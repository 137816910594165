import React, {useEffect,useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Container, Card, Table,Tabs,Tab,} from 'react-bootstrap';

/**
 * Creates a new OrderedItemTable.
 * @class
 */
const SizeChart = props => {
	
	const Topwear=()=>{
		
		return(
		<div key='topwear'>
            <Row >
                <Col sm={12}>
                <Table responsive striped bordered  hover size="sm">
				<thead>
                <tr className="thead-table-ord">
                            <th className="td pb-1 pt-1 text-center fsize13" style={{width:'10%'}}><p className="m-0 table-blank-p">SIZE</p></th>
							<th className="td pb-1 pt-1 text-center fsize13" style={{width:'10%'}}><p className="m-0 table-blank-p">CHEST</p></th>
							<th className="td pb-1 pt-1 text-center fsize13" style={{width:'14%'}}><p className="m-0 table-blank-p">WAIST</p></th>
							<th className="td pb-1 pt-1 text-center fsize13" style={{width:'11%'}}><p className="m-0 table-blank-p">HIPS</p></th>
							<th className="td pb-1 pt-1 text-center fsize13" style={{width:'11%'}}><p className="m-0 table-blank-p">SHOULDER</p></th>
							<th className="td pb-1 pt-1 text-center fsize13" style={{width:'9%'}}><p className="m-0 table-blank-p">AROUND ARM</p></th>
							<th className="td pb-1 pt-1 text-center fsize13" style={{width:'9%'}}><p className="m-0 table-blank-p">SLEEVE LENGTH FULL</p></th>
							<th className="td pb-1 pt-1 text-center fsize13" style={{width:'9%'}}><p className="m-0 table-blank-p">SLEEVE LENGTH QUARTER</p></th>
							<th className="td pb-1 pt-1 text-center fsize13" style={{width:'10%'}}><p className="m-0 table-blank-p">LENGTH HALF SLEEVE</p></th>
                        </tr>
                        </thead>
						<tbody>
						<tr className="tr" >
						 <td className="tdata text-center pt-1 pb-1 fsize13" >XS</td>
						 <td className="tdata text-center pt-1 pb-1 fsize13" >34</td>
						 <td className="tdata text-center pt-1 pb-1 fsize13" >30</td>
						 <td className="tdata text-center pt-1 pb-1 fsize13" >38</td>
						 <td className="tdata text-center pt-1 pb-1 fsize13" >13.5</td>
						 <td className="tdata text-center pt-1 pb-1 fsize13" >15</td>
						 <td className="tdata text-center pt-1 pb-1 fsize13" >20</td>
						 <td className="tdata text-center pt-1 pb-1 fsize13" >16</td>
						 <td className="tdata text-center pt-1 pb-1 fsize13" >6</td>
						 </tr>
						 </tbody>
						 <tbody>
						 <tr className="tr" >
						 <td className="tdata text-center pt-1 pb-1 fsize13" >S</td>
						 <td className="tdata text-center pt-1 pb-1 fsize13" >36</td>
						 <td className="tdata text-center pt-1 pb-1 fsize13" >32</td>
						 <td className="tdata text-center pt-1 pb-1 fsize13" >40</td>
						 <td className="tdata text-center pt-1 pb-1 fsize13" >14</td>
						 <td className="tdata text-center pt-1 pb-1 fsize13" >16</td>
						 <td className="tdata text-center pt-1 pb-1 fsize13" >20.5</td>
						 <td className="tdata text-center pt-1 pb-1 fsize13" >16.5</td>
						 <td className="tdata text-center pt-1 pb-1 fsize13" >6</td>
						 </tr>
						 </tbody>
						 <tbody>
						 <tr className="tr" >
						 <td className="tdata text-center pt-1 pb-1 fsize13" >M</td>
						 <td className="tdata text-center pt-1 pb-1 fsize13" >38</td>
						 <td className="tdata text-center pt-1 pb-1 fsize13" >34</td>
						 <td className="tdata text-center pt-1 pb-1 fsize13" >42</td>
						 <td className="tdata text-center pt-1 pb-1 fsize13" >14.5</td>
						 <td className="tdata text-center pt-1 pb-1 fsize13" >17</td>
						 <td className="tdata text-center pt-1 pb-1 fsize13" >21</td>
						 <td className="tdata text-center pt-1 pb-1 fsize13" >17</td>
						 <td className="tdata text-center pt-1 pb-1 fsize13" >7</td>
						 </tr>
						 </tbody>
						 <tbody>
						 <tr className="tr" >
						 <td className="tdata text-center pt-1 pb-1 fsize13" >L</td>
						 <td className="tdata text-center pt-1 pb-1 fsize13" >40</td>
						 <td className="tdata text-center pt-1 pb-1 fsize13" >36</td>
						 <td className="tdata text-center pt-1 pb-1 fsize13" >44</td>
						 <td className="tdata text-center pt-1 pb-1 fsize13" >15</td>
						 <td className="tdata text-center pt-1 pb-1 fsize13" >18</td>
						 <td className="tdata text-center pt-1 pb-1 fsize13" >21</td>
						 <td className="tdata text-center pt-1 pb-1 fsize13" >17.5</td>
						 <td className="tdata text-center pt-1 pb-1 fsize13" >7</td>
						 </tr>
						 </tbody>
						 <tbody>
						 <tr className="tr" >
						 <td className="tdata text-center pt-1 pb-1 fsize13" >XL</td>
						 <td className="tdata text-center pt-1 pb-1 fsize13" >42</td>
						 <td className="tdata text-center pt-1 pb-1 fsize13" >38</td>
						 <td className="tdata text-center pt-1 pb-1 fsize13" >44</td>
						 <td className="tdata text-center pt-1 pb-1 fsize13" >15.5</td>
						 <td className="tdata text-center pt-1 pb-1 fsize13" >19</td>
						 <td className="tdata text-center pt-1 pb-1 fsize13" >22</td>
						 <td className="tdata text-center pt-1 pb-1 fsize13" >18</td>
						 <td className="tdata text-center pt-1 pb-1 fsize13" >7</td>
						 </tr>
						 </tbody>
						 <tbody>
						 <tr className="tr" >
						 <td className="tdata text-center pt-1 pb-1 fsize13" >XXL</td>
						 <td className="tdata text-center pt-1 pb-1 fsize13" >44</td>
						 <td className="tdata text-center pt-1 pb-1 fsize13" >40</td>
						 <td className="tdata text-center pt-1 pb-1 fsize13" >48</td>
						 <td className="tdata text-center pt-1 pb-1 fsize13" >16</td>
						 <td className="tdata text-center pt-1 pb-1 fsize13" >20</td>
						 <td className="tdata text-center pt-1 pb-1 fsize13" >22</td>
						 <td className="tdata text-center pt-1 pb-1 fsize13" >18.5</td>
						 <td className="tdata text-center pt-1 pb-1 fsize13" >7</td>
						 </tr>
						 </tbody>
						 
						 
						</Table>
                    
            </Col>
            </Row>
        </div>
		
		);
	}
	
	
	const Trouser=()=>{
		
		return(
		<div key='trowser'>
            <Row >
                <Col sm={12}>
                <Table responsive striped bordered  hover size="sm">
				<thead>
                <tr className="thead-table-ord">
                            <th className="td pb-1 pt-1 text-center fsize13" style={{width:'10%'}}><p className="m-0 table-blank-p">SIZE</p></th>
							<th className="td pb-1 pt-1 text-center fsize13" style={{width:'10%'}}><p className="m-0 table-blank-p">WAIST</p></th>
							<th className="td pb-1 pt-1 text-center fsize13" style={{width:'14%'}}><p className="m-0 table-blank-p">OPTION-1 TROUSER LENGTH</p></th>
							<th className="td pb-1 pt-1 text-center fsize13" style={{width:'14%'}}><p className="m-0 table-blank-p">OPTION-2 PALLAZO LENGTH</p></th>
							
                        </tr>
                        </thead>
						<tbody>
						<tr className="tr" >
							<td className="tdata text-center pt-1 pb-1 fsize13" >XS</td>
							<td className="tdata text-center pt-1 pb-1 fsize13" >30</td>
							<td className="tdata text-center pt-1 pb-1 fsize13" >36</td>
							<td className="tdata text-center pt-1 pb-1 fsize13" >36</td>
						</tr>
						</tbody>
						<tbody>
						<tr className="tr" >
							<td className="tdata text-center pt-1 pb-1 fsize13" >S</td>
							<td className="tdata text-center pt-1 pb-1 fsize13" >32</td>
							<td className="tdata text-center pt-1 pb-1 fsize13" >36</td>
							<td className="tdata text-center pt-1 pb-1 fsize13" >36</td>
						</tr>
						</tbody>
						<tbody>
						<tr className="tr" >
							<td className="tdata text-center pt-1 pb-1 fsize13" >M</td>
							<td className="tdata text-center pt-1 pb-1 fsize13" >34</td>
							<td className="tdata text-center pt-1 pb-1 fsize13" >38</td>
							<td className="tdata text-center pt-1 pb-1 fsize13" >38</td>
						</tr>
						</tbody>
						<tbody>
						<tr className="tr" >
							<td className="tdata text-center pt-1 pb-1 fsize13" >L</td>
							<td className="tdata text-center pt-1 pb-1 fsize13" >36</td>
							<td className="tdata text-center pt-1 pb-1 fsize13" >38</td>
							<td className="tdata text-center pt-1 pb-1 fsize13" >38</td>
						</tr>
						</tbody>
						<tbody>
						<tr className="tr" >
							<td className="tdata text-center pt-1 pb-1 fsize13" >XL</td>
							<td className="tdata text-center pt-1 pb-1 fsize13" >38</td>
							<td className="tdata text-center pt-1 pb-1 fsize13" >40</td>
							<td className="tdata text-center pt-1 pb-1 fsize13" >40</td>
						</tr>
						</tbody>
						<tbody>
						<tr className="tr" >
							<td className="tdata text-center pt-1 pb-1 fsize13" >XXL</td>
							<td className="tdata text-center pt-1 pb-1 fsize13" >40</td>
							<td className="tdata text-center pt-1 pb-1 fsize13" >40</td>
							<td className="tdata text-center pt-1 pb-1 fsize13" >40</td>
						</tr>
						</tbody>
						</Table>
                    
            </Col>
            </Row>
        </div>
		
		);
	}
						

    return (
        <React.Fragment>
        <Tabs defaultActiveKey="topwear" id="uncontrolled-tab-example">
			<Tab eventKey="topwear" title="TopWear">
				<Topwear />
			</Tab>
			<Tab eventKey="trouser" title="Trouser">
				<Trouser />
			</Tab>
			
		</Tabs>
    
        </React.Fragment>
    );
}
 
const mapStateToProps = state => ({
  
})

export default connect(mapStateToProps,{})(SizeChart);
