import React,{useEffect,useState} from 'react';
import { useLocation, useNavigate, Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import ThirdStep from './ThirdStep';
import { Container, Row, Col, Navbar } from "react-bootstrap";


const Progress = (props) => {
  const showSection = (typeof props.currentSection == "undefined") ? "" : props.currentSection;
  
  const activeAddressClass=(props.currentSection == "Address") ? "d-inline-block fw-bold w-25 position-relative text-center float-start progressbar-list active" :(props.currentSection == "Delivery" || props.currentSection == "FinalOrderList" || props.currentSection == "Payment")? "d-inline-block fw-bold w-25 position-relative text-center float-start progressbar-list done":"d-inline-block fw-bold w-25 position-relative text-center float-start progressbar-list";
  const activeDeliveryClass=(props.currentSection == "Delivery") ? "d-inline-block fw-bold w-25 position-relative text-center float-start progressbar-list active" : (props.currentSection == "FinalOrderList" || props.currentSection == "Payment")? "d-inline-block fw-bold w-25 position-relative text-center float-start progressbar-list done":"d-inline-block fw-bold w-25 position-relative text-center float-start progressbar-list";
  let activeFinalOrderListClass=(props.currentSection == "FinalOrderList") ? "d-inline-block fw-bold w-25 position-relative text-center float-start progressbar-list active" : ( props.currentSection == "Payment")? "d-inline-block fw-bold w-25 position-relative text-center float-start progressbar-list done":"d-inline-block fw-bold w-25 position-relative text-center float-start progressbar-list";
  let activePaymentClass=( props.currentSection == "Payment") ? "d-inline-block fw-bold w-25 position-relative text-center float-start progressbar-list active" : "d-inline-block fw-bold w-25 position-relative text-center float-start progressbar-list";

  return (
    <div className='text-center'>
      {/* <div className={(showSection=="Phone") ? 'primarybutton' : '' }>
          <div>Step 1(Phone)</div>
        </div> */}
      {/* <div className={(showSection=="Address") ? 'primaryactive activebutton' : 'activebutton' }>
          <button>1</button>
          <label className='w-100'>Delivery </label>
        </div>
        <div className={(showSection=="Delivery") ? 'primaryactive activebutton' : 'activebutton' }>
        <button>2</button>
          <label className='w-100'>Place Order</label>
        </div>
        <div className={(showSection=="FinalOrderList") ? 'primaryactive activebutton' : 'activebutton' }>
        <button>3</button>
          <label className='w-100'>Complete Payment</label>
        </div>
        <div className={(showSection=="Payment") ? 'primaryactive activebutton' : 'activebutton' }>
        <button>4</button>
          <label className='w-100'>Step 4</label>
        </div> */}
<Row>
  <div className='col-md-8 p-0-mob'>
  <ul id="progressBar" className="progressbar px-lg-5 px-0">
        <li className={activeAddressClass}>
          Address</li>
          <li className={activeDeliveryClass}>
          Delivery Instruction</li>
          <li className={activeFinalOrderListClass}>
          Order Summery</li>
          <li className={activePaymentClass}>
          Payment</li>
      </ul>
  </div>
  <div className='col-md-4'>
  
  </div>
</Row>
      
    </div>

  );
};

export default Progress;