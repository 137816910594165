import React from 'react';
import { connect } from 'react-redux';
import Product from './Product';
import { Container, Row } from 'react-bootstrap';


const Products = (props) => {

    const renderProducts = () => {
        //console.log(props.productsList, props.searchTerm)
        let productsData=[];
        if(props.productsList) {
            productsData = props.productsList
            .map(product => {
                const cartObj = props.cartList.filter(item => {
                return item.id === product.id
                })
                //
                return (
                <Product
                    key={product.id}
                    product={product}
                    cart={cartObj[0]}
                />
                );
            });
        }
        return productsData;
      }
	  
	  const renderloading=()=>
	  {
		  let content=[];
		  for(var i=0;i<8;i++)
			  content=[content,(<Product />)];
	  }
		  
	  
	  const Loadingproduct = () =>
	  {
		  
		  return (<div>{renderloading()}</div>);
	  }

    /* let slug;
    const allProductsView = props.productsList.map(product => {
        slug = product.image; //using the image as slug
        return (
            <div key={product.id}>
                <span><b>{product.name}</b> --- <Link to={`/productdetails/${slug}`}>{product.image}</Link></span>
            </div>
        );
    });  */

    let productsData= renderProducts()
    //let term = props.searchTerm;
    // Empty and Loading States
    let view;
    if (productsData.length <= 0 ) {
      view =  <Loadingproduct/>
    } 
    else {
      
      view = (
        <div className="mar-0-auto text-center">
            
                {productsData}
           
            </div>);
    }
    
    return (
        <div className="products-wrapper">  
          <div>{view}</div>
        </div>
    );
}

const mapStateToProps = state => ({
    productsList: state.ProductsData.productsList,
    searchTerm: state.SearchData.searchTerm,
    cartList: state.CartData.cartList,
    selectedCategory: state.CategoryData.selectedCategory 
})

export default connect(mapStateToProps)(Products);