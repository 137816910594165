import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Button, Card, Form, Collapse } from 'react-bootstrap';
import { connect } from 'react-redux';
import { set_phone, getCustomerDetails, set_phoneCountryCode,set_phoneCode } from '../../actions/UserAction';
import {validateOtp, setValidateOtp, sendOtp, clearOtpStatus, setLogin} from '../../actions/AuthAction';
import ResendOtp from './ResendOtp';
import { Global } from '../../utils/Env';
import PhoneInput,{ getCountryCallingCode  } from 'react-phone-number-input'
import { login, sendOtpForLogin, set_password,  set_authenticated, setMsgModal,WalletInfo } from '../../actions/AuthAction';
import { getCart, saveCart,setWishListNonLog } from '../../actions/CartAction';
import { saveWishList, getWishListLogin, SaveUpdateWishCategory } from '../../actions/ProductsAction';
import { clearLocation } from '../../actions/LocationAction';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";

const PhoneValidate = (props) => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    var date = yyyy + '-' + mm + '-' + dd;
    const navigate = useNavigate();
    const [state, setState] = useState("phone");
    const [phonenumber, setPhonenumber] = useState(props.phone);
    const [otp, setOtp] = useState(["", "", "", ""]);
    const [signinDis,setSignInDis]=useState(false);
    
    const [otpError, setOtpError] = useState(false);

    const ref1 = useRef(null)
    const ref2 = useRef(null)
    const ref3 = useRef(null)
    const ref4 = useRef(null)
    const ref5 = useRef(null)
    
    const [countryCodeData, setCountryCode] = useState(props.countryCode==null || props.countryCode=='' || props.countryCode==undefined?'IN':props.countryCode.toUpperCase());
    const [phoneCodeValue, setPhoneCode] = useState(props.phoneCodeDefault==null || props.phoneCodeDefault=='' || props.phoneCodeDefault==undefined?'+91':props.phoneCodeDefault);
    const [codeWithPhonenumber, setPhone] = useState((props.phoneCodeDefault=='' || props.phoneCodeDefault==undefined || props.phoneCodeDefault==null)?'+91'.concat(props.phone):props.phoneCodeDefault+props.phone);
    const redirectToLogin = (typeof props.redirectToLogin=="undefined")?false:props.redirectToLogin;

    let primaryColor=props.petTemplateDetails && props.petTemplateDetails.primaryColor?props.petTemplateDetails.primaryColor:'';
    let secondaryColor=props.petTemplateDetails && props.petTemplateDetails.secondaryColor?props.petTemplateDetails.secondaryColor:'';
    let tertiaryColor=props.petTemplateDetails && props.petTemplateDetails.tertiaryColor?props.petTemplateDetails.tertiaryColor:'';
	
    

    useEffect(()=>{
        setCountryCode(props.countryCode==null || props.countryCode=='' || props.countryCode==undefined?'IN':props.countryCode.toUpperCase());
        setPhoneCode(props.phoneCodeDefault==null || props.phoneCodeDefault=='' || props.phoneCodeDefault==undefined?'+91':props.phoneCodeDefault);

    },[props.phoneCode,props.countryCode])
	
	

   
 
    useEffect(() => {
        if(props.phone && props.valid_otp){
            props.setPhnCard(false);
            props.setAddressCard(true);
        }
    }, [props.phone, props.valid_otp]);

   

    Global.callback.sendOtp_onComplete = () => {
        setSignInDis(false);
        if(props.otpSendStatus.status && props.otpSendStatus.data && props.otpSendStatus.data.isOTPSent)
        setState('otp');
        else if(props.otpSendStatus.data && props.otpSendStatus.data.alreadyCustomer)
        {
        if(props.setParentState)
            props.setParentState('login')
        else
            props.sendOtpForLogin({CompanyId:props.companyid,PhoneNo:phonenumber,PhoneCode:phoneCodeValue,ipAddress:props.ipAddress})
        }
        else
        props.setMsgModal({
            "title": "Error in Phone",
            "message": props.otpSendStatus.message,
            "show": true
        });
    }

    const handleClick = () => {
        if (state === "phone")
            reqOtpClicked();
        else
            verifyOtpClicked();
    }

    const reqOtpClicked = () => {
        if (phonenumber.length ==10 && phonenumber>6000000000) {
            props.set_phone(phonenumber);
            props.sendOtp({CompanyId:props.companyid,PhoneNo:phonenumber,PhoneCode:phoneCodeValue,ipAddress:props.ipAddress})
            setSignInDis(true);
        }
        else
        toast.info("Please enter valid phone number")
    }
//props.companyid
    const verifyOtpClicked = () => {
        if (otp.length === 4) {
            setOtp(["", "", "", ""]); // clear otp fields
            //console.log('Akka',redirectToLogin);
            props.validateOtp({"CompanyId":props.companyid,"PhoneNo":phonenumber, "OTP":otp.join(''),"PhoneCode":props.phoneCode,ipAddress:props.ipAddress});
            setSignInDis(true);
        }
    }
          /** @function
 * @name login_onComplete */
           Global.callback.login_onComplete = () => {
            setSignInDis(false);
            if (props.loginData.data != null) {
                props.set_authenticated(true);
                props.set_password('');
                props.set_phone(props.loginData.data.phone);
                props.setValidateOtp({ "status": 1, "message": "Success", "data":{"isOTPVerified": true }});
                if (props.loginData.data.customerId != '' && props.loginData.data.customerId != null && props.loginData.data.userType == 'b2b') {
                    props.WalletInfo({ "CustomerId": parseInt(props.loginData.data.customerId) });
                }
                //console.log('OnComplete===>',props.cartList)
                if (props.cartList !=undefined &&  props.cartList !=null && props.cartList.length > 0) {
                    let items = [];
                    props.cartList.map((item, i) => {
                        items.push({ "itemId": item.id, "itemVariantId": (item.variantid ? item.variantid : 0), "itemQty": item.quantity })
                    });
                    props.saveCart({ "companyid": props.companyid, "customerid": parseInt(props.loginData.data.customerId), "userid": props.loginData.data.id, "carttItemVariant": items });
                }
                else
                    props.getCart({ "companyid": props.companyid, "customerid": parseInt(props.loginData.data.customerId) });
                props.clearLocation();
                props.getWishListLogin({ "companyid": props.companyid, "customerid": parseInt(props.loginData.data.customerId) });
                //console.log('closeeeeee====>', props.closeAction())
                props.closeAction();
                // window.location.reload(); 
                navigate("/");
            }
            else if(props.valid_otp && props.setParentState)
            {
                props.setParentState('signup');

            }
            else
            {
                props.setMsgModal({
                    "title": "Error in login",
                    "message": "Error",
                    "show": true
                });
            }
        }

        Global.callback.getwishlistlogin_onComplete = () => {
            console.log("props.wishlist",props.wishlist);
            if(props.wishlist && props.wishlist.length>0 && props.wishlist.filter((item)=>item.wishListCategoryName=='Non Logged In').length>0)
            {
              let wishCat=props.wishlist.filter((item)=>item.wishListCategoryName=='Non Logged In')[0];
              props.wishlistNonLog.map((wish)=>{
                let obj = {
                  "companyid": props.companyid,
                  "customerId": parseInt(props.loginData.data.customerId),
                  "itemId": wish.itemId,
                  "itemVariantId": wish.itemVariantId,
                  "notifyOnArrival": wish.notifyOnArrival,
                  "wishListCategoryId": wishCat.wishListCategoryId,
                  "wishListCategoryName": wishCat.wishListCategoryName,
                  "note": "",
                  "date": date
              }
             
              props.saveWishList(obj);
              })
              props.setWishListNonLog([]);
            }
            else
            {
              let obj = {
                "WishListCategoryId": 0,
                "WishListCategoryName": 'Non Logged In',
                "IsDefault": props.wishlist ? false : true,
                "Date": date,
                "customerId": parseInt(props.loginData.data.customerId),
                "companyId": props.loginData.data.companyId
              }
              props.SaveUpdateWishCategory(obj); 
            }
            
        }
        Global.callback.login_onError = () => {
            setSignInDis(false);
            props.setMsgModal({
                "title": "Error in login",
                "message": "Some Error Occurred",
                "show": true
            });
        }

    const handlePhonenumberChange = (e) => {
            setPhonenumber(e)
            setPhone(e);
        var onlyPhoneCode='';
        //console.log("chanig")
       
        if(countryCodeData !='' && countryCodeData !==undefined)
        {
            //console.log("test123");
        onlyPhoneCode ="+" + getCountryCallingCode(countryCodeData);  
        setPhoneCode(onlyPhoneCode);
        const onlyPhoneNumber = e?e.replace(onlyPhoneCode, ''):''
        setPhonenumber(onlyPhoneNumber);
        props.set_phoneCountryCode(countryCodeData);
        props.set_phoneCode(onlyPhoneCode);
        }
      
    }
    const handleOtpChange = (e, i) => {
        let copyOtp = [...otp]
        if (e.target.value.length <= 1) {
            copyOtp[i] = e.target.value
            setOtp(copyOtp)
        }
        if (otp[i] === "") {
            switch (i + 1) {
                case 1:
                    {
                        ref2.current.focus();
                        break
                    }
                case 2:
                    {
                        ref3.current.focus();
                        break
                    }
                case 3:
                    {
                        ref4.current.focus();
                        break
                    }
                case 4:
                    {
                        ref5.current.focus();
                        break
                    }
                default: return null
            }
        }
    }

    const checkValidity = () => {
        //console.log("phonenumber",phonenumber)
        //console.log("state",state);
        //console.log("phonenumber.length",phonenumber.length)
        if (state == "phone") {
            if (phonenumber.length >= 8) {
                //console.log("got inside")
                return false
            }
        }
        else {
            if (otp[0] !== "" && otp[1] !== "" && otp[2] !== "" && otp[3] !== "") {
                return false
            }
        }
        return true
    }
    
    const renderPhoneField = () => {
        return (<React.Fragment>
                    <Row>
                        <Col className='pl-0 pr-0'>
                        <p className="w-100 fs-14 mb-1 phno-lebel">Enter Your Phone Number</p>
                        {/* <input className="form-control" placeholder="Phone Number" type="number" value={phonenumber} onChange={handlePhonenumberChange} /> */}
                        <PhoneInput className='pl-0 pr-0'
                                                countrySelectProps={{ unicodeFlags: false }}
                                                countryCallingCodeEditable={false}
                                                international
                                                defaultCountry={countryCodeData}
                                                value={codeWithPhonenumber}
                                                name="phonenumber"
                                                onChange={handlePhonenumberChange} onCountryChange={setCountryCode} />
                        </Col>
                    </Row>
                </React.Fragment>)
    }

    const renderOtpField = () => {
        return (<Row>
            <p className="w-100 fs-13-f-n mb-1 phno-lebel">Enter Your OTP</p>
            <Col md="3 pl-0" xs="3">
                <input className="form-control p-0-5-tab br-radious-0" ref={ref1} value={otp[0]} onChange={(e) => handleOtpChange(e, 0)} />
            </Col>
            <Col md="3 pl-0" xs="3">
                <input className="form-control p-0-5-tab br-radious-0" ref={ref2} value={otp[1]} onChange={(e) => handleOtpChange(e, 1)} />
            </Col>
            <Col md="3 pl-0" xs="3">
                <input className="form-control p-0-5-tab br-radious-0" ref={ref3} value={otp[2]} onChange={(e) => handleOtpChange(e, 2)} />
            </Col>
            <Col md="3 pl-0" xs="3">
                <input className="form-control p-0-5-tab br-radious-0" ref={ref4} value={otp[3]} onChange={(e) => handleOtpChange(e, 3)} />
            </Col>
            {otpError && <div className="text-danger font-12 mt-2">Error in otp... try again</div>}
            <ResendOtp phonenumber={phonenumber} phoneCodeValue={phoneCodeValue} loginMode={false} />
        </Row> )
    }
	
    return (
        <Card className="mb-2">
            <Card.Header className="bgf2f2f2">
                    <i className="fa fa-check-circle text-dark f-size22" aria-hidden="true"></i> 
               
                <label className="float-right mb-0">{props.phone}</label>
            </Card.Header>
            <Card.Body style={{ display:"block" }}>
                <Row>
                    <Col md="12 pr-0-mob">
                        {(state === "phone") ? renderPhoneField() : renderOtpField()}
                    </Col>
                    <Col className='mt-3'>
                    <div className="midLinks">By continuing, I agree to the <a style={{color:primaryColor}} href="/termsofuse">Terms of Use</a> &amp; <a style={{color:primaryColor}} href="/privacypolicy">Privacy Policy</a></div>
                    </Col>
                    <Col md="12 text-right">
                        <p className="w-100 fs-14 mb-1">&nbsp;</p>
                        {<button style={{backgroundColor:primaryColor}} ref={ref5} onClick={handleClick} disabled={checkValidity() || signinDis} className="rounded-0 primary-button-nocolor w-100">CONTINUE</button>}
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

const mapStateToProps = state => ({
	loginData:state.AuthData.loginData,
    customerid:state.AuthData.loginData && state.AuthData.loginData.data && state.AuthData.loginData.data.customerId?state.AuthData.loginData.data.customerId:0,
    customerDetails:state.UserData.customerDetails,
    otpSendStatus:state.AuthData.otpSendStatus,
    valid_otp:state.AuthData.valid_otp,
    companyid: state.HomeData.companyid,
	pin: state.LocationData.pin,
    countryCode:state.HomeData.servicePhoneCountryCode,
    phone:state.UserData.phone,
    phoneCountryCode: state.UserData.phoneCountryCode,
    phoneCode:state.UserData.phoneCode,
    phoneCodeDefault:state.HomeData.servicePhoneCode,
    wishListNonLog:state.CartData.wishListNonLog,
    wishlist: state.ProductsData.wishlist,
    petTemplateDetails: state.petProductData.userP_AllProductInfo.data,
    ipAddress:state.AuthData.ipAddress
})

export default connect(mapStateToProps, { setMsgModal,sendOtpForLogin,clearLocation,getCart,setWishListNonLog,saveWishList, getWishListLogin, SaveUpdateWishCategory,saveCart,WalletInfo,set_password,set_authenticated,login,set_phone, getCustomerDetails, validateOtp, setValidateOtp, sendOtp, clearOtpStatus,set_phoneCountryCode,set_phoneCode })(PhoneValidate);  