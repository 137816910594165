import React, {  } from 'react';
import { Container ,Row  } from 'react-bootstrap';
import { connect } from 'react-redux';
import comonbanner from "../../src/assets/img/pet-shop-banner.jpg";
import smcomonbanner from "../../src/assets/img/sm-comon-banner.jpg";
import dogs1 from "../../src/assets/img/da1.png";
import pet1 from "../../src/assets/img/pet1.png";
import pl from "../../src/assets/img/pl.png";
const AboutUs = props => {



	return (
		<React.Fragment>
			<div >
				<h1 className="aboutus-head"><b>About Us</b></h1>
				{props.footerData && props.footerData.aboutUsDtl && props.footerData.aboutUsDtl.showAboutUs ? props.footerData.aboutUsDtl.isHtml ?
					<div className="product_details_cont" dangerouslySetInnerHTML={{ __html: props.footerData.aboutUsDtl.aboutUs }} /> : '' : ''}
			</div>
		</React.Fragment>
	);
}

const mapStateToProps = state => ({
	footerData: state.HomeData.footer
})

export default connect(mapStateToProps, {})(AboutUs);
