import React from 'react';
import OrderPlaceHeader from '../checkout/OrderPlaceHeader';
import FirstStep from '../checkout/FirstStep';



const OrderPlace = () => (
  <div>
    <OrderPlaceHeader />
    {/* <FirstStep/> */}
  </div>
);

export default OrderPlace;