import React,{useEffect} from 'react';
import { connect } from 'react-redux';
import { to2Decimal, formatINR,formatSymbolFromCurrency } from '../utils/CommonUtil';

const Wallet = props => {

    const walletInfo = () => {
  
      let walletDiv = "";
            if(props.loginData.data !='' && props.loginData.data !=null)
            {
                    if(props.loginData.data.userType=='b2b' && props.userWalletInfo !=null && props.userWalletInfo !='')
                    {
                        if(props.userWalletInfo.customerWalletInfo !=null && props.userWalletInfo.customerWalletInfo !='')
                    {
                                walletDiv =<div className={"wallet"}>
                                <div class="row">

                                    <div class="col-md-3">
                                        <div class="mb-15">
                                            <p class="all-textlabel mb-0">Credit Limit: <b id="labCreditLimit">{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode),to2Decimal(props.userWalletInfo.customerWalletInfo.creditLimit))}</b></p>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="mb-15">
                                            <p class="all-textlabel mb-0">Total Business Amount: <b id="labTotalBusinessAmount">{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode),to2Decimal(props.userWalletInfo.customerWalletInfo.totalBusinessAmount))}</b></p> 
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="mb-15">
                                            <p class="all-textlabel mb-0">Total Paid Amount: <b id="labTotalPaidAmount">{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode),to2Decimal(props.userWalletInfo.customerWalletInfo.totalPaidAmount))}</b></p> 
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="mb-15">
                                            <p class="all-textlabel mb-0">Total Due Amount: <b id="labTotalDueAmount">{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode),to2Decimal(props.userWalletInfo.customerWalletInfo.dueAmount))}</b></p> 
                                        </div>
                                    </div>
                                </div>
                                </div>
                    }
                }
            }
                   
      return (walletDiv)
    }
 
    return (
        walletInfo()
    );
  }
  
  const mapStateToProps = state => ({
    loginData: state.AuthData.loginData,
    userWalletInfo: state.AuthData.userWalletInfo.data,
    companyDetailsCurrency: state.HomeData.companyDetailsCurrency,
  })
  
  export default connect(mapStateToProps)(Wallet);