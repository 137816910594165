import React, { useState,useEffect } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import ImageUtil from '../../utils/ImageUtil';
import { to2Decimal, formatINR,formatSymbolFromCurrency } from '../../utils/CommonUtil';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

const FinalOrderList = (props) => {
    //console.log(props)
    const navigate = useNavigate();
    const [showAll, setShowAll] = useState(false);
    const toggleShow = () => {
        setShowAll(!showAll)
    }
	
	useEffect(()=>{
		//console.log(props.checkoutDetails,"deep_checkout");
	},[props.checkoutDetails]);
    const renderItem = (item, indx,tax,taxprct,taxitem) => {
        return (
            <Row key={item.name+indx} className="final-ord-list" >
                    {/* <Col md="1" xs="1" className="pr-0 pl-2 pt-3 text-center">
                        <p className="mb-0 font-12"><b>{item.quantity}</b></p>
                    </Col> */}
                    <Col md="1"  xs="3" className="p-0-tab final-ordcol">
                        {/* {item.brandicon ?
                            <div className="cart-brand-iconmaindiv">
                                <img className="cart-brand-img" alt={item.brandicon} src={item.brandicon} />
                            </div> : null} */}
                            <div className='jc-flc'>
                        <img
                            alt={item.name}
                            className="product-imagefinalorber" 
                            src={item.images[0] ? ImageUtil.getImage(item.images[0].name.substring(0, item.images[0].name.lastIndexOf(".")).concat("_Thumbnail").concat(item.images[0].name.substring(item.images[0].name.lastIndexOf("."), item.images[0].name.length))) : ImageUtil.getErrorImage() }
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = ImageUtil.getErrorImage();
                            }}
                        />
                        </div>
                    </Col>
                    <Col md="6"  xs="4" className="p-0">
                        <Row><Col className="text-left"><span className="badge badge-pill badge-success">{item.promo_percentage}</span></Col></Row>
                        <Row>
                            <Col md="12" className="text-left">
							<p className="product-name product-name-cart mb-0 text-muted fs-13-f-n">{item.item_code}</p>
                                {/* <p className="asin-title">{item.name}</p> */}
                                <a href={(`/categorySection/${2}/${item.id}/${item.variantid}`)} className="product-name product-name-cart product-name-cart-newpage mb-0 cursor" onClick={() => navigate(`/categorySection/${2}/${item.id}/${item.variantid}`)}>{`${item.name} `}</a>
                                <div><b className='asin-title'>Quantity:</b> {item.quantity}</div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md="3" xs="2" className="pr-0 pl-0 text-center"><div className={props.mobileState?"text-muted text-left font-12":"text-muted text-left font-12"}>
                        {item && item.color && item.color!=''?<p className='mb-0 asin-title'>Color:<b>{item.color}</b></p>:''}
                        {item && item.material && item.material!=''?<p className='mb-0 asin-title'>Material:<b>{item.material}</b></p>:''}
                        {item && item.size && item.size!=''?<p className='mb-0 asin-title'>Size:<b>{item.size}</b></p>:''}
                        {item && item.weight && item.weight!=''?<p className='mb-0 asin-title'>Weight:<b>{item.weight}</b></p>:''}
                     <p className='asin-title'>{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode),to2Decimal(taxitem.itemamt))}</p>
					
						{(tax)?<><p className='mb-0'>Tax({(taxprct)?taxprct.split(".")[0].concat('%'):null}) :<span>{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode),to2Decimal(taxitem.taxamt))}</span></p></>:null}</div></Col>
                    <Col md="2" xs="3" className="pl-0 pr-2 text-right">
					{(tax)?<><p className="text-right mb-2 fs-14"><b>{item.promo_price ? formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode),to2Decimal(taxitem.itemamt+taxitem.taxamt)) : formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode),to2Decimal(taxitem.itemamt+taxitem.taxamt))}</b></p></>:<><p className="text-right mb-2 fs-14"><b>{item.promo_price ? formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode),to2Decimal(taxitem.itemamt)) : formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode),to2Decimal(taxitem.itemamt))}</b></p></>}
                    </Col>
                </Row>
        )
    }
    const renderAllItems = () => {
        let items = props.cartItems.map((item,i) => {
			let tax;
			let taxprct;
			let taxitem;
			if(props.checkoutDetails.item)
			{
			props.checkoutDetails.item.filter((e)=>{
					if(e.id==item.id)
					{
						taxitem=JSON.parse(JSON.stringify(e));
						if(item.variantid)
						{
							tax=e.taxamt/e.quantity;
							taxprct=e.taxrate;
						}
						else if(item.variantid && e.variantid==item.variantid)
						{
							tax=e.taxamt/e.quantity;
							taxprct=e.taxrate;
						}
					}
					return true;
				});
			}
			//console.log("Deep_taxitem",taxitem);
			if(props.checkoutDetails.item)
            return renderItem(item,i,tax,taxprct,taxitem)})
        return items;
    }

    const renderfourItems = () => {
        let items = props.cartItems.map((item, i )=> {
			let tax;
			let taxprct;
			let taxitem;
			if(props.checkoutDetails.item)
			{
				props.checkoutDetails.item.filter((e)=>{
					if(e.id==item.id)
					{
						taxitem=JSON.parse(JSON.stringify(e));
						if(item.variantid)
						{
							tax=e.taxamt/e.quantity;
							taxprct=e.taxrate;
						}
						else if(item.variantid && e.variantid==item.variantid)
						{
							tax=e.taxamt/e.quantity;
							taxprct=e.taxrate;
						}
					}
					return true;
				});
			}
			//console.log("Deep_taxitem",taxitem);
			if(props.checkoutDetails.item)
           return( i< 4 ? renderItem(item,i,tax,taxprct,taxitem) :  "")}) 
        return items;
    }
	//console.log(props.checkoutDetails,"deep_checkout_render");
    return (
        <Card>

            {/* <Row>
                <Col className="pt-2 pb-2 bord-bot-5">
                    <div><p className="addressbrands-second ">Grand Total<span className="float-right text-dark">{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode),to2Decimal(props.checkoutDetails.totalamt))}</span></p></div>
                    <div><p className="addressbrands-second ">Total Tax<span className="float-right text-dark">{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode),to2Decimal(props.checkoutDetails.totaltaxamt))}</span></p></div>
                    <div><p className="addressbrands-second ">Delivery Charges<span className="float-right text-dark">{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode),to2Decimal(props.checkoutDetails.deliverychargeamt))}</span></p></div>
                    <hr className="mt-2 mb-2" />
                    <div><p className="addressbrands-second ">Your Total Savings<span className="float-right text-danger">{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode),to2Decimal(props.checkoutDetails.discount_total))}</span></p></div>
                </Col>
            </Row> */}
            
            <div className="my-items-header">
                <Row>
                    <Col className="p-0-mob p-0 p-0-tab">My Items ({props.cartItems.length})</Col>
                    <Col className="text-right p-0 p-0-mob p-0-tab"><b>{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode),to2Decimal(props.checkoutDetails.grandtotalamt))}</b></Col>
                </Row>
            </div>
            <Col className="pl-0 pr-0 text-center">
            {showAll ? renderAllItems() : renderfourItems()}
            </Col>
            <div className="text-center show-more-less-footer">
                { props.cartItems.length > 4 ? <button className="btn btn-link p-0 add-new-add-link" onClick={toggleShow}>{showAll ? "Show Less" : "Show More"}</button> : ""}
            </div>
        </Card>
    )
}
const mapStateToProps = state => ({
    cartItems: state.CartData.cartList,
    totalAmount: state.CartData.totalAmount,
    checkoutDetails: state.CheckoutData.checkoutDetails,
	mobileState: state.CategoryData.isMobileMenu,
    loginData: state.AuthData.loginData,
    companyDetailsCurrency: state.HomeData.companyDetailsCurrency,
});
export default connect(mapStateToProps)(FinalOrderList);
