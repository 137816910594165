import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Row, Col, Container, Navbar, Nav,Carousel } from 'react-bootstrap';

const SurpriseDeals = props => {


    return (
        <React.Fragment>
			 <div className="each-section surprise-deals">
                <Container>
                <Row>
                        <Col>
                        <h2 class="section-header text-dark">Surprise Deals Of The Day</h2>
                        </Col>
                        
                    </Row>
                    <Row>
                        <Col>
                        <img src="https://assets.ajio.com/medias/sys_master/images/images/h80/h81/45628248490014/30112021-D-WHP-banner1-p2-kurtas-under999.jpg" className="w-100" />
                        </Col>
                        <Col>
                        <Col>
                        <img src="https://assets.ajio.com/medias/sys_master/images/images/hf5/h62/45628248719390/30112021-D-WHP-banner1-p4-ajiogold-topwear-brands-min40.jpg" className="w-100" />
                        </Col>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    
})

export default connect(mapStateToProps, { })(SurpriseDeals);
