import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { getCategoryList, selectCategory, selectsubCategory, selectsubsubCategory, setMenuState } from '../../actions/CategoryAction';
import Category from './Category';
import { Nav } from 'react-bootstrap';
import { filterByCategory } from '../../actions/ProductsAction';
import Scroller from '../Scroller';

const CategoryMenu = (props) => {

    const { companyid, getCategoryList } = props;

    const navigate = useNavigate();
    const location = useLocation();
    let menuName;
    let menuList;
    let subMenuName;
    let subCategoryItemList;
    let subCategoryItemFinalList;
    let subsubcategoryList;
    let subSubCategoryItemList;
    let subSubMenuList;
    let subSubMenuFinalList;
    let subMenuList;
    let subMenuFinalList;
    let itemId;
    let itemCode;
    let itemName;
    let itemImagepath;
    let menuAlternetcolor;
    let menuforAlignment;
    let subMenuforAlignment;
    // eslint-disable-next-line
    useEffect(() => {
        if (companyid && companyid !== '') {
            getCategoryList(companyid)
        }
    }, [companyid]);

    useEffect(() => {
        props.selectCategory(location.pathname.replace('/category/', ''));
    }, [location.pathname]);

    const onCategorySelect = (eventKey) => {
        //console.log("selected");
        props.selectCategory(eventKey.split(' ')[0].split(':')[1]);
        props.selectsubsubCategory(eventKey);
        if (props.isMobileMenu) {
            props.setMenuState(false)
        }
        if (eventKey != "naumib2blink") {
            if (eventKey.split(' ').length == 1)
                navigate(`/category/${eventKey.split(' ')[0].split(':')[1]}`);
            else if (eventKey.split(' ').length == 2)
                navigate(`/category/${eventKey.split(' ')[0].split(':')[1]}/${eventKey.split(' ')[1].split(':')[1]}`);
            else
                navigate(`/category/${eventKey.split(' ')[0].split(':')[1]}/${eventKey.split(' ')[1].split(':')[1]}/${eventKey.split(' ')[2].split(':')[1]}`);
        }
    }
    function menuClick (itemId){
        //console.log("on menuclick");
        //console.log("itemId",itemId);
        navigate(`/categorySection/${0}/${itemId}/${0}`);
      }

      const toggleClass = (e,i) => {
        e.preventDefault();
          if(props.CategoryList !=undefined && props.CategoryList !=null)
          {
            props.CategoryList.map((item, id) => {
                if(item.sub_category !=undefined && item.sub_category !="" && subMenuList !=null)
                {
                    item.sub_category.map((item1, id1) => {
                       
                        if(item1.id==i)
                        {
                            //console.log(item1.id,'if===>',i)
                        if(document.getElementById(i))
                        document.getElementById(i).className = "zzz ASSSS";
                        }
                        else
                        {
                            //console.log(item1.id,'else===>',i)
                            if(document.getElementById(item1.id))
                            document.getElementById(item1.id).className = "zzz";
                        }
                    });
               
                }
            });   
          }
      };
    //const catMenuData = props.CategoryList.map((category, key) => (<Category category={category} key={key} keyValue={key} />));
    //catMenuData.push(<Nav.Item key="naumib2blink"><Nav.Link key="naumib2blink" eventKey="naumib2blink" href="https://naumiwholesale.uvanij.com" target="_blank">B2B</Nav.Link></Nav.Item>);
    //.. check config
    //const scroll_nav={type:"fa",left:"fa fa-chevron-circle-left arrow-stylecontrol", right:"fa fa-chevron-circle-right arrow-stylecontrol"};
    const dynamicMenuGeneration=()=>{
            if(typeof props.CategoryList.data !=undefined && props.CategoryList.data !="")
            {
              menuList=props.CategoryList.map((item, id) => {
              menuName=item.name; //Dog
              subMenuList= item.sub_category; 
        return (
                    <div className="nav-item">
                        <div>
                        {typeof subMenuList !=undefined && subMenuList !="" && subMenuList !=null?
                        <div className="subcategory_dropdown position-relative level sub_category dropdown nav-item">
                            <a aria-haspopup="true" aria-expanded="false" id="nav-dropdown" href="#" className="dropdown-toggle nav-link" role="button">{item.name}</a>
                         <div className='old-typemenu'>
                                 <div className='old-typemenu-inner'>
                                    <div className=''> 
                                    <div className=''>
                                     <div className=''>
                        {typeof subMenuList !=undefined && subMenuList !="" && subMenuList !=null?
                        
                                subMenuFinalList = item.sub_category.map((item1, id1) => {
                                subMenuName=item1.name;//Dog Food
                                subCategoryItemList=item1.subCategoryItem;
                                subsubcategoryList=item1.sub_sub_category;
                                if(subsubcategoryList !=null && subsubcategoryList.length>0)
                                {
                                    // subSubCategoryItemList=subsubcategoryList.map((item2, id2) => { className={isActive ? 'MegaDropdown-ContentInner ADD': 'MegaDropdown-ContentInner'}


                                    // });
                                    menuAlternetcolor=id1%2==0? 'MegaDropdown-ContentInner':'MegaDropdown-ContentInner';
                                    menuforAlignment=id1%2;
                                    
                                    return (
                                        <div className='zzz' id={item1.id}  onClick={(e) => toggleClass(e, item1.id)}>
                                        <div className={menuAlternetcolor}>
                                            <div className="MegaDropdown-ContentHeading">
                                                <a rel="noopener noreferrer" href="#">{item1.name}
                                                <span className='float-right'> <i class="fa fa-chevron-right roticon" aria-hidden="true"></i> </span>
                                                </a>
                                            </div>
    
                                            <div className="glamnfitstoreBox megaDropdown">
                                                <ul className='pl-0 mb-0 bord-bots'>
                                               
                                                {typeof subsubcategoryList !=undefined && subsubcategoryList !="" && subsubcategoryList !=null?
                                                         
                                                             subCategoryItemFinalList=subsubcategoryList.map((item2, id2) => {
                                                                    subSubMenuList=item2.subSubCategoryItem;
                                                            return (
                                                                <div className='subsubmenumaindiv' id={item2.id}>

<a rel="noopener noreferrer"  className='sub_submenu' href="" onClick={() =>{menuClick(item2.id)}}>{item2.name}
                                                                        
                                                                        </a>
                                                              
                                                                 </div>
                                                                )
                                                            })
                                                          :""}                                                              
                                                </ul>
                                            </div>                                                       
                                        </div>
                                     </div>
    
                                )
                                }
                                else
                                {
                                menuAlternetcolor=id1%2==0? 'MegaDropdown-ContentInner bgWhite':'MegaDropdown-ContentInner  bgGrey';
                                menuforAlignment=id1%2;
                                return (
                                    <div className='zzz' id={item1.id}  onClick={() =>{menuClick(item1.id)}}>
                                    <div className={menuAlternetcolor}>
                                        <div className="MegaDropdown-ContentHeading">
                                            <a rel="noopener noreferrer" href="#">{item1.name}
                                            </a>
                                        </div>
                                    </div>
                                 </div>

                            )
                                                    }
                         })
                        :'' } 
                         </div>
                        
                         </div> 
                    </div>
                        </div>
                    </div> 
                    </div>:
                    <div className='subcategory_dropdown position-relative level sub_category dropdown nav-item' id={item.id}  onClick={() =>{menuClick(item.id)}}>
                            <a className="dropdown-toggle nav-link" rel="noopener noreferrer" href="#">{item.name}
                            </a>
                 </div>}

                    </div></div>
                )
             
          })
        
            }
            return menuList
      }
    function DynamicImageloadforMenuItem(img,id) {
       // img=(img==null)?'https://library.wizardcomm.in/company/petindiab2c/images/Item/Original/DGB5.jpg':img;
        document.getElementById(id).src=img;
      }
      
   // console.log('New Menu',props.CategoryList);
    return (
        <div className={props.menuState ? "menu-items" : "hideclassmain"}>
        <div className="categories-section pb-0">
            <Nav justify variant="pills" className="nav-box"  >
         <Scroller data={dynamicMenuGeneration()} range={3} nav={props.scroll_nav}/> 
         </Nav>
            </div>
        </div>
       
    )
}

const mapStateToProps = state => ({
    companyid: state.HomeData.companyid,
    CategoryList: state.CategoryData.categoryList,
    menuState: state.CategoryData.menuState,
    isMobileMenu: state.CategoryData.isMobileMenu,
    scroll_range: state.HomeData.scroll_range,
    scroll_nav: state.HomeData.scroll_nav
})

export default connect(mapStateToProps, { getCategoryList, selectCategory, selectsubCategory, selectsubsubCategory, filterByCategory, setMenuState })(CategoryMenu);