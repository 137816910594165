import { apiAction } from '../middleware/api/apiActions';
import API from './APIConst';

const PLACE_ORDER = 'PLACE_ORDER';
const CHECKOUT_ORDER = 'CHECKOUT_ORDER';
const SET_MODAL = 'SET_MODAL';
const SPECIAL_REQUEST = 'SPECIAL_REQUEST';
const SET_PROMO_MODAL = 'SET_PROMO_MODAL';
const VALIDATE_DISCOUNT = 'VALIDATE_DISCOUNT';
const CLEAN_DISCOUNT = 'CLEAN_DISCOUNT';
const CLEAR_ORDER = 'CLEAR_ORDER';
const RAZOR_PAY1 ='RAZOR_PAY1';
const RAZOR_PAY_SUCCESS='RAZOR_PAY_SUCCESS';
const RAZOR_PAY_FAILURE='RAZOR_PAY_FAILURE';
const RAZOR_PAY_STATUS='RAZOR_PAY_STATUS';
const PAYMENT_MODE="PAYMENT_MODE";
const SAVE_PAYMENT="SAVE_PAYMENT";
const SELECTED_VOUCHER="SELECTED_VOUCHER";
const VALIDATE_OTP="VALIDATE_OTP";
const TERMS_ACCEPT="TERMS_ACCEPT";
const SEND_OTP="SEND_OTP";
const ADD_ADDRESS="ADD_ADDRESS";
const DELETE_ADDRESS="DELETE_ADDRESS";
const PHONE_PE_DATA="PHONE_PE_DATA";
const FETCHED_PHONE_PE_DATA="FETCHED_PHONE_PE_DATA";
const CHECK_PAYMENT_STATUS='CHECK_PAYMENT_STATUS';
const SET_PROMO_CODE='SET_PROMO_CODE';
const SET_PROMO_APPLIED='SET_PROMO_APPLIED';
const CLEAN_DISCOUNT_D='CLEAN_DISCOUNT_D';
const GET_DELIVERY_CHARGE='GET_DELIVERY_CHARGE';

/**
 * Action placeOrder defined.
 * @constructor
 * @param {string} param - Pass param to update the payload.
 */


export const setPromoCode = param => ({
    type: SET_PROMO_CODE,
    payload: param
});

export const setAcceptTerms = param => ({
    type: TERMS_ACCEPT,
    payload: param
});

export const setPromoApplied = param => ({
    type: SET_PROMO_APPLIED,
    payload: param
});

export const cleanDiscountD = param => ({
    type: CLEAN_DISCOUNT_D,
    payload: param
});


export const savePayment = (obj) => {
    return apiAction({
        url: API.SAVE_PAYMENT,
        method: "post",
        data: obj,
        onSuccess: savePaymentdone,
        onFailure: () => console.log("Error occured in save payment"),
        label: SAVE_PAYMENT
    });
}
export const getDeliveryCharge = (obj) => {
    return apiAction({
        url: API.GET_DELIVERY_CHARGE,
        method: "post",
        data: obj,
        onSuccess: fetchedDeliveryCharge,
        onFailure: () => console.log("Error occured in save payment"),
        label: GET_DELIVERY_CHARGE
    });
}

export const fetchedDeliveryCharge=param=>({
    type:GET_DELIVERY_CHARGE,
    payload:param.data
});

export const savePaymentdone = param => ({
    type: SAVE_PAYMENT,
    payload: param
});

export const addAddress = (obj) => {
    return apiAction({
        url: API.ADD_ADDRESS,
        method: "post",
        data: obj,
        onSuccess: addAddressDone,
        onFailure: () => console.log("Error occured in addAddress"),
        label: ADD_ADDRESS,
		callback:'addaddress'
    });
}

export const addAddressDone = param => ({
    type: ADD_ADDRESS,
    payload: param
});

export const deleteAddress = (obj) => {
    return apiAction({
        url: API.DELETE_ADDRESS,
        method: "post",
        data: obj,
        onSuccess: deleteAddressDone,
        onFailure: () => console.log("Error occured in deleteAddress"),
        label: DELETE_ADDRESS
    });
}

export const deleteAddressDone = param => ({
    type: DELETE_ADDRESS,
    payload: param
});

export const checkOutOrder = (obj) => {
    return apiAction({
        url: API.CHECKOUT_ORDER,
        method: "post",
        data: obj,
        onSuccess: orderCheckedOut,
        onFailure: () => console.log("Error occured in placeOrder"),
        callback: 'checkOutOrder',
        label: CHECKOUT_ORDER
    });
}


export const orderCheckedOut = param => ({
    type: CHECKOUT_ORDER,
    payload: param.data
});

/**
 * Action placeOrder defined.
 * @constructor
 * @param {string} param - Pass param to update the payload.
 */
export const placeOrder = (obj) => {
    return apiAction({
        url: API.PLACE_ORDER,
        method: "post",
        data: obj,
        onSuccess: orderPlaced,
        onFailure: () => console.log("Error occured in placeOrder"),
        label: PLACE_ORDER,
		callback: 'placeordercon'
    });
}
export const placeOrderCash = (obj) => {
    return apiAction({
        url: API.PLACE_ORDER,
        method: "post",
        data: obj,
        onSuccess: orderPlaced,
        onFailure: () => console.log("Error occured in placeOrder"),
        label: PLACE_ORDER,
		callback: 'placeordercash'
    });
}
export const initiatePhonePe = (obj) => {
    return apiAction({
        url: API.INITIATE_PHONE_PE_PAYMENT,
        method: "post",
        data: obj,
        onSuccess: placedPhonePeOrder,
        onFailure: () => console.log("Error occured in initiatephonepe"),
        label: PHONE_PE_DATA,
		callback: 'initiatephonepe'
    });
}

export const fetchPhonePeStatus = (obj) => {
    return apiAction({
        url: API.FETCH_PHONE_PE_STATUS,
        method: "post",
        data: obj,
        onSuccess: fetchedPhonePeData,
        onFailure: () => console.log("Error occured in fetchPhonePeStatus"),
        label: FETCHED_PHONE_PE_DATA,
		callback: 'phonepesuccess'
    });
}

export const fetchPaymentStatus = (obj) => {
    return apiAction({
        url: API.CHECK_PAYMENT_STATUS,
        method: "post",
        data: obj,
        onSuccess: fetchedPaymentStatus,
        onFailure: () => console.log("Error occured in fetchPaymentStatus"),
        label: CHECK_PAYMENT_STATUS,
    });
}
export const fetchedPaymentStatus = param => ({
    type: CHECK_PAYMENT_STATUS,
    payload: param.data
});

export const orderPlaced = param => ({
    type: PLACE_ORDER,
    payload: param.data
});
export const placedPhonePeOrder = param => ({
    type: PHONE_PE_DATA,
    payload: param.data
});
export const fetchedPhonePeData = param => ({
    type: FETCHED_PHONE_PE_DATA,
    payload: param
});

//...............................................................
/**
 * Action validateDiscount defined.
 * @constructor
 * @param {string} param - Pass param to update the payload.
 */
export const validateDiscount = (obj) => {
    return apiAction({
        url: API.VALIDATE_DISCOUNT,
        method: "post",
        data: obj,
        onSuccess: discountValidated,
        onFailure: () => console.log("Error occured in validateDiscount"),
        label: VALIDATE_DISCOUNT
    });
}
export const razorPay1func = (obj) => {
    return apiAction({
        url: "https://paymentmanagement.wizardcomm.in/Payment/ProcessRazorPayment",
        method: "post",
        data: obj,
        onSuccess: razorPay1Validated,
        onFailure: () => console.log("Error occured in razor Pay 1"),
        label: RAZOR_PAY1,
		callback: 'razorpay1'
    });
}
export const razorpaysuccess_func = (obj) => {
    return apiAction({
        url: "https://paymentmanagement.wizardcomm.in/Payment/RazorpayPaymentStatus",
        method: "post",
        data: obj,
        onSuccess: razorPaySuccessDone,
        onFailure: () => console.log("Error occured in razor Pay Success"),
        label: RAZOR_PAY_SUCCESS,
		callback: 'razorpaysuccess'
    });
}

export const razorPaySuccessDone = param => ({
    type: RAZOR_PAY_SUCCESS,
    payload: param
});

export const razorpayfailure_func = (obj) => {
    return apiAction({
        url: "https://paymentmanagement.wizardcomm.in/Payment/RazorpayOnDismiss",
        method: "post",
        data: obj,
        onSuccess: razorPayFailureDone,
        onFailure: () => console.log("Error occured in razor Pay Success"),
        label: RAZOR_PAY_FAILURE,
		callback: 'razorpayfailure'
    });
}

export const razorPayFailureDone = param => ({
    type: RAZOR_PAY_FAILURE,
    payload: param
});




export const discountValidated = param => ({
    type: VALIDATE_DISCOUNT,
    payload: param.data
});

export const razorPay1Validated = param => ({
    type: RAZOR_PAY1,
    payload: param
});


//...................................................................
/**
 * Action setModal defined.
 * @constructor
 * @param
 */
export const setModal = (param) => ({
    type: SET_MODAL,
    payload: param
});
//...................................................................
/**
 * Action setPromModal defined.
 * @constructor
 * @param
 */
export const setPromModal = (param) => ({
    type: SET_PROMO_MODAL,
    payload: param
});

export const setRazorPayStatus = (param) => ({
    type: RAZOR_PAY_STATUS,
    payload: param
});

//...................................................................
/**
 * Action cleanDiscount defined.
 * @constructor
 * @param
 */
export const cleanDiscount = () => ({
    type: CLEAN_DISCOUNT,
    payload: null
});

/**
 * Action reset checkout.
 * @constructor
 * @param
 */
export const clearOrder = () => ({
    type: CLEAR_ORDER,
    payload: null
});

export const setSelectedvoucher = (param) => ({
    type: SELECTED_VOUCHER,
    payload: param
});



//................................................................... 
export const set_special_request = (param) => ({ type: SPECIAL_REQUEST, payload: param })
export const set_paymentmode = (param) => ({ type: PAYMENT_MODE, payload: param })
/**
 * set up with initial state.
 */
const initialState = {
    items: [],
    checkoutDetails: {},
	checkoutDetails_backup:{},
    special_request: '',
    payment_mode: 'COD',
    delivery_slot: '',
    selected_voucher: {},
    confirmationModal: false,
    promoModal: false,
    order: {},
    discountDetails: {},
	razorpay1: {},
	razorpaysuccess: {},
	razorpayfailure: {},
	razorpaystatus: {},
	save_payment: {},
	addaddressdata:{},
	deleteaddressdata:{},
	phonePeData:{},
    fetchedPhonePeData:{},
    fetchedPaymentStatusData:{},
    promoCode:'',
    promoApplied:false,
    acceptTerms:false

}

/**
 * reducer .
 * @constructor
 * @param {string} state - state of the data shape.
 * @param {string} action - action payload passed for data updates.
 */
let reducer = (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_ORDER: return initialState
        case PLACE_ORDER: return { ...state, order: action.payload, confirmationModal: true,promoCode:'',promoApplied:false,discountDetails: {}, selected_voucher: {},acceptTerms:{}}
        case CHECKOUT_ORDER: return { ...state, checkoutDetails: action.payload }
		case CHECKOUT_ORDER: return { ...state, checkoutDetails: action.payload }
        case GET_DELIVERY_CHARGE:{
        let checkoutDetails=JSON.parse(JSON.stringify(state.checkoutDetails));
        checkoutDetails.grandtotalamt=checkoutDetails.grandtotalamt-checkoutDetails.deliverychargeamt;
        checkoutDetails.deliverychargeamt=Number(action.payload?action.payload:0);
        checkoutDetails.grandtotalamt=checkoutDetails.grandtotalamt+Number(action.payload?action.payload:0);
         return { ...state, checkoutDetails: checkoutDetails }
        }
        case SET_MODAL: return { ...state, confirmationModal: action.payload }
        case SET_PROMO_MODAL: return { ...state, promoModal: action.payload }
        case SPECIAL_REQUEST: return { ...state, special_request: action.payload }
        case CLEAN_DISCOUNT: return { ...state, promoCode:'',promoApplied:false,discountDetails: {}, selected_voucher: {},checkoutDetails:state.checkoutDetails_backup }
        case CLEAN_DISCOUNT_D: return { ...state, promoCode:'',promoApplied:false,discountDetails: {}, selected_voucher: {}}
		case RAZOR_PAY1: return { ...state, razorpay1: action.payload }
		case RAZOR_PAY_SUCCESS: return { ...state, razorpaysuccess: action.payload }
        case TERMS_ACCEPT: return { ...state, acceptTerms: action.payload }
		case RAZOR_PAY_FAILURE: return { ...state, razorpayfailure: action.payload }
		case RAZOR_PAY_STATUS: return { ...state, razorpaystatus: action.payload }
		case SELECTED_VOUCHER: return { ...state, selected_voucher: action.payload }
		case PAYMENT_MODE: return { ...state, payment_mode: action.payload }
		case SAVE_PAYMENT: return { ...state, save_payment: action.payload }
		case ADD_ADDRESS: return { ...state, addaddressdata: action.payload }
		case DELETE_ADDRESS: return { ...state, deleteaddressdata: action.payload }
		case PHONE_PE_DATA: return { ...state, phonePeData: action.payload }
        case FETCHED_PHONE_PE_DATA: return { ...state, fetchedPhonePeData: action.payload }
        case CHECK_PAYMENT_STATUS: return { ...state, fetchedPaymentStatusData: action.payload }
        case SET_PROMO_CODE: return { ...state, promoCode: action.payload }
        case SET_PROMO_APPLIED: return { ...state, promoApplied: action.payload,checkoutDetails_backup:state.checkoutDetails}
        case VALIDATE_DISCOUNT: return {
            ...state,
            promoModal: false,
            discountDetails: action.payload,
			checkoutDetails_backup: state.checkoutDetails,
            checkoutDetails: { ...state.checkoutDetails, ...action.payload.order },
            selected_voucher: action.payload.vouchers,
        }
        default: return state;
    }
}

//...................................................................
//.. selectors :: TODO
export function selectCheckOutJSON(state) {
    return {
        "companyid": state.HomeData.companyid,
        "ordered": {
            "items": state.CartData.cartList.map(item => {
                return {
                    id: item.id,
                    variantid: item.variantid,
                    rate: item.offer ? item.offer.offerSalePrice : item.price_regular,
                    quantity: item.quantity,
                    status: "order placed"
                }
            })
        },
        "discountcode":state.CheckoutData.promoCode
    }
}

export function selectValidateDiscountJSON(state) {
    return {
        "companyid": state.HomeData.companyid,
        "order": {
            "date": state.CheckoutData.checkoutDetails.date,
            "totalamt": state.CartData.totalAmount,
            "totaltaxamt": state.CheckoutData.checkoutDetails.totaltaxamt,
            "deliverychargeamt": state.CheckoutData.checkoutDetails.deliverychargeamt,
            "grandtotalamt": state.CheckoutData.checkoutDetails.grandtotalamt
        }
    }
}
export function selectPlaceOrderJSON(state) {
    return {
        "companyid": state.HomeData.companyid,
        "customer": {
			"userid": (state.AuthData.authenticated)?state.AuthData.loginData.data.id:null,
            "addrid": state.UserData.addrid,
            "fname": state.UserData.fname,
			"mname":state.UserData.mname,
            "lname": state.UserData.lname,
            "phone": state.UserData.phone==""?state.AuthData.updatedUserPhoneNumber:state.UserData.phone,
            "email": state.UserData.email,
            "address1": state.UserData.address1,
            "address2": state.UserData.address2,
            "pin": state.UserData.pin,
            "city": state.UserData.city,
            "dist": state.UserData.dist,
            "state": state.UserData.state,
            "country": "India",
            "type": state.UserData.type,
            "spclrequest": state.CheckoutData.special_request,
            "paymentmode": state.CheckoutData.payment_mode,
            "deliveryslot": state.CheckoutData.checkoutDetails.deliveryslot
        },
        "order": {
            "orderdate": state.CheckoutData.checkoutDetails.date,
            "totalamt": state.CheckoutData.checkoutDetails.totalamt,
            "totaltaxamt": state.CheckoutData.checkoutDetails.totaltaxamt,
            "deliverychargeamt": state.CheckoutData.checkoutDetails.deliverychargeamt,
            "grandtotalamt": state.CheckoutData.checkoutDetails.grandtotalamt,
            "discount_code": state.CheckoutData.selected_voucher.code || '',
            "discount_total": (String(state.CheckoutData.checkoutDetails.discount_total)=="undefined")? '0':String(state.CheckoutData.checkoutDetails.discount_total),
            "data": state.CheckoutData.checkoutDetails.item
        }
    }
}
//...................................................................

/**
 * Export Object (Action + Reducer)
 */
const CheckoutAction = {
    reducer: reducer,
    actions: {
        checkOutOrder,
        placeOrder,
        setModal,
        set_special_request,
        setPromModal,
        validateDiscount,
        cleanDiscount,
		razorPay1func,
		razorpayfailure_func,
		razorpaysuccess_func,
		setRazorPayStatus,
		savePayment,
		addAddress,
		deleteAddress,
		initiatePhonePe,
        fetchPhonePeStatus,
        fetchPaymentStatus,
        setPromoCode,
        setPromoApplied,
        cleanDiscountD,
        setAcceptTerms
    }
}

export default CheckoutAction

