import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { set_special_request } from '../../actions/CheckoutAction';
import Info from '../Info';

const Delivery = (props) => {
    const [completed, setCompleted] = useState(false);
    const [check, setCheck] = useState(true);
    const [rqst, setRqst] = useState("");
	const info_content=<><p>will be shipped within next 2 working days</p>&nbsp;<p>shipping may delay due to COVID and delivery partners from their end</p>&nbsp;<p>There will be no charge above purchase of Rs5000/-</p>&nbsp;<p>We will charge Rs 50 on purchase of below  RS2000/- , shipping charge will vary 2k to 3k = Rs70 , 3k to 4k =Rs 85,4k to below 5k=Rs100  till the purchase of Rs5000/- ( initially We will follow shipping with amount)</p></>

    useEffect(() => {
        if(props.special_request) {
            setRqst(props.special_request);
        }
    }, [props.special_request])
	
	useEffect(()=>{
		//props.set_special_request("");
		setRqst("");
	},[]);
    
    const handleOnChange = e => {
        setRqst(e.target.value)
        props.set_special_request(e.target.value);
        setTimeout(()=>{props.setDeliveryCard(false);props.setPymentCard(true);}, 500)
        setCompleted(true)
    }
    const nextHandler = () => {
        props.set_special_request(rqst);
        setTimeout(()=>{props.setDeliveryCard(false);props.setPymentCard(true);}, 500)
        setCompleted(true)
    }
    return (
        <React.Fragment>
            <Card className="mb-2">
                <Col className='pt-3'>
                <div className="ship-add-head">
                        Delivery Details
						{/* <Info title="Delivery and Shipping Charge" content={info_content} /> */}
                </div>
                </Col>
                {/* <Card.Body style={{ display: props.deliveryCard ? "block" : "none" }}> */}
                <Col style={{ display: true ? "block" : "none" }}>
                    <Row className='mt-3'>
                        {props.deliveryslot && <Col md="12 p-0">
                            <input type="checkbox" checked={check} onChange={() => setCheck(!check)} />&nbsp;&nbsp;
                        <label>{props.deliveryslot}</label>
                        </Col>}
                        <Form.Label className="form-group-labels">Special Request</Form.Label>
                        <Form.Control className="mb-4" as="textarea" name="rqst" value={rqst} onChange={handleOnChange} rows="2" />
                    </Row>
                    
                    <Row>
                        <Col md="12 text-right p-0 mt-2 mb-2" style={{ display: false ? "block" : "none" }}>
                            <div>{<button className="btn btn-success bgpinbutton position-relative min-w-save-cancelo" disabled={!check} onClick={nextHandler}>Save</button>}</div>
                        </Col>
                    </Row>
                </Col>
            </Card>
            {/*<p class="del-text"> <i class="fa fa-info-circle" aria-hidden="true"></i>Delivery Time Inside Dhaka - 2-3 days &amp; Outside Dhaka - 3-5 days</p>*/}
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    deliveryslot: state.CheckoutData.checkoutDetails.deliveryslot,
    special_request: state.CheckoutData.special_request
})

export default connect(mapStateToProps, { set_special_request })(Delivery);
