import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Product from '../components/Product';
import { Container, Row , Col} from 'react-bootstrap';

import { getfeaturedItems } from '../actions/ProductsAction';

const FeaturePanel = props => {

    const {companyid, getfeaturedItems, productsList, featuredItems} = props;
    
    useEffect(() => {
        if(companyid && companyid !== '') {
            getfeaturedItems(companyid);
          }
    }, [getfeaturedItems, companyid]);
    
    
    const myArray = productsList;
    const myFilter = featuredItems;

    const productsData = myArray.filter(array => myFilter.some(filter => filter === array.id)).map((product)=>{
        return <Product key={product.id} product={product} />
    });

    return (
        <div className="products-wrapper">
            
                {/* <Row>
                    <Col>
                        <h4 className="pt-3 pb-3 mb-3 bord-bot-theme"><b>Our Best Sellers</b></h4>
                    </Col>
                </Row> */}
                <div className="mar-0-auto text-center">
                    {productsData}
                </div>
           
        </div>
    );
}

const mapStateToProps = state => ({
    companyid:state.HomeData.companyid,
    productsList: state.ProductsData.productsList,
    featuredItems: state.ProductsData.featuredItems,
})

export default connect(mapStateToProps, {getfeaturedItems})(FeaturePanel);
