import React from 'react';
import Progress from '../checkout/Progress';

const OrderPlaceHeader = () => (
  <div>
    <h1>Order Place</h1>
    <Progress />
  </div>
);

export default OrderPlaceHeader;