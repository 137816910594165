import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { setModal } from '../../actions/CheckoutAction';
import { clearCart } from '../../actions/CartAction';
import { cleanDiscount, clearOrder } from '../../actions/CheckoutAction';
import { clear_userDetails } from '../../actions/UserAction';
import {useNavigate} from 'react-router-dom';
import { connect } from 'react-redux';

const GenericModal = (props) => {
    const navigate = useNavigate();
    const handleClose = () => {
        if(props.razorpaystatus.status)
		{props.clearCart();
        props.cleanDiscount();
        props.clearOrder();
		}
        //props.clear_userDetails();
        props.setModal(false);
        navigate('/');
    }

    let primaryColor=props.petTemplateDetails && props.petTemplateDetails.primaryColor?props.petTemplateDetails.primaryColor:'';
    let secondaryColor=props.petTemplateDetails && props.petTemplateDetails.secondaryColor?props.petTemplateDetails.secondaryColor:'';
    let tertiaryColor=props.petTemplateDetails && props.petTemplateDetails.tertiaryColor?props.petTemplateDetails.tertiaryColor:'';

    return (
        <React.Fragment>
             <Modal show={props.confirmationModal} onHide={handleClose} centered>
                <Modal.Header closeButton className='custom-modal-header pt-2 pb-2'> 
                    <Modal.Title className='thanks-modtitle'>{(props.razorpaystatus.status)?"Thank You":"Payment Failed"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='fs-14px-desh' dangerouslySetInnerHTML={{__html:props.order.orderStatus}}/>
					<div className='fs-14px-desh' dangerouslySetInnerHTML={{__html:props.razorpaystatus.message}}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button style={{backgroundColor:primaryColor}} className='primary-button-nocolor min-same-widthxs pt-2 pb-2 mb-2 ' onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}
const mapStateToProps = state => ({
    confirmationModal:state.CheckoutData.confirmationModal,
    order:state.CheckoutData.order,
	razorpaystatus: state.CheckoutData.razorpaystatus,
    petTemplateDetails: state.petProductData.userP_AllProductInfo.data,
})
export default connect(mapStateToProps,{ setModal,
                                         clearCart,
                                         clearOrder,
                                         cleanDiscount,
                                         clear_userDetails }) (GenericModal);