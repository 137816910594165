import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { sendOtp, clearOtpStatus,sendOtpForLogin } from '../../actions/AuthAction';
import {convertTime} from '../../utils/CommonUtil';

const ResendOtp = props => {

    const [seconds, setSeconds] = useState(300);
    const [isActive, setIsActive] = useState(false);

    const startCountDown = (time) => {
        setSeconds(time);
        setIsActive(true);
    }

    useEffect(() => {
        let interval = null;
        if (isActive) {
            interval = setInterval(() => {
                setSeconds(seconds => seconds - 1);
            }, 1000);
            if(seconds === 0) {
                setIsActive(!isActive);
            } 
        } else {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isActive, seconds]);

    useEffect(() => {
        if(props.otpSendStatus.status && props.otpSendStatus.data && props.otpSendStatus.data.isOTPSent){
            startCountDown(3*60); //value converted to sec    
        }
    },[props.otpSendStatus]);

    const resendHandler = () => {
        props.clearOtpStatus();
        if(props.loginMode)
        {
            props.sendOtpForLogin({CompanyId:props.companyid,PhoneNo:props.phonenumber,PhoneCode:props.phoneCodeValue,ipAddress:props.ipAddress})
        }
        else
        {
            props.sendOtp({CompanyId:props.companyid,PhoneNo:props.phonenumber,PhoneCode:props.phoneCodeValue,ipAddress:props.ipAddress})
        }
    }

    return (
        <React.Fragment>
            <div className="w-100 text-left">
            <button className="btn btn-link text-danger pl-0" disabled={isActive} onClick={resendHandler}>Resend OTP</button>
           {isActive ? convertTime(seconds) : null}
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    otpSendStatus:state.AuthData.otpSendStatus,
    phone:state.UserData.phone,
    companyid: state.HomeData.companyid,
    ipAddress:state.AuthData.ipAddress
})

export default connect(mapStateToProps, { sendOtp, clearOtpStatus,sendOtpForLogin }) (ResendOtp);