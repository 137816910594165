import { apiAction } from '../middleware/api/apiActions';
import API from './APIConst';

const P_ALLPRODUCT = "P_ALLPRODUCT";
const P_ALLPRODUCTFORLAZYLOAD = "P_ALLPRODUCTFORLAZYLOAD";

export const P_ProductInfo = (obj) => {
    return apiAction({
        url: API.P_ALLPRODUCT,
        method: "post",
        data: obj,
        onSuccess: setP_ProductInfo,
        onFailure: () => console.log("Error occured in setWalletInfo"),       
        label: P_ALLPRODUCT
    });
}
export const P_ProductInfoForLazyLoad = (obj) => {
    return apiAction({
        url: API.P_ALLPRODUCTFORLAZYLOAD,
        method: "post",
        data: obj,
        onSuccess: setP_ProductInfoForLAzyLoad,
        onFailure: () => console.log("Error occured in setWalletInfo"),       
        label: P_ALLPRODUCTFORLAZYLOAD
    });
}

/**
 * setWalletInfo Action define.
 * @constructor
 * @param {object} param - Pass object as payload to check WalletInfo authentication.
 */
 export const setP_ProductInfo = param => {
    return {
        type: P_ALLPRODUCT,
        payload: param
    }
};

/**
 * setWalletInfo Action define.
 * @constructor
 * @param {object} param - Pass object as payload to check WalletInfo authentication.
 */
 export const setP_ProductInfoForLAzyLoad = param => {
    return {
        type: P_ALLPRODUCTFORLAZYLOAD,
        payload: param
    }
};

/**
 * set up with initial state.
 */
 const initialState = {
    userP_AllProductInfo:{},
    userP_AllProductInfoForLazyLoad:{}
}
/**
 * reducer .
 * @constructor
 * @param {string} state - state of the data shape.
 * @param {string} action - action payload passed for data updates.
 */
 let reducer = (state = initialState, action) => {
    switch (action.type) {
        case P_ALLPRODUCT: return {...state, userP_AllProductInfo: action.payload } 
        case P_ALLPRODUCTFORLAZYLOAD: return {...state, userP_AllProductInfoForLazyLoad: action.payload } 
		default: return state;
    }
}
/**
 * Export Object (Action + Reducer)
 */
 const P_ProductsAction = {
    reducer: reducer,
    actions: {
        P_ProductInfo,
        P_ProductInfoForLazyLoad
    }
}

export default P_ProductsAction