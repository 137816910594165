import React, {useEffect,useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Container, Card, Table,Accordion} from 'react-bootstrap';
import { formatINR,formatSymbolFromCurrency } from '../../utils/CommonUtil';
import {getOrderHistory,cancelOrderItem,getCancelReason} from '../../actions/UserAction';
import {setMsgModal} from '../../actions/AuthAction';
import {saveRating} from '../../actions/ProductsAction';
import ReactStars from "react-rating-stars-component";
import Info from '../Info';
import moment from 'moment';
import { toast } from "react-toastify";
import { Global } from '../../utils/Env';
import MsgModal from '../../container/MsgModal';
import OrderItem from './OrderPreviewItem';
import ImageUtil from '../../utils/ImageUtil';
import CancelReason from './CancelReason';

/**
 * Creates a new OrderedItemTable.
 * @class
 */
const AllOrders = props => {
	
	var CryptoJS = require("crypto-js");
	
	const openAckPage = (id)=>{
		var ciphertext = CryptoJS.AES.encrypt(String(id), 'test123').toString();
		//console.log(ciphertext);
		let counter=0;
		while(ciphertext.indexOf('+')!=-1)
		{
			counter++
			ciphertext = CryptoJS.AES.encrypt(String(id), 'test123').toString();
			//console.log(ciphertext);
			if(ciphertext.indexOf('+')==-1)
			window.open("/order_print/"+encodeURIComponent(ciphertext), '_blank');
		}
		if(counter==0)
		window.open("/order_print/"+encodeURIComponent(ciphertext), '_blank');
		
	}
	
	const openInvoice = (id)=>{
		
		var ciphertext = CryptoJS.AES.encrypt(String(id), 'test123').toString();

		//console.log(ciphertext);
		let counter=0;
		while(ciphertext.indexOf('+')!=-1)
		{
			counter++
			ciphertext = CryptoJS.AES.encrypt(String(id), 'test123').toString();
			//console.log(ciphertext);
			if(ciphertext.indexOf('+')==-1)
			window.open("/invoice/"+encodeURIComponent(ciphertext), '_blank');
		}
		if(counter==0)
		window.open("/invoice/"+encodeURIComponent(ciphertext), '_blank');
	}
	
	const [from,setFrom]=useState(1);
	const [to,setTo]=useState(6);
	const [cancelModal,setCancelModal]= useState(false);
	const [cancelItem,setCancelItem]= useState(null);
	const [cancelReason, setCancelReason]= useState([]);
	const [cancelExplanation, setCancelExplanation]= useState(null);
	const cancel=(ob)=>
	{
		let payload={
			"OrderId":ob.orderId,
			"CompanyId":props.companyid,
			"InvoiceId":ob.invoiceId,
			"OrderDetailId":ob.orderDetailId,
			"InvoiceDetailId":ob.invoiceDetailId,
			"timestamp":moment().format('YYYY-MM-DD'),
			"cancelreasonId":8,
			"Explanation":"done"
			}

		setCancelItem(payload);
		setCancelModal(true);
		setCancelExplanation(null);
		setCancelReason([]);
		props.getCancelReason({
		"CompanyId":112
		});

		
			
	}

	const postCancelItem=()=>{
		if(!cancelReason)
		toast.error("please select valid cancel reason");
		else
		{
			let temp=JSON.parse(JSON.stringify(cancelItem));
			temp["cancelreasonId"]=cancelReason;
			temp["Explanation"]=cancelExplanation;
			props.cancelOrderItem(temp);
			setCancelItem(null);
			setCancelModal(false);
			setCancelExplanation(null);
			setCancelReason([]);
		}
	}

	const invoice_error=()=>
	{
		props.setMsgModal({"title": "Invoice Order","message": "The order is not yet invoiced.","show": true})
	}
	const info_content=<><p>can claim to return within 7 days of receipt of the order </p>&nbsp;<p>refund will be initiated only on receipt of the order and subject to the terms and conditions</p>&nbsp;<p>The product must be returned in its original condition along with the invoice, all labels, tags should be present in the garments </p>&nbsp;<p>Any garment sought to be returned or exchanged, should be unworn or unwashed. Garments that are found to be worn or damaged or soiled will not be accepted for return or exchange</p>&nbsp;<p>Please note an order can only cancelled within 24 hours of placing the order. Once the order is processed after 24 hours, no cancellation request will be entertained.</p>&nbsp;<p>As part of usual business practice, Naumi reserves the right to cancel the order and refund the entire amount back to you, if order has not been approved / processed by Naumi.</p></>
	
	const return_order=()=>
	{
		props.setMsgModal({"title": "Return Order","message": "Please call up 033-40653034/+91 80172 14927 to request for returning the product.","show": true})
	}
	
		
	const reduce=()=>{
		setTo(to-5);
		setFrom(from-5);
	}
	const increase=()=>{
		setTo(to+5);
		setFrom(from+5);
	}
		

    useEffect(() => {
        props.getOrderHistory({"CompanyId":props.companyid,"UserId":props.loginData.data.id,"RecordFrom":from,"RecordTo":to}); 
        },[from,to]);

  
	const handleReturn=()=>{
		props.setMsgModal({"title":"Return Item","message":"Please call up 033-40653034/+91 80172 14927 to request for returning the product.","show":true});
	}
		  
	  const handleNotReturn=()=>{
		props.setMsgModal({"title":"Return Item","message":"This item is not eligible for return.","show":true});
		
	}
	  const ratingChanged = (newRating,itemId,itemVariantId) => {
		props.saveRating({"CompanyId":props.companyid,"ItemId":itemId,"VariantId":itemVariantId,"CustomerId":parseInt(props.loginData.data.customerId),"Rating":newRating,"Note":""});
		props.getOrderHistory({"CompanyId":props.companyid,"UserId":props.loginData.data.id,"RecordFrom":from,"RecordTo":to}); 
		};
		
	Global.callback.saverating_onComplete = () => {
		if(props.saveratingdata.status)
		{
			props.setMsgModal({"title":"Save Rating","message":"Your rating has been saved. Thank you for your feedback.","show":true});
		}
	}
	Global.callback.cancelorderdata_onComplete = () => {
			props.setMsgModal({"title":props.cancelOrderData.status?'Success':'Failure',"message":props.cancelOrderData.message,"show":true});
	}
 
    const renderTable = () => {
        
            return (typeof props.orderhistory.data != "undefined" && props.orderhistory.data!=null && props.orderhistory.data.data!=null) ? props.orderhistory.data.data.map((item, i) => {
                return (
						<Card style={{marginBottom:"20px"}}>
                                <Card.Body>
                                    <Row>
									 <Col className="pl-0 fs-14 col-md-4">
									 <p className="mb-0"><b>Order Date/Time: </b>{(item.custOrdNo!=null)?item.orderDateStr:"Not Generated"}</p>
									 <p className="mb-0"><b>Order Status: </b>{item.orderStatus}</p>
									 <p className="mb-0"><b>Order Amount: </b>{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode),(item.total))}</p>
									 <p className="mb-0"><b> Paid Amount: </b>{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode),(item.paidAmount))}</p>
									 {/*<p className="mb-0 mt-2">{(item.isCancel)?<a href="#" className='ret-item' onClick={()=>cancel(item)}><b>Cancel Order</b></a>:((item.isReturn)?<a href="#" className='ret-item' onClick={return_order}><b>Return Order</b></a>:<i className="not_cancel"><b>Not Cancellable</b></i>)}</p>*/}
									 <p className="mb-0 mt-2"><a href="#" className='ret-item' onClick={()=>cancel(item)}><b>Cancel Order</b></a></p>
									 </Col>
									 
									 <Col className="pl-0 fs-14 col-md-4">
									 <p className="mb-0"><b>Sub Total: </b>{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode),(item.subTotal))}</p>
									 <p className="mb-0"><b>Total Tax: </b>{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode),(item.tax))}</p>
									 <p className="mb-0"><b> Delv Chrg: </b>{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode),(item.delvCharge))}</p>
									 <p className="mb-0"><b> Discount: </b>{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode),(item.discount))}</p>
									 </Col>
                                  
								
							
										
                                    </Row>
									<Row className="cart-item border mt-2 " >

										<Col md="2" xs="3" className="p-0-5-tab pl-1 pr-1">
											<div className="chkout-main-imgpanel gg">
												<img
													className="product-image product-image-cart"
													src={item.imageName ? ImageUtil.getImage(item.imageName) : ImageUtil.getErrorImage()}

													alt={item.imageAlt}
													
												/>
											</div>
										</Col>
										<Col md="10 p-0" xs="9">
											<Col md="12" className="text-left pl-0 pl-0-mob">
											<p className="product-name-order product-name product-name-cart mb-2 "><b>{`${item.name} `}</b></p>
											
											<Row>
											<Col md="6">
												{item.color && item.color!=''?<p className='prod-variant-text'>Color: <span className='a-text-regular col8f8f8f fsize13'>{item.color}</span></p>:''}
												{item.size && item.size!=''?<p className='prod-variant-text'>Size: <span className='a-text-regular col8f8f8f fsize13'>{item.size}</span></p>:''}
												<p className="product-name product-name-cart mb-1">Ordered Quantity: <b>{item.qty}</b></p>
												<p className="product-name product-name-cart mb-1">Price per unit: <b>{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode),(item.price))}</b></p>
												<p className="product-name product-name-cart mb-1">Tax per unit: <b>{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode),((item.tax/item.qty)))}</b> @ (<b>{item.taxRate}%)</b></p>
												{item.isReturn?<p className="product-name product-name-cart mb-0">Last Return Date: <b>{item.lastReturnDate}</b></p>:null}
												
												
												</Col>
												<Col md="6">
												<p className="product-name product-name-cart mb-1">Invoice Quantity: <b>{item.invQty}</b></p>
												<p className="product-name product-name-cart mb-1">Invoice Date: <b>{item.invDate}</b></p>
												<p className="product-name product-name-cart mb-1">Invoice Status: <b>{item.status}</b></p>
												</Col>
												<Col md="12">
												<p className="product-name product-name-cart mb-0 mt-4"><b>Your Rating for this Item:</b></p>
												<ReactStars
												count={5}
												onChange={(newRating)=>ratingChanged(newRating,item.itemId,item.itemVariantId)}
												size={24}
												value={item.rating}
												isHalf={false}
												/>
												</Col>
												</Row>
												<p className="amount product-price text-right mt-2 mb-0"><b>
														{item.isReturn 
															? <a aria-hidden="true" title="Return Item" className="ret-item" href="#" onClick={handleReturn}>Return</a>: <a aria-hidden="true" title="Return Item" className="ret-item" href="#" onClick={handleNotReturn}>Return</a>}</b></p>
												
											</Col>
										</Col>
            						</Row>
                                </Card.Body>
                                <Card.Footer className="border-0 bg-white">
								<div className='text-right'>
								
								{(item.custOrdNo!=null)?(<button className="primary-button min-same-width mr-2 mb-2" onClick={()=>openAckPage(item.orderId)}>Open Order Acknowledgement</button>):"Order Acknowledgement Not Generated"}
								
								<button className="primary-button min-same-width mb-2" onClick={item.isInvoiced?()=>openInvoice(item.orderId):()=>invoice_error()}>Open Invoice</button>
                                
								</div>
								</Card.Footer>
                            </Card>

                        
                               
            
                )
            }) : null;
         
    }

    return (
        <React.Fragment>
        <Card.Body className=" pr-0 pl-0-mob pr-0-mob border-none-mob pb-0-mob">
		
		<div className="col mb-3"><i>For the terms and conditions please click here </i><Info title="Return and Cancellation Policy" content={info_content} /></div>
		
		<br/>
		{(props.orderhistory.data && props.orderhistory.data.hasMoreData)?((props.orderhistory.data.hasMoreData)?(
				<div className="scroll_order">
				<span>
				<i className="fa fa-arrow-circle-left fa-3x" onClick={(from!=1)?(()=>reduce()):(()=>console.log(from))}/>
				</span>
				&nbsp;
				&nbsp;
				&nbsp;
				<span>
				<i className="fa fa-arrow-circle-right fa-3x" onClick={(props.orderhistory.data && props.orderhistory.data.hasMoreData)?(()=>increase()):(()=>console.log(from))}/>
			</span>
			</div>
			):null):null}
        <div className="order_accordion">
                
				<Accordion className="address_accordion" defaultActiveKey="0" >
                        {renderTable()}
						
				</Accordion>
           
        </div>
		<br/>
		{(props.orderhistory.data && props.orderhistory.data.hasMoreData)?((props.orderhistory.data.hasMoreData)?(
				<div className="scroll_order">
				<span>
				<i className="fa fa-arrow-circle-left fa-3x" onClick={(from!=1)?(()=>reduce()):(()=>console.log(from))}/>
				</span>
				&nbsp;
				&nbsp;
				&nbsp;
				<span>
				<i className="fa fa-arrow-circle-right fa-3x" onClick={(props.orderhistory.data && props.orderhistory.data.hasMoreData)?(()=>increase()):(()=>console.log(from))}/>
			</span>
			</div>
			):null):null}
    </Card.Body>
		
	{cancelModal?
	<MsgModal 
	show={cancelModal} 
	closeAction={()=>setCancelModal(false)} 
	title={"Cancel Item"}
	component=
		<CancelReason cancelReason={cancelReason} cancelExplanation={cancelExplanation} cancelItem={()=>postCancelItem()} setCancelReason={(e)=>setCancelReason(e)} setCancelExplanation={(e)=>setCancelExplanation(e)} />
	
	/>:''}
        </React.Fragment>
    );
}
 
const mapStateToProps = state => ({
   companyid:state.HomeData.companyid,
   loginData: state.AuthData.loginData,
   orderhistory: state.UserData.orderhistory,
   phone: state.HomeData.phoneno,
   saveratingdata:state.ProductsData.saveratingdata,
   termsandcondition: state.HomeData.termsncondnord,
   companyDetailsCurrency: state.HomeData.companyDetailsCurrency,
   cancelOrderData: state.UserData.cancelOrderData,
   cancelReasonData: state.UserData.cancelReasonData
})

export default connect(mapStateToProps,{getOrderHistory,setMsgModal,saveRating,cancelOrderItem,getCancelReason})(AllOrders);
