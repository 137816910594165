import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.css';

import { Row, Col, Container, Navbar, Nav,Carousel } from 'react-bootstrap';

const Banner = props => {


    return (
        <React.Fragment>
		 <div className="mains-wrapper">
                <div className="home-pageslider">
                    <Carousel>
					{(props.imageData)?props.imageData.map((item)=>{
						return(
							<Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={item.imagePath}
                                alt="First slide"
                            />

                        </Carousel.Item>
						)
					}):''
					}
                    </Carousel>
                </div>
			</div>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    
})

export default connect(mapStateToProps, { })(Banner);
