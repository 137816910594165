import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import {Button} from 'react-bootstrap';
import { Global } from '../utils/Env';
import {invoice} from '../actions/AuthAction';


const InvoiceGenerator = props => {

 const navigate = useNavigate();
 const params = useParams();
 const [invoice, setInvoice] = useState(null);
 var CryptoJS = require("crypto-js");
 

 
 useEffect(()=>{
	 var ciphertext=decodeURIComponent(params.slug);
	 var bytes  = CryptoJS.AES.decrypt(ciphertext, 'test123');
	 var originalText = bytes.toString(CryptoJS.enc.Utf8);
	 if(props.companyid!='' && props.companyid !=null)
	 props.invoice({"companyId":props.companyid,"orderId":ciphertext});
 },[params.slug,props.companyid]);
 
 Global.callback.invoicegenerated_onComplete = () => {
		setInvoice(props.invoicedata.data.html);
	}
	
const generatePDF=()=>{
	window.print();
}

  return (
    <React.Fragment>
	<div className="no-printme">
	<br/>
	<Button onClick={()=>generatePDF()} variant="primary">Print</Button>
     </div>
      <div className="printme" dangerouslySetInnerHTML={{ __html: invoice }}/>
    </React.Fragment>
  );

}

const mapStateToProps = state => ({
  companyid: state.HomeData.companyid,
  invoicedata: state.AuthData.invoicedata
})

export default connect(mapStateToProps, { invoice })(InvoiceGenerator);