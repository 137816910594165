import React, {useEffect,useState } from 'react';
import { connect } from 'react-redux';
import { Modal,Row, Col, Container, Card, Table,Tabs,Tab,} from 'react-bootstrap';
import { getItemsByVariant} from '../actions/ProductsAction';
import { Global } from '../utils/Env';
import PropTypes from 'prop-types';
import PhoneInput,{ getCountryCallingCode  } from 'react-phone-number-input'
import {setUpdatedPhoneNumber,setLoginModal,set_phoneNumber, set_password,set_phone,saveCustomerPhone} from '../actions/AuthAction';
/**
 * Creates a new OrderedItemTable.
 * @class
 */
const PhoneNumberAdd = props => {
    const [countryCodeData, setCountryCode] = useState(props.countryCode==null || props.countryCode=='' || props.countryCode==undefined?'IN':props.countryCode.toUpperCase());
    const [codeWithPhonenumber, setPhone] = useState((props.phoneCode=='' || props.phoneCode==undefined || props.phoneCode==null)?'+91'.concat(props.phone):props.phoneCode+props.phone);
	const [state, setState] = useState("");
    const [show, setShow] = useState(props.showValue);
    const [phonenumber, setPhonenumber] = useState(props.phone);
  const [phoneCodeValue, setPhoneCode] = useState(props.phoneCode==null || props.phoneCode=='' || props.phoneCode==undefined?'+91':props.phoneCode);
  const checkValidity = () => {
    if (phonenumber !=undefined && phonenumber.length >=8) {
      return false
    }
    return true
  }
  const handleClose = () => {
    setShow(false);
}

PhoneNumberAdd.propTypes = {
    show: PropTypes.bool.isRequired,
    closeAction: PropTypes.func
};

PhoneNumberAdd.defaultProps = {
    show: false,
    closeAction: () => {},
}
  const SavePhoneNo = () => {
    props.saveCustomerPhone({ "CompanyId": props.companyid, "CustomerId": parseInt(props.loginData.data.customerId),"Phone": phonenumber, "PhoneCode": phoneCodeValue,"PhoneCountryCode": countryCodeData});
    props.setUpdatedPhoneNumber(phonenumber)
    props.closeAction();
  }
  const setPhoneDeep=(e)=>{
    setPhone(e);
    var onlyPhoneCode='';
    if(countryCodeData !='' && countryCodeData !==undefined)
    {
      onlyPhoneCode ="+" + getCountryCallingCode(countryCodeData);  
      setPhoneCode(onlyPhoneCode);
      if(e)
      {
      const onlyPhoneNumber = e.replace(onlyPhoneCode, '')
      setPhonenumber(onlyPhoneNumber);
      }
      else
      setPhonenumber('');
    }

  }
    const handlePhonenumberChange = (e) => {
        e.preventDefault();
        if (e.target.value.length >= 8) {
            setPhonenumber(e.target.value)
        }
        var onlyPhoneCode='';
       
        if(countryCodeData !='' && countryCodeData !==undefined)
        {
        onlyPhoneCode ="+" + getCountryCallingCode(countryCodeData);  
        setPhoneCode(onlyPhoneCode);
        const onlyPhoneNumber = codeWithPhonenumber.replace(onlyPhoneCode, '')
        setPhonenumber(onlyPhoneNumber);
        }
      
    }

    useEffect(()=>{
      setCountryCode(props.countryCode==null || props.countryCode=='' || props.countryCode==undefined?'IN':props.countryCode.toUpperCase());
      setPhoneCode(props.phoneCode==null || props.phoneCode=='' || props.phoneCode==undefined?'+91':props.phoneCode);

  },[props.phoneCode,props.countryCode])

    let primaryColor=props.petTemplateDetails && props.petTemplateDetails.primaryColor?props.petTemplateDetails.primaryColor:'';
    let secondaryColor=props.petTemplateDetails && props.petTemplateDetails.secondaryColor?props.petTemplateDetails.secondaryColor:'';
    let tertiaryColor=props.petTemplateDetails && props.petTemplateDetails.tertiaryColor?props.petTemplateDetails.tertiaryColor:'';

    const renderPhoneField = () => {
        return (<React.Fragment>
                    <Row>
                        <Col>
                        <p className="w-100 fs-13-f-n">Enter Your Phone Number</p>
                        <PhoneInput 
                                                countrySelectProps={{ unicodeFlags: false }}
                                                countryCallingCodeEditable={false}
                                                international
                                                defaultCountry={props.countryCode?props.countryCode:'IN'}
                                                value={codeWithPhonenumber}
                                                name="phonenumber"
                                                onChange={setPhoneDeep} onCountryChange={setCountryCode} onBlurCapture={handlePhonenumberChange}/>
                        </Col>
                    </Row>
                    <Row>
                    <Col className="text-right" style={{margin: "5px 0"}}>
                    <button style={{backgroundColor:primaryColor}}  className="ph-addbutton primary-button-nocolor" disabled={checkValidity()}  onClick={SavePhoneNo}>
                    Add
                  </button>
                    </Col>
                    </Row>
                </React.Fragment>)
      }
    return (
        <React.Fragment>
			  <div className='ph-val'>
              <Modal show={props.showValue} backdrop="static" onHide={props.closeAction}  centered>
                <Modal.Header closeButton className="bg-dark pt-1 pb-1 text-white cl-btn-white">
                    <h5>Phone Number</h5>
                </Modal.Header>
                <Modal.Body>
                  {renderPhoneField()}
                </Modal.Body>
            </Modal>

                </div>
    
        </React.Fragment>
    );
}
const mapStateToProps = (state) => ({
    mobileState: state.CategoryData.isMobileMenu,
    cartList: state.CartData.cartList,
    loginData: state.AuthData.loginData,
    authenticated: state.AuthData.authenticated,
    loginhead:state.AuthData.loginhead,
    companyid:state.HomeData.companyid,
    cartItems: state.CartData.cartList,
    checkoutDetails: state.CheckoutData.checkoutDetails,
    totalAmount: state.CartData.totalAmount,
    cartData: state,
    countryCode:state.HomeData.servicePhoneCountryCode,
    phoneCode:state.HomeData.servicePhoneCode,
    updatedUserPhoneNumber:state.AuthData.updatedUserPhoneNumber,
    updatedUserPhoneNo:state.AuthData.updatedUserPhoneNo,
    petTemplateDetails: state.petProductData.userP_AllProductInfo.data,
  });
// export default PhoneNumberAdd;
export default connect(mapStateToProps, { set_phone,setUpdatedPhoneNumber,setLoginModal,saveCustomerPhone })(PhoneNumberAdd);

