import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import { setLoginModal, set_login_head } from '../actions/AuthAction';

const WishListnull = props => {
    const navigate = useNavigate();
    const onMoreShopping = () => navigate("/");
    return (
        <React.Fragment>
            <div className="alert-wishlist text-center pt-5 pb-5">
                <i className="fa fa-heart-o fa-lg"></i>
                <div className="wishtlist_header">Wish list is currently empty..</div>
            </div>
            <div >
                <Container className={(props.mobileState) ? "p-0-mob p-0-tab checkout_mobileback" : "p-0-mob p-0-tab"}>
                    <div className="">
                        <button class="primary-button" onClick={onMoreShopping}><i class="fa fa-arrow-left" ></i>&nbsp;&nbsp;Back to Shopping</button>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
}
export default WishListnull;
