import React, { useEffect } from 'react';
import { connect } from 'react-redux';


import CartItem from './CartPreviewItem';
import EmptyCart from "../EmptyCart";
import { Container } from 'react-bootstrap';
import { to2Decimal, formatINR, formatSymbolFromCurrency } from "../../utils/CommonUtil";
import { selectCheckOutJSON, checkOutOrder, clearOrder } from '../../actions/CheckoutAction';
import { previewCart } from '../../actions/CartAction';
import { Global } from '../../utils/Env';
import { useNavigate } from 'react-router-dom';
import {setLoginModal} from '../../actions/AuthAction';


const PreviewItemDetailsList = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
    props.previewCart(false);
    Global.callback.checkOutOrder_onComplete = () => {
      navigate("/checkout", { state: { process: true } });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [props.previewCart, navigate]);
  let cartItems = props.cartItems.map((product, index) => {
    return <CartItem key={`${product.name}${index}`} product={product} />
  });

  let hasItems = () => {
    return (props.cartItems && (props.cartItems.length > 0)) ? true : false
  }
  const checkoutHandler = () => {
    onHide();
	if(props.authenticated)
    props.checkOutOrder(props.checkoutData);
	else
	props.setLoginModal(true);
  }

  const onHide = () => {
    props.previewCart(false)
  }

  return (<Container className="p-0-mob">
    <div className="itemlist">
		<div className='row'>
			<div className='col-md-12'>
			<h3 class="page-lab-head">Shopping Cart</h3>
			</div>
		</div>
      <div className='row'>
        <div className='col-md-8'>
        <div className="itemlistinner">
        {hasItems() ? cartItems : <EmptyCart />}
        <div className='Subtotal-mainpannel Subtotal text-right'>
        Subtotal ({props.cartItems.length} Items): <span className="font-bold">{formatSymbolFromCurrency((!(props.companyDetailsCurrency.currency && props.companyDetailsCurrency.currency.currencyCode) ? 'INR' : props.companyDetailsCurrency.currency.currencyCode), to2Decimal(props.totalAmount))}</span>
            </div>
      </div>
	  {/* <p class="del-text"> <i class="fa fa-info-circle" aria-hidden="true"></i>Delivery Time Inside Dhaka - 2-3 days &amp; Outside Dhaka - 3-5 days</p> */}
        </div>
        <div className='col-md-4'>
			<div className='sub-total-right-parent'>
          <div className='item-subtotal-right'>
        {hasItems() ? <div> 
          <div className='Subtotal'>
                Subtotal ({props.cartItems.length} Items): <span className="float-right font-bold">{formatSymbolFromCurrency((!(props.companyDetailsCurrency.currency && props.companyDetailsCurrency.currency.currencyCode) ? 'INR' : props.companyDetailsCurrency.currency.currencyCode), to2Decimal(props.totalAmount))}</span>
            </div>
            
        <div className="w-100 mt-0">
          <button
            type="button"
            onClick={checkoutHandler}
            disabled={!hasItems() || props.cartItems.filter((item)=>item.stockQty!=null && item.stockQty<=0).length>0}
            className={hasItems() ? "btn btn-secondary bgpinbutton position-relative min-same-widthxs w-100 text-center primary-button-continue" : "btn btn-secondary bgpinbutton position-relative min-same-widthxs w-100 checkout-disable text-center primary-button-continue"}>
            Continue
          </button>
        </div></div> : <div></div>}
		
        </div>
		
		<p className='promocode-text'>Promo code can be applied on payment page</p>
		
		</div>
		{/*<p class="del-text"> <i class="fa fa-info-circle" aria-hidden="true"></i>Delivery Time Inside Dhaka - 2-3 days &amp; Outside Dhaka - 3-5 days</p>*/}
        </div>
      </div>
     <div className='row'>
     {/*<div className="product-details-maindiv raw-bites row mt-4">
     <div class="col faq-div pt-4 pb-4 border-top"><h2 class="faq-header-label">Products related to this item</h2></div>
									<div className="container">
										<div className="position-relative row">
											<label className="comon-arrow-style lft-pos">❮</label>
											<div className="col-md-3">
												<div className="prod">
													<div>
														<img className="prod-img" src="https://library.wizardcomm.in/company/petindiab2c/images/Item/Original/CHICKEN &amp;VEG GRAVY.jpg" />
													</div>
													<div className="col">
														<div className="product_name text-center"> IndiPet Organics Raw Bites Premium Dog Treats- Rabbit Jerky</div>
													</div>
												</div>
												<div className="row">
													<div className="pl-10 col"><div className="vendor text-center mt-2 mb-2">INDIPET</div></div>
												</div>
												<div className="row">
													<div className="pl-10 col">
														<div className="Price-renge text-center mb-2">
															<div className="price-new mr-1">₹ 690.00</div>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="pl-10 mb-5 col">
														<div className="addtocart-div text-center"><button className="primary-button min-same-width">Add To Cart</button></div>
													</div>
												</div>
											</div>
											<div className="col-md-3">
												<div className="prod">
													<div>
														<img className="prod-img" src="https://library.wizardcomm.in/company/petindiab2c/images/Item/Original/CHICKEN &amp;VEG GRAVY.jpg" />
													</div>
													<div className="col">
														<div className="product_name text-center"> IndiPet Organics Raw Bites Premium Dog Treats- Rabbit Jerky</div>
													</div>
												</div>
												<div className="row">
													<div className="pl-10 col">
														<div className="Price-renge text-center mb-2">
															<div className="price-new mr-1">₹ 690.00</div>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="pl-10 mb-5 col">
														<div className="addtocart-div text-center"><button className="primary-button min-same-width">Add To Cart</button></div>
													</div>
												</div>
											</div>
											<div className="col-md-3">
												<div className="prod">
													<div>
														<img className="prod-img" src="https://library.wizardcomm.in/company/petindiab2c/images/Item/Original/CHICKEN &amp;VEG GRAVY.jpg" />
													</div>
													<div className="col">
														<div className="product_name text-center"> IndiPet Organics Raw Bites Premium Dog Treats- Rabbit Jerky</div>
													</div>
												</div>
												<div className="row">
													<div className="pl-10 col">
														<div className="Price-renge text-center mb-2">
															<div className="price-new mr-1">₹ 690.00</div>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="pl-10 mb-5 col">
														<div className="addtocart-div text-center"><button className="primary-button min-same-width">Add To Cart</button></div>
													</div>
												</div>
											</div>
											<div className="col-md-3">
												<div className="prod">
													<div>
														<img className="prod-img" src="https://library.wizardcomm.in/company/petindiab2c/images/Item/Original/CHICKEN &amp;VEG GRAVY.jpg" />
													</div>
													<div className="col">
														<div className="product_name text-center"> IndiPet Organics Raw Bites Premium Dog Treats- Rabbit Jerky</div>
													</div>
												</div>
												<div className="row">
													<div className="pl-10 col"><div className="vendor text-center mt-2 mb-2">INDIPET</div></div>
												</div>
												<div className="row">
													<div className="pl-10 col">
														<div className="Price-renge text-center mb-2">
															<div className="price-new mr-1">₹ 690.00</div>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="pl-10 mb-5 col">
														<div className="addtocart-div text-center"><button className="primary-button min-same-width">Add To Cart</button></div>
													</div>
												</div>
											</div>
											<label className="comon-arrow-style rig-pos">❯</label>
										</div>
									</div>
		</div>*/}
     </div>
     

    </div>

    

  </Container>)
}

const mapStateToProps = state => ({
  cartItems: state.CartData.cartList,
  totalAmount: state.CartData.totalAmount,
  companyDetailsCurrency: state.HomeData.companyDetailsCurrency,
  checkoutData: selectCheckOutJSON(state),
  authenticated: state.AuthData.authenticated,
});

export default connect(mapStateToProps, { previewCart,setLoginModal, checkOutOrder })(PreviewItemDetailsList);