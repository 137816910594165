import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import {Button} from 'react-bootstrap';
import { Global } from '../utils/Env';
import {order} from '../actions/AuthAction';


const OrderGenerator = props => {

 const navigate = useNavigate();
 const params = useParams();
 const [order, setOrder] = useState(null);
 var CryptoJS = require("crypto-js");
 
 


 
 useEffect(()=>{
	 var ciphertext=decodeURIComponent(params.slug);
	 var bytes  = CryptoJS.AES.decrypt(ciphertext, 'test123');
	 var originalText = bytes.toString(CryptoJS.enc.Utf8);
	 //console.log(ciphertext,"deep_cipher");
	 //console.log(encodeURIComponent('U2FsdGVkX1/wgaOi0qiFAzU9UZ+SZDAYtS/4bLOYL/M='));
	 if(props.companyid!='' && props.companyid!=null)
	 props.order({"companyId":props.companyid,"orderId":ciphertext});
 },[params.slug,props.companyid]);
 
 Global.callback.ordergenerated_onComplete = () => {
		setOrder(props.order_generated.data.html);
	}
	
const generatePDF=()=>{
	window.print();
}

  return (
    <React.Fragment>
	<div className="no-printme">
	<br/>
	<Button onClick={()=>generatePDF()} className="primary-button min-same-width">Print</Button>
     </div>
      <div className="printme" dangerouslySetInnerHTML={{ __html: order }}/>
    </React.Fragment>
  );

}

const mapStateToProps = state => ({
  companyid: state.HomeData.companyid,
  order_generated: state.AuthData.order_generated
})

export default connect(mapStateToProps, { order })(OrderGenerator);