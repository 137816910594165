import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import Product from '../components/Product';
import { Container, Row , Col} from 'react-bootstrap';

import { getSectionItems } from '../actions/ProductsAction';

const SectionPanel = props => {
	
	const params = useParams();
	const sectionId=params.slug;
    
    useEffect(() => {
        props.getSectionItems({"CompanyId":props.companyid,"SectionId":parseInt(sectionId)});
		
    }, []);
    
    

    const featuredItems=[];
	props.productsList.map((item)=>{
		if(props.sectionItems.filter((secItem)=>{return secItem.id==item.id}).length>0)
			featuredItems.push(item)
	})
	

    const productsData = featuredItems.map((product)=>{
        return <Product key={product.id} product={product} />
    });

    return (
        <div className="products-wrapper">
            
                {/* <Row>
                    <Col>
                        <h4 className="pt-3 pb-3 mb-3 bord-bot-theme"><b>Our Best Sellers</b></h4>
                    </Col>
                </Row> */}
                <div className="mar-0-auto text-center">
                    {productsData}
                </div>
           
        </div>
    );
}

const mapStateToProps = state => ({
    companyid:state.HomeData.companyid,
    productsList: state.ProductsData.productsList,
    sectionItems: state.ProductsData.sectionItems
})

export default connect(mapStateToProps, {getSectionItems})(SectionPanel);
