import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import HorizontalSlider from 'react-horizontal-slider'
import 'bootstrap/dist/css/bootstrap.css';
import 'react-horizontal-slider/build/main.css';
import 'react-horizontal-slider/index.css';

import { Row, Col, Container, Navbar, Nav,Carousel } from 'react-bootstrap';

const ItemScroller = props => {
	
	let items=[];
	if(props.imageData && props.imageData.length>0)
	props.imageData.map((item)=>{
		let temp={
			"url":item.imagePath,
			"title":props.secondaryText
		}
		items.push(temp);
	});

    return (
        <React.Fragment>
			<Container className="trending-products">
		<h2 class="section-header text-dark">{props.primaryText}</h2>
		<HorizontalSlider
	    
	     data={items}
	     id={1} 
	     />
		 </Container>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    
})

export default connect(mapStateToProps, { })(ItemScroller);
