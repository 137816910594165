import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.css';

import { Row, Col, Container, Navbar, Nav } from 'react-bootstrap';

const CategoryComponent = props => {


    return (
        <React.Fragment>
		  <div className="each-section shop-by-category">
                    <Container>
                        <div className="row">
                            <div className="col-md-6">
                                <h2 className="section-header text-dark">Shop By Category</h2>
                            </div>
                            <div className="col-md-6 text-right">
                                <a href="#" className="viewall-link">Viev All <span className="double-arrow">&#187;</span></a>
                            </div>
                        </div>

                        <div className="row">
                            <Col>
                                <div>
                                    <div class="card">
                                        <div className="product-category-images">
                                            <img class="card-img-top" src="https://rukminim1.flixcart.com/image/495/594/kgqvlow0-0/t-shirt/z/v/1/xxl-boat-neck-black-try-this-original-imafwx27xap5ebsv.jpeg?q=50" alt="Card image" />
                                        </div>
                                        <div class="card-body text-center">
                                            <p class="product-category-name">Turtle</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div>
                                    <div class="card">
                                        <div className="product-category-images">
                                            <img class="card-img-top" src="https://rukminim1.flixcart.com/image/495/594/kqidx8w0/kurta/3/m/y/xl-crp-kurti-fexel-original-imag4gcdmhbug9zj.jpeg?q=50" alt="Card image" />
                                        </div>
                                        <div class="card-body text-center">
                                            <p class="product-category-name">Kurta</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div>
                                    <div class="card">
                                        <div className="product-category-images">
                                            <img class="card-img-top" src="https://rukminim1.flixcart.com/image/495/594/kgqvlow0-0/t-shirt/z/v/1/xxl-boat-neck-black-try-this-original-imafwx27xap5ebsv.jpeg?q=50" alt="Card image" />
                                        </div>
                                        <div class="card-body text-center">
                                            <p class="product-category-name">Turtle</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div>
                                    <div class="card">
                                        <div className="product-category-images">
                                            <img class="card-img-top" src="https://rukminim1.flixcart.com/image/495/594/kqidx8w0/kurta/3/m/y/xl-crp-kurti-fexel-original-imag4gcdmhbug9zj.jpeg?q=50" alt="Card image" />
                                        </div>
                                        <div class="card-body text-center">
                                            <p class="product-category-name">Kurta</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div>
                                    <div class="card">
                                        <div className="product-category-images">
                                            <img class="card-img-top" src="https://rukminim1.flixcart.com/image/495/594/kqidx8w0/kurta/3/m/y/xl-crp-kurti-fexel-original-imag4gcdmhbug9zj.jpeg?q=50" alt="Card image" />
                                        </div>
                                        <div class="card-body text-center">
                                            <p class="product-category-name">Kurta</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </div>
                    </Container>
                </div>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    
})

export default connect(mapStateToProps, { })(CategoryComponent);
