import React,{useState} from "react";
import { useNavigate,useLocation } from 'react-router-dom';
import { APIAlert } from '../utils/AlertUtil';
import styled, { keyframes } from "styled-components";
import LazyLoad from "react-lazyload";
import PhoneNumberAdd from "./PhoneNumberAdd";
import { connect } from "react-redux";
import {setLoginModal} from '../actions/AuthAction';
/**
 * Creates a new Loading.
 * @class
 */ 

 const ImageWrapper = styled.div`
 position: relative;
 width: 100%;
 height: 20vw;
`;

const loadingAnimation = keyframes`
 0% {
   background-color: #fff;
 }
 50% {
   background-color: #ccc;
 }
 100% {
   background-color: #fff;
 }
`;

const Placeholder = styled.div`
 position: absolute;
 left: 0;
 top: 0;
 right: 0;
 bottom: 0;
 animation: ${loadingAnimation} 1s infinite;
`;

const StyledImage = styled.img`
 position: absolute;
 left: 0;
 width: 100%;
 height: 100%;
 object-fit: cover;
`;
	
const P_Loading = (props) => {
  const location = useLocation();
  const productId = (typeof props.productId=="undefined")?0:props.productId;
  const variantId = (typeof props.variantId=="undefined")?0:props.variantId;
  const sectionFor =(typeof props.sectionFor=="undefined")?0:props.sectionFor;
  const sectionForByLocation =2;
  
  const [state, setState] = useState("");
  const navigate = useNavigate();
  const handleImgClick = () => {
    if(!props.authenticated)
      {
        // props.setLoginModal(true);
        navigate(`/categorySection/${sectionFor}/${productId}/${variantId}`);
      }
  }
  const closeAction = () => {
    setState("");
}
  const refPlaceholder = React.useRef();
  const removePlaceholder = () => {
    refPlaceholder.current.remove();
  };
  return (
    // <React.Fragment>   
    //   <div>
    //   <img className="prod-img" src={props.src}  onClick={handleImgClick}  />
	  // </div>
    // </React.Fragment>
    <React.Fragment>
    <ImageWrapper>
      <Placeholder ref={refPlaceholder} />
      <LazyLoad>
        <StyledImage className="prod-img"
          onLoad={removePlaceholder}
          onError={removePlaceholder}
          src={props.src}
          onClick={handleImgClick}
          alt={props.alt}
        />
      </LazyLoad>
    </ImageWrapper>
   
      <div>
    {(state == "PhoneReInsert") ? (
      <PhoneNumberAdd showValue={true} closeAction={closeAction}/>
         ) : null}</div>
 </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  mobileState: state.CategoryData.isMobileMenu,
  cartList: state.CartData.cartList,
  loginData: state.AuthData.loginData,
  authenticated: state.AuthData.authenticated,
  loginhead:state.AuthData.loginhead,

  cartItems: state.CartData.cartList,
  checkoutDetails: state.CheckoutData.checkoutDetails,
  totalAmount: state.CartData.totalAmount,
  updatedUserPhoneNumber:state.AuthData.updatedUserPhoneNumber
});

export default connect(mapStateToProps,{setLoginModal})(P_Loading);