import React, { useState } from "react";
import { connect } from 'react-redux';
import Counter from "./Counter";
import ImageUtil from '../utils/ImageUtil';
import { to2Decimal, formatINR,formatSymbolFromCurrency } from '../utils/CommonUtil';
import { selectProduct, getItemsByVariant, setVariantModal} from '../actions/ProductsAction';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Container, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { APIAlert } from '../utils/AlertUtil';
import { isProd } from '../utils/Env';
import MsgModal from '../container/MsgModal';
import { Global } from '../utils/Env';
import {setLoginModal,set_login_head} from '../actions/AuthAction';
import WishlistItems from './WishlistItems';
//import GenericModal from "./Generic Modal";
import getSymbolFromCurrency from 'currency-symbol-map'

import { Card, ListGroup } from 'react-bootstrap';
import ProductLoading from './ProductLoading';

const Product = (props) => {
  const [loading, setLoading] = useState(false);
    const [show,setShow]=useState(false);
  const [content,setContent]=useState(null);
  const [head,setHead]=useState("Wish List");
  let { product } = props;
  const navigate = useNavigate();
  const cart = props.cartList;
  let variant = product.variant;
  let index = cart.findIndex(x => x.id === product.id);
  const sizeHandler = () => {
    props.getItemsByVariant({ companyid: props.companyid, variant: product.variant });
    props.setVariantModal(true);
  }
  const handleImgClick = () => {
    navigate(`/products/${product.id}`)
  }
  
  const closeAction=()=>{
		setShow(false);
	}

  const Overlay_name = (param) => {
    return (
      <OverlayTrigger placement='bottom' overlay={<Tooltip className="tooltip"> {param}</Tooltip>}>
        <h4 className="product-name product-name-home elips-twoline mb-2">{param.substring(0, 34).concat("...")}</h4>
      </OverlayTrigger>
    );
  }
  const handleImageLoaded = () => {
    setLoading(true);
  }

  /* const imageHandler = () => {
    console.log(product.images[0].name);
   let img = ((product.images[0].name === null) || (product.images[0].name === 'undefined')) ? ImageUtil.getErrorImage() : ImageUtil.getImage(product.images[0].name);
   let errMsg = 'Missing->'+product.images[0].name;
     //APIAlert().error(errMsg);
     return img
   } */

  if (!isProd() && !product.images.length) {
    let errMsg = 'IMG name in db null for->' + product.name;
    APIAlert(errMsg).error();
  }
  
	
const removewishlist=()=>{
		setHead("Wish Listed Items");
		setShow(true);
		setContent(<WishlistItems proid={product.id} />);
	}
  
  const wishlist = ()=>{
	
	if(!props.authenticated)
	{
		props.setLoginModal(true);
		props.set_login_head("Login");
	}
	else
		handleImgClick();
	
}
  return (

    <div className="prod-divn pr-3 pl-3">

      <div className={loading ? "product-box show" : "product-box hide"}>
        <Row>
          <Col className="p-0">
            <div className="position-relative">
              <span className="badge badge-pill badge-info position-absolute z-index1 percent-offer rounded-0 font-weight-normal">{product.promo_percentage ? `${product.promo_percentage} Off` : ""}</span>
              <span className="badge badge-info float-right position-absolute z-index1 mt-1 label-offer">{product.promo_name || ""}</span>
			  </div>
          </Col>
        </Row>

        <Row>
          <Col className="p-0">
            {product.brandicon ?
              <div className="brand-iconmaindiv">
                <img className="brand-img" alt={product.brandicon} src={product.brandicon} />
              </div>
              : null}

            <img className="product-img"
              src={(product.images[0]) ? ImageUtil.getImage(product.images[0].name.substring(0,product.images[0].name.lastIndexOf(".")).concat("_Thumbnail").concat(product.images[0].name.substring(product.images[0].name.lastIndexOf("."),product.images[0].name.length))) : ImageUtil.getErrorImage()}
              alt={product.name}
              onClick={handleImgClick}
              onError={e => e.target.src = ImageUtil.getErrorImage()}
              onLoad={handleImageLoaded}
            />
          </Col>
        </Row>

        <Row>
          <Col className="p-0 pl-2 pr-2">
		  { (props.wishlist.filter((item)=>{return item.itemId==product.id}).length!=0)?
			<span className="badge float-right position-absolute z-index1 mt-1 label-wish"><i className="fa fa-heart fa-2x wishlisted" onClick={()=>removewishlist()} /></span>:null}
			{( props.wishlist.filter((item)=>{return item.itemId==product.id}).length==0)?
			<span className="badge float-right position-absolute z-index1 mt-1 label-wish"><i className="fa fa-heart fa-2x not-wishlisted" onClick={()=>wishlist()}/></span>:null}
			<h4 className="product-name ">{product.item_code}</h4>
            {(product.name.length > 37) 
                ? Overlay_name(product.name) 
                : (<h4 className="product-name product-name-home elips-twoline mb-2">{product.name}</h4>)}
            {/* Commented as description not required: Ashim=> <p className="product-des elips-threeline mb-2"></p> */}
          </Col>
        </Row>

        {/* <Row>
          <Col className="p-0">
            <button className="btn btn-outline-success theme-outline-button mob-font-11px btn-sm" onClick={sizeHandler}>
              {product.size}&nbsp;&nbsp; {product.variant.length > 1 ? <i className="fa fa-chevron-down font-12" aria-hidden="true"></i> : ""}
            </button>
          </Col>
        </Row> */}

        <Row className="w-100 l-0 b-0 pos-rel-mobile text-center">
          <Col className="p-0 pl-2 pr-2 rate-sizecontrol" md={12} xs={12}>
            <p className="product-price text-left mb-1">
			{!product.issoldout?<><span className="text-danger">
                <s>{product.promo_price ? formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode),to2Decimal(product.price_regular)) : ""}</s>
              
			  </span><span>{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode),to2Decimal(product.promo_price ? product.promo_price : product.price_regular))}</span>
			  
			{(product.savePricePctg && product.savePricePctg>0)?<><span style={{opacity:"45%",fontSize:"20px",color:"#040404"}}>&nbsp;&nbsp;<del>{product.mrp}</del></span><span className="percentage_off"> ({product.savePricePctg}% OFF)</span></>:null}</>:null}
				{product.issoldout?<span className="stock_off"><b>SOLD OUT</b></span>:null}
              
			  </p>
               {/* <p className="product-price text-left">
              <span className="text-danger">
                <s>10</s>
              </span>&nbsp;&nbsp;<span>100</span> </p> */}
          </Col>
          {/* <Col className="pl-3 pr-3 tab-width-cont" md={6} xs={12}>
            <div className="product-action pos-rel-mobile pos-sta-tab">
              {<Counter product={product} />}
            </div>
          </Col> */}
        </Row>

      </div>
      
      <Card className={loading ? "product-box hide" : "product-box show"} >
        <ProductLoading />
      </Card>
     <MsgModal show={show} title={head} message={content} closeAction={closeAction} />
    </div>
  );
}

const mapStateToProps = state => ({
  productsList: state.ProductsData.productsList,
  cartList: state.CartData.cartList,
  companyid: state.HomeData.companyid,
  companyDetailsCurrency: state.HomeData.companyDetailsCurrency,
  wishlist: state.ProductsData.wishlist,
  loginData: state.AuthData.loginData,
  authenticated: state.AuthData.authenticated
})


export default connect(mapStateToProps, { selectProduct, getItemsByVariant, setVariantModal,setLoginModal,set_login_head })(Product);
