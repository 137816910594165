import React, {  } from 'react';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';


const PrivacyPolicy = props => {
	
	

    return (
	<React.Fragment>
        <Container className='mt-5 mb-5'>
	<h1 className="aboutus-head"><b>Privacy Policy</b></h1>
	<div className="entry clr aboutus-body" itemProp="text">
    {props.footerData && props.footerData.privacyPolicyDtl && props.footerData.privacyPolicyDtl.showPrivacyPolicy?props.footerData.privacyPolicyDtl.isHtml?
    <div className="product_details_cont" dangerouslySetInnerHTML={{ __html: props.footerData.privacyPolicyDtl.privacyPolicy }} />:'':''}
      </div>
      </Container>
		</React.Fragment>
    );
}

const mapStateToProps = state => ({
	footerData:state.HomeData.footer
})

export default connect(mapStateToProps, {})(PrivacyPolicy);
