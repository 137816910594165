import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Row, Col, Container, Navbar, Nav,Carousel } from 'react-bootstrap';

const BestDeals = props => {


    return (
        <React.Fragment>
			<div className="each-section deals-ofthe-day">
                <Container>
                    <Row>
                        <Col>
                        <h2 class="section-header text-dark">{props.primaryText}</h2>
                        </Col>
                        <Col className="text-right">
                        <a href="#" class="viewall-link">Viev All <span class="double-arrow">»</span></a>
                        </Col>
                    </Row>
                    
                    <Row>
					{props.imageData?props.imageData.map((item)=>{
						return(
                        <div className="col-md-3">
                            <div>
                                <img src={item.imagePath} className="w-100" />
                            </div>
                        </div>
						)
					}):''
					}
                        
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    
})

export default connect(mapStateToProps, { })(BestDeals);
