import React, { useEffect, useState, lazy, Suspense, useRef,useLayoutEffect } from "react";
import { connect } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import ImageUtil from "../utils/ImageUtil";
import { to2Decimal, formatINR, formatSymbolFromCurrency } from "../utils/CommonUtil";
import Counter from "./Counter";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Carousel } from "react-responsive-carousel";
import { Container, Row, Col, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import Footer from '../components/Footer';
import {
  getItemsByVariant,
  selectProduct,
  saveWishList,
  getWishList,
  removeWishList,
  getRating,
} from "../actions/ProductsAction";
import { Global } from "../utils/Env";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import { P_ProductInfo, P_ProductInfoForLazyLoad } from '../actions/P_ProductsAction';
import InnerImageZoom from "react-inner-image-zoom";
import Info from "./Info";
import { previewCart } from "../actions/CartAction";
import MsgModal from "../container/MsgModal";
import SizeChart from "./SizeChart";
import { setLoginModal, set_login_head } from "../actions/AuthAction";
import ProductLoading from "./ProductLoading";
import { Card, ListGroup } from "react-bootstrap";
import Header from "../components/header/Header";
import P_ProductLoading from "./P_ProductLoading";
import WishlistItems from './WishlistItems';
import LazyLoad from 'react-lazyload';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "transparent" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "transparent" }}
      onClick={onClick}
    />
  );
}


const settings = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }
  ]
 
};


const settingsfeatures = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }
  ]
 
};

// const settings = {
//   dots: false,
//   arrows: true,
//   infinite: true,
//   speed: 500,
//   slidesToShow: 4,
//   slidesToScroll: 1,
//   nextArrow: <SampleNextArrow />,
//   prevArrow: <SamplePrevArrow />
// };
const settings2 = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
 
};
// const P_ProductLoading=lazy(()=>import("./P_ProductLoading"));
const P_AllProduct = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const [selectedProduct, setSelectedProduct] = useState({});
  const [selectedProductForLazyLoad, setselectedProductForLazyLoad] = useState({});
  const imageRef = useRef(null);
  const [show, setShow] = useState(false);
  const [content, setContent] = useState(<SizeChart />);
  const [head, setHead] = useState("Size Chart");
  const [final_color, setColor] = useState(null);
  const [final_size, setSize] = useState(null);
  const [colorList, setColorList] = useState([]);
  const [sizeList, setSizeList] = useState([]);
  const [rate, setRate] = useState(0);
  let wishlisting = false;
  let imageList;
  let imageFinalList;
  let imageLastList;
  let sectionFor;
  // useEffect(() => {
  //   if(props.companyid){
  //   console.log("inside AllProduct")
  //   props.P_ProductInfo({ "Type": "1", "companyid": props.companyid });
  //   }
  // }, [selectedProduct,props.companyid]);

  const showDefaultSection = (typeof props.showDefaultSection == "undefined") ? false : props.showDefaultSection;
  const [productId, setproductId] = useState(0);


  const [scrollPosition, setPosition] = useState(0);
  const [bodyDynamicClass, setbodyDynamicClass] = useState('text-center');
  
  const lazyLoad = () => {
    props.P_ProductInfoForLazyLoad({ "CompanyId": props.companyid, "Page": 1, "RecsPerPage": 5 });
  };

  const closeAction = () => {
    setShow(false);
  };
  const handleImageZoom = (i, e) => {
    if (wishlisting == false) {
      setHead("Image Zoom");
      setShow(true);
      setContent(
        selectedProduct.images.map((item, id) => {
          if (id == i)
            return (
              <InnerImageZoom
                key={id}
                src={ImageUtil.getImage(item.name)}
                alt={item.name}
                fullscreenOnMobile={true}
                mobileBreakpoint={450}
                height={200}
              />
            );
        })
      );
    }
    wishlisting = false;
  };
  const renderThumbs = () => {
	imageFinalList=null;
	imageLastList=null;
    imageList = props.wishlist.map((item, id) => {
      // console.log(item.singleItemReturn.images);
      imageFinalList = item.singleItemReturn.images;
      if (imageFinalList != null) {
        imageLastList = imageFinalList.map((item1, id1) => {
          return (
            <img
              key={id1}
              src={ImageUtil.getImage(item1.name)}
              alt={item1.alt}
            />
          );
        });
      }
    });
    return imageLastList;
  };

  function dynamicSectionLoad(sectionFor,itemId,variantId) {
    //console.log("calling navigate");
    navigate(`/categorySection/${sectionFor}/${itemId}/${variantId}`);
   }

  const section1 = (id) => {
	imageFinalList=null;
	imageLastList=null;
    if (typeof props.petTemplateDetails != "undefined") {
      let item=props.petTemplateDetails.responseCompanyTemplateSections[id];
        imageFinalList = item.responseSectionItemAndImage;
        sectionFor=item.sectionFor;
        if (imageFinalList != null) {
          if (imageFinalList.sectionItems != null && item.sectionType == 1 && imageFinalList.sectionItems.length>0) {
            imageLastList = imageFinalList.sectionItems.map((item1, id1) => {
              return (
                <div className="" key={id1} onClick={() => dynamicSectionLoad(item.sectionFor,item1.itemId,item1.variantId)}>
                  <img key={id1} src={item1.imagePath} alt={item1.name} />
                  {/* <a href="#" className="d-block"><img key={id1} src="https://cdn.shopify.com/s/files/1/1199/8502/files/Banner-AUTOMATED-Toys_080c461a-8776-4d62-8155-2c81e81b5612_1400x.jpg?v=1638974789" /></a> */}
                </div>
              )
            })
          }
        }
    }
    return imageLastList;
  }
  const section2 = (id) => {
	imageFinalList=null;
	imageLastList=null;
    if (typeof props.petTemplateDetails != "undefined") {
      let item=props.petTemplateDetails.responseCompanyTemplateSections[id];
        imageFinalList = item.responseSectionItemAndImage;
       
        if (imageFinalList != null) {
          if (imageFinalList.sectionItems != null && item.sectionType == 2 && imageFinalList.sectionItems.length>0) {
            return (
              imageLastList = imageFinalList.sectionItems.map((item1, id1) => {
                //console.log("deep_item",item1);
                let saleprice=item1.offer && item1.offer.offerSalePrice?item1.offer.offerSalePrice:item1.price;
                let mrp=item1.offer && item1.offer.offerSalePrice?item1.price:item1.mrp;
                return(
                <Col className="p-1-mob">
                 <div key={id1} >
                  <P_ProductLoading src={item1.imagePath} href={""} alt={item1.name} productId={item1.itemId} variantId={item1.variantId} salePrice={saleprice} mrp={mrp} sectionFor={item.sectionFor} offer={item1.offer} stockQty={item1.stockQty} />
                  </div>
                </Col>
                );
              })
            );         
          }
        }
    }

    return imageLastList;
  }
  const section3 = (id) => {
	imageFinalList=null;
	imageLastList=null;
    if (typeof props.petTemplateDetails != "undefined") {
      let item=props.petTemplateDetails.responseCompanyTemplateSections[id];
        imageFinalList = item.responseSectionItemAndImage;
        
        if (imageFinalList != null) {
          if (imageFinalList.sectionItems != null && item.sectionType == 3 && imageFinalList.sectionItems.length>0) {
            imageLastList = imageFinalList.sectionItems.map((item1, id1) => {
              return (
                <div key={id1} onClick={() => dynamicSectionLoad(item.sectionFor,item1.itemId,item1.variantId)}>
                  <img key={id1} className="w-100" src={item1.imagePath} alt={item1.name} />
                </div>
              )
            })
          }
        }

    }
    return imageLastList;
  }
  const section4 = (id) => {
	imageFinalList=null;
	imageLastList=null;
    if (typeof props.petTemplateDetails != "undefined") {
      let item=props.petTemplateDetails.responseCompanyTemplateSections[id];
        imageFinalList = item.responseSectionItemAndImage;
        
        if (imageFinalList != null) {
          if (imageFinalList.sectionItems != null && item.sectionType == 4 && imageFinalList.sectionItems.length>0) {
            imageLastList = imageFinalList.sectionItems.map((item1, id1) => {
              let saleprice=item1.offer && item1.offer.offerSalePrice?item1.offer.offerSalePrice:item1.price;
              let mrp=item1.offer && item1.offer.offerSalePrice?item1.price:item1.mrp;
              return (
                <Col className="p-1-mob">
                  <div className={"product-boxe ff show"}>
                    <P_ProductLoading src={item1.imagePath} href={""} alt={item1.name}  productId={item1.itemId} variantId={item1.variantId} salePrice={saleprice} mrp={mrp}  sectionFor={item.sectionFor} offer={item1.offer} stockQty={item1.stockQty}/>
                  </div>
                </Col>
              )
            })
          }
        }
    }
    return imageLastList;
  }
  const section5 = (id) => {
	imageFinalList=null;
	imageLastList=null;
    if (typeof props.petTemplateDetails != "undefined") {
      let item=props.petTemplateDetails.responseCompanyTemplateSections[id];
        imageFinalList = item.responseSectionItemAndImage;
       
        if (imageFinalList != null) {
          if (imageFinalList.sectionItems != null && item.sectionType == 5 && imageFinalList.sectionItems.length>0) {
            imageLastList = imageFinalList.sectionItems.map((item1, id1) => {
              return (
                <div key={id1} onClick={() => dynamicSectionLoad(item.sectionFor,item1.itemId,item1.variantId)}>
                  {/* <a href={""} target="_blank"><img key={id1} className="w-100" src={item1.imagePath} /></a> */}
                  <img key={id1} className="w-100" src={item1.imagePath}  alt={item1.name} />
                </div>
              )
            })
          }
        }
    }
    return imageLastList;
  }
  const section6 = (id) => {
	imageFinalList=null;
	imageLastList=null;
    if (typeof props.petTemplateDetails != "undefined") {
      let item=props.petTemplateDetails.responseCompanyTemplateSections[id];
        imageFinalList = item.responseSectionItemAndImage;
       
        if (imageFinalList != null) {
          if (imageFinalList.sectionItems != null && item.sectionType == 6 && imageFinalList.sectionItems.length>0) {
            imageLastList = imageFinalList.sectionItems.map((item1, id1) => {
              let saleprice=item1.offer && item1.offer.offerSalePrice?item1.offer.offerSalePrice:item1.price;
              let mrp=item1.offer && item1.offer.offerSalePrice?item1.price:item1.mrp;
              return (
                <Col className="p-1-mob">
                  <div className={"product-boxe ff show only-freatures-product"} >
                    <P_ProductLoading src={item1.imagePath} href={""} alt={item1.name} productId={item1.itemId} variantId={item1.variantId} salePrice={saleprice} mrp={mrp}  sectionFor={item.sectionFor} offer={item1.offer} stockQty={item1.stockQty}/>
                  </div>
                </Col>
              )
            })
          }
        }
    }
    return imageLastList;
  }
  const section7 = (id) => {
	imageFinalList=null;
	imageLastList=null;
    if (typeof props.petTemplateDetails != "undefined") {
      let item=props.petTemplateDetails.responseCompanyTemplateSections[id];
        imageFinalList = item.responseSectionItemAndImage;
        
        if (imageFinalList != null) {
          if (imageFinalList.sectionItems != null && item.sectionType == 7 && imageFinalList.sectionItems.length>0) {
            imageLastList = imageFinalList.sectionItems.map((item1, id1) => {
              return (
                <div key={id1} onClick={() => dynamicSectionLoad(item.sectionFor,item1.itemId,item1.variantId)}>
                  <img key={id1} className="w-100" src={item1.imagePath} alt={item1.name}/>
                </div>
              )
            })
          }
        }
    }
    return imageLastList;
  }
  const section8 = (id) => {
	imageFinalList=null;
	imageLastList=null;
    if (typeof props.petTemplateDetails != "undefined") {
      let item=props.petTemplateDetails.responseCompanyTemplateSections[id];
        imageFinalList = item.responseSectionItemAndImage;
        
        if (imageFinalList != null) {
          if (imageFinalList.sectionItems != null && item.sectionType == 8 && imageFinalList.sectionItems.length>0) {
            imageLastList = imageFinalList.sectionItems.map((item1, id1) => {
              let saleprice=item1.offer && item1.offer.offerSalePrice?item1.offer.offerSalePrice:item1.price;
              let mrp=item1.offer && item1.offer.offerSalePrice?item1.price:item1.mrp;
              return (
                <Col className="p-1-mob">
                  <div className={"product-boxe ff show"} >
                    <P_ProductLoading src={item1.imagePath} href={""} alt={item1.name} section={"section8"} productId={item1.itemId} variantId={item1.variantId} salePrice={saleprice} mrp={mrp}  sectionFor={item.sectionFor} offer={item1.offer} stockQty={item1.stockQty}/>
                  </div>
                </Col>
              )
            })
          }
        }
    }
    return imageLastList;
  }
  const section9 = (id) => {
	imageFinalList=null;
	imageLastList=null;
    if (typeof props.petTemplateDetails != "undefined") {
      let item=props.petTemplateDetails.responseCompanyTemplateSections[id];
        imageFinalList = item.responseSectionItemAndImage;
        
        if (imageFinalList != null) {
          if (imageFinalList.sectionItems != null && item.sectionType == 9 && imageFinalList.sectionItems.length>0) {
            imageLastList = imageFinalList.sectionItems.map((item1, id1) => {
              return (
                <div key={id1} onClick={() => dynamicSectionLoad(item.sectionFor,item1.itemId,item1.variantId)}>
                  {/* <a href={""} target="_blank"><img key={id1} className="w-100" src={item1.imagePath} /></a><br/>&nbsp; */}
                  <img key={id1} className="w-100" src={item1.imagePath} alt={item1.name} />
                </div>
              )
            })
          }
        }
    }
    return imageLastList;
  }
  const section11 = (id) => {
    imageFinalList=null;
    imageLastList=null;
      if (typeof props.petTemplateDetails != "undefined") {
        let item=props.petTemplateDetails.responseCompanyTemplateSections[id];
          imageFinalList = item.responseSectionItemAndImage;
          sectionFor=item.sectionFor;
          if (imageFinalList != null) {
            if (imageFinalList.sectionItems != null && item.sectionType == 11 && imageFinalList.sectionItems.length>0) {
              imageLastList = imageFinalList.sectionItems.map((item1, id1) => {
                return (
                  <div className="" key={id1} onClick={() => dynamicSectionLoad(item.sectionFor,item1.itemId,item1.variantId)}>
                    <img key={id1} src={item1.imagePath} alt={item1.name} />
                  </div>
                )
              })
            }
          }
      }
      return imageLastList;
    }
    const section12 = (id) => {
      imageFinalList=null;
      imageLastList=null;
        if (typeof props.petTemplateDetails != "undefined") {
          let item=props.petTemplateDetails.responseCompanyTemplateSections[id];
            imageFinalList = item.responseSectionItemAndImage;
            sectionFor=item.sectionFor;
            if (imageFinalList != null) {
              if (imageFinalList.sectionItems != null && item.sectionType == 12 && imageFinalList.sectionItems.length>0) {
              //console.log(imageFinalList.sectionItems)
                imageLastList = imageFinalList.sectionItems.map((item1, id1) => {
                  return (
                    <div className="" key={id1} onClick={() => dynamicSectionLoad(item.sectionFor,item1.itemId,item1.variantId)}>
                      <img key={id1} src={item1.imagePath} alt={item1.name} />
                    </div>
                  )
                })
              }
            }
        }
        return imageLastList;
      }


    const section14 = (id) => {
      imageFinalList=null;
      imageLastList=null;
        if (typeof props.petTemplateDetails != "undefined") {
          let item=props.petTemplateDetails.responseCompanyTemplateSections[id];
            imageFinalList = item.responseSectionItemAndImage;
           
      
           
            if (imageFinalList != null) {
              if (imageFinalList.sectionItems != null && item.sectionType == 14 && imageFinalList.sectionItems.length>0) {
                return (
                  imageLastList = imageFinalList.sectionItems.map((item1, id1) => {
                    //console.log("deep_item",item1);
                    let saleprice=item1.offer && item1.offer.offerSalePrice?item1.offer.offerSalePrice:item1.price;
                    let mrp=item1.offer && item1.offer.offerSalePrice?item1.price:item1.mrp;
                    return(
                    <Col className="p-1-mob">
                     <div key={id1} >
                      <P_ProductLoading src={item1.imagePath} href={""} alt={item1.name} productId={item1.itemId} variantId={item1.variantId} salePrice={saleprice} mrp={mrp} sectionFor={item.sectionFor} offer={item1.offer} stockQty={item1.stockQty}  />
                      </div>
                    </Col>
                    );
                  })
                );         
              }
            }
        }
    
        return imageLastList;
      }
      const section16 = (id) => {
        imageFinalList=null;
        imageLastList=null;
          if (typeof props.petTemplateDetails != "undefined") {
            let item=props.petTemplateDetails.responseCompanyTemplateSections[id];
              imageFinalList = item.responseSectionItemAndImage;

              if (imageFinalList != null) {
                if (imageFinalList.sectionItems != null && item.sectionType == 16 && item.sectionFor==2 && imageFinalList.sectionItems.length>0) {
                  return (
                    imageLastList = imageFinalList.sectionItems.map((item1, id1) => {
                      //console.log("deep_item",item1);
                      let saleprice=item1.offer && item1.offer.offerSalePrice?item1.offer.offerSalePrice:item1.price;
                      let mrp=item1.offer && item1.offer.offerSalePrice?item1.price:item1.mrp;
                      return(
                      <Col className="p-1-mob">
                       <div key={id1} >
                        <P_ProductLoading src={item1.imagePath} href={""} alt={item1.name} productId={item1.itemId} variantId={item1.variantId} salePrice={saleprice} mrp={mrp} sectionFor={item.sectionFor} sectionType={item.sectionType} offer={item1.offer} stockQty={item1.stockQty}  />
                        </div>
                      </Col>
                      );
                    })
                  );         
                }
              }
          }
      
          return imageLastList;
        }

  const fromMenuSectionDynamic = () => {
    // window.onscroll = window.addEventListener("scroll", lazyLoad);
    const productId = props.CategoryMenuData.id;
    imageLastList = props.CategoryMenuData.images.map((item, id) => {
      return (
        <div className="col-md-3">
          <P_ProductLoading src={item.fullImagepath} href={""} alt={item.name} productId={productId} variantId={(typeof props.CategoryMenuData != "undefined" && props.CategoryMenuData != null) ? props.CategoryMenuData.variant[0] : 0} salePrice={props.CategoryMenuData.salePrice[0]} mrp={props.CategoryMenuData.mrp[0]}  sectionFor={props.CategoryMenuData.sectionFor[0]} offer={props.CategoryMenuData.offer} stockQty={props.CategoryMenuData.stockQty}/>
        </div>
      )
    })
    return imageLastList;
  }
  
  //extra aeed
  //console.log('props', props);
  let { product } = props;
  const [loading, setLoading] = useState(false);
  const wishlist = () => {

    if (!props.authenticated) {
      props.setLoginModal(true);
      props.set_login_head("Login");
    }
    else
      handleImgClick();

  }
  const removewishlist = () => {
    setHead("Wish Listed Items");
    setShow(true);
    setContent(<WishlistItems proid={product.id} />);
  }
  const handleImageLoaded = () => {
    setLoading(true);
  }
  const handleImgClick = () => {
    navigate(`/products/${product.id}`)
  }
  const Overlay_name = (param) => {
    return (
      <OverlayTrigger placement='bottom' overlay={<Tooltip className="tooltip"> {param}</Tooltip>}>
        <h4 className="product-name product-name-home elips-twoline mb-2">{param.substring(0, 34).concat("...")}</h4>
      </OverlayTrigger>
    );
  }
  //end
  
  //console.log('product Alllll', selectedProduct);
  return ((!showDefaultSection) ?
    <React.Fragment>
      <Header/>
      <div className={props.companyDetailsCurrency.announcementText? "full-page-body full-page-body-announce":" full-page-body"}>
      {props.petTemplateDetails && props.petTemplateDetails.responseCompanyTemplateSections?
      props.petTemplateDetails.responseCompanyTemplateSections.map((sec_item,sec_id)=>{
        if(sec_item.sectionType==1 && sec_item.responseSectionItemAndImage && sec_item.responseSectionItemAndImage.sectionItems && sec_item.responseSectionItemAndImage.sectionItems.length>0)
        return(
        <Row className="product-details-maindiv ">
          <Container className="p-0-mob">
          <Col md="12" className="p-0">
            <div className="Banner-crousal">
              <Carousel
                showThumbs={false}
                renderThumbs={renderThumbs}
                autoPlay={true}
                stopOnHover={true}
                infiniteLoop={true}
              >
                {section1(sec_id)}
              </Carousel>
            </div>

                        {/* ---------------------Small Banner--------------------- */}
                        <div className="small-banner d-none">
              <div className="position-relative">
              <img src="https://9yob.com/wp-content/uploads/2023/02/192-banner.jpg" className="w-100" />
              <div className="small-banner-text">
                  <h1>About Us</h1>
                  <p>Lorem Ipsum Dolor Sit Amet</p>
              </div>
              </div>
                        
            </div>
            {/* ---------------------medium Banner--------------------- */}
            <div className="medium-banner d-none">
              <div className="position-relative">
              <img src="https://9yob.com/wp-content/uploads/2023/02/288-banner.jpg" className="w-100" />
              <div className="medium-banner-text">
                  <h1>About Us</h1>
                  <p>Lorem Ipsum Dolor Sit Amet</p>
              </div>
              </div>
                        
            </div>

          </Col>
          </Container>
        </Row>
        )
       
        if(sec_item.sectionType==2 && sec_item.responseSectionItemAndImage && sec_item.responseSectionItemAndImage.sectionItems && sec_item.responseSectionItemAndImage.sectionItems.length>0)
        return(
          <Row className="product-details-maindiv raw-bites">
          <Container className="pl-10-pr-10-mob">
            <Row className="position-relative bg-light-control">
              <Col md="12 text-center pl-5px-mob">
              <h3 className="page-lab-head">{sec_item.sectionName}</h3>
		          </Col>
              <div className="col-md-12 p-0">
                <Slider {...{...settings,infinite:sec_item.responseSectionItemAndImage.sectionItems.length<4?false:true}}>
                  {section2(sec_id)}
                </Slider>
              </div>
            </Row>
          </Container>

        </Row>
          )
        if(sec_item.sectionType==3 && sec_item.responseSectionItemAndImage && sec_item.responseSectionItemAndImage.sectionItems && sec_item.responseSectionItemAndImage.sectionItems.length>0)
        return(
        <Row className="product-details-maindiv">
          <Container className="pl-10-pr-10-mob">
            <Row className="position-relative">
              {/* <label class="comon-arrow-style lft-pos">❮</label> */}
             
              <div className="col-md-12 p-0">
                <Slider {...{...settings2,infinite:sec_item.responseSectionItemAndImage.sectionItems.length<4?false:true}}>
                  {section3(sec_id)}
                </Slider>
              </div>
              {/* <label class="comon-arrow-style rig-pos">❯</label> */}
            </Row>
          </Container>
        </Row>
        )
        if(sec_item.sectionType==4 && sec_item.responseSectionItemAndImage && sec_item.responseSectionItemAndImage.sectionItems && sec_item.responseSectionItemAndImage.sectionItems.length>0)
        return(
        <Row className="product-details-maindiv deeptaman text-center">
          <Container className="pl-10-pr-10-mob">
          <Row className="position-relative bg-light-control">
          <Col md="12 text-center pl-5px-mob">
          <h3 className="page-lab-head w-100">{sec_item.sectionName}</h3>
		  </Col>
            <div className="col-md-12 p-0">
                <Slider {...{...settings,infinite:sec_item.responseSectionItemAndImage.sectionItems.length<4?false:true}}>
                  {section4(sec_id)}
                </Slider>
              </div>
            </Row>
            
          </Container>
        </Row>
        )
        if(sec_item.sectionType==5 && sec_item.responseSectionItemAndImage && sec_item.responseSectionItemAndImage.sectionItems && sec_item.responseSectionItemAndImage.sectionItems.length>0)
        return(
        <Row className="product-details-maindiv">
          <Container className="pl-10-pr-10-mob">
            <Row className="position-relative">
              <div className="col-md-12 p-0">
                <Slider {...{...settings2,infinite:sec_item.responseSectionItemAndImage.sectionItems.length<4?false:true}}>
                  {section5(sec_id)}
                </Slider>
              </div>
            </Row>
          </Container>
        </Row>
        )
        if(sec_item.sectionType==6 && sec_item.responseSectionItemAndImage && sec_item.responseSectionItemAndImage.sectionItems && sec_item.responseSectionItemAndImage.sectionItems.length>0)
        return(
        <Row className="product-details-maindiv text-center">
          <Container className="pl-10-pr-10-mob">
            <Row className="position-relative bg-light-control">
            <div className="col-md-12 text-center pl-5px-mob"> <h3 className="page-lab-head w-100">{sec_item.sectionName}</h3></div>
            <div className="col-md-12 p-0">
              <Slider {...{...settingsfeatures,infinite:sec_item.responseSectionItemAndImage.sectionItems.length<4?false:true}}>
                {section6(sec_id)}
              </Slider>
            </div>
          </Row></Container>
        </Row>
        )
        if(sec_item.sectionType==7 && sec_item.responseSectionItemAndImage && sec_item.responseSectionItemAndImage.sectionItems && sec_item.responseSectionItemAndImage.sectionItems.length>0)
        return(
        <Row className="product-details-maindiv">
          <Container className="pl-10-pr-10-mob"><Row className="position-relative">
            <div className="col-md-12 p-0">
              <Slider {...{...settings2,infinite:sec_item.responseSectionItemAndImage.sectionItems.length<4?false:true}}>
                {section7(sec_id)}
              </Slider>
            </div>
          </Row></Container>
        </Row>
        )
        if(sec_item.sectionType==8 && sec_item.responseSectionItemAndImage && sec_item.responseSectionItemAndImage.sectionItems && sec_item.responseSectionItemAndImage.sectionItems.length>0)
        return(
        <Row className="product-details-maindiv">
          <Container className="pl-10-pr-10-mob"><Row className="position-relative bg-light-control">
           
		  <Col md="12" className="pl-5px-mob"> <h3 className="page-lab-head w-100">{sec_item.sectionName}</h3></Col>
            <div className="col-md-12 p-0">
              <Slider {...{...settings,infinite:sec_item.responseSectionItemAndImage.sectionItems.length<4?false:true}}>
                {section8(sec_id)}
              </Slider>
            </div>
           
          </Row></Container>
        </Row>
        )
        if(sec_item.sectionType==9 && sec_item.responseSectionItemAndImage && sec_item.responseSectionItemAndImage.sectionItems && sec_item.responseSectionItemAndImage.sectionItems.length>0)
        return(
        <Row className="product-details-maindiv">
          <Container className="pl-10-pr-10-mob"><Row className="position-relative">
            <div className="col-md-12 p-0">
              <Slider {...{...settings2,infinite:sec_item.responseSectionItemAndImage.sectionItems.length<4?false:true}}>
                {section9(sec_id)}
              </Slider>
            </div>
          </Row></Container>
        </Row>)
         if(sec_item.sectionType==11 && sec_item.responseSectionItemAndImage && sec_item.responseSectionItemAndImage.sectionItems && sec_item.responseSectionItemAndImage.sectionItems.length>0)
         return(
         <Row className="product-details-maindiv">
           <Container className="p-0-mob">
           <Col md="12" className="p-0">
           <div className="small-banner">
               <Carousel
                 showThumbs={false}
                 renderThumbs={renderThumbs}
                 autoPlay={true}
                 stopOnHover={true}
                 infiniteLoop={true}
                 showIndicators={false}
               >
                 {section11(sec_id)}
               </Carousel>
             </div>
 
           </Col>
           </Container>
         </Row>
         )
         if(sec_item.sectionType==12 && sec_item.responseSectionItemAndImage && sec_item.responseSectionItemAndImage.sectionItems && sec_item.responseSectionItemAndImage.sectionItems.length>0)
         return(
         <Row className="product-details-maindiv">
           <Container className="p-0-mob">
           <Col md="12" className="p-0">
           <div className="medium-banner">
               <Carousel
                 showThumbs={false}
                 renderThumbs={renderThumbs}
                 autoPlay={true}
                 stopOnHover={true}
                 infiniteLoop={true}
               >
                 {section12(sec_id)}
               </Carousel>
             </div>
 
           </Col>
           </Container>
         </Row>
         )


       

      if(sec_item.sectionType==14 && sec_item.responseSectionItemAndImage && sec_item.responseSectionItemAndImage.sectionItems && sec_item.responseSectionItemAndImage.sectionItems.length>0)
        return(
          <Row className="product-details-maindiv raw-bites">
          <Container className="pl-10-pr-10-mob">
            <Row className="position-relative bg-light-control">
              <Col md="12 text-center pl-5px-mob">
              <h3 className="page-lab-head">{sec_item.sectionName}</h3>
		          </Col>
              <div className="col-md-12 p-0">
                <Slider {...{...settings,infinite:sec_item.responseSectionItemAndImage.sectionItems.length<4?false:true}}>
                  {section14(sec_id)}
                </Slider>
              </div>
            </Row>
          </Container>

        </Row>
          )
          if(sec_item.sectionType==16 && sec_item.sectionFor==2 &&  sec_item.responseSectionItemAndImage && sec_item.responseSectionItemAndImage.sectionItems && sec_item.responseSectionItemAndImage.sectionItems.length>0)
        return(
       
          <Row className="product-details-maindiv raw-bites">
          <Container className="pl-10-pr-10-mob">
            <Row className="position-relative bg-light-control" style={sec_item.sectionBackgrounColor ? {backgroundColor:sec_item.sectionBackgrounColor}:{backgroundColor:''}}>
              <Col md="12 text-center pl-5px-mob">
              <h3 className="page-lab-head" >{sec_item.sectionName}</h3>
		          </Col>
              <div className="col-md-12 p-0">
                <Slider {...{...settings,infinite:sec_item.responseSectionItemAndImage.sectionItems.length<4?false:true}}>
                  {section16(sec_id)}
                </Slider>
              </div>
            </Row>
          </Container>

        </Row>
          )
      }):null}
      
      {/* ----------------------------New item section------------------------- */}
      <Row className="product-details-maindiv mt-4 pt-0 d-none">
        <Container className="pl-10-pr-10-mob">
          <Row className="position-relative bg-light-control">
            <div className="col-md-12">
            <h3 className="page-lab-head w-100">Featured Items</h3>
            </div>
             <div className="col-md-3">
                  <div className="garment-item-div mb-3">
                      <div className="garment-type-inner">
                        <img src="https://9yob.com/wp-content/uploads/2023/02/Tussar-silk.png"  className="w-100 abs-img"/>
                        </div>
                        <p className="text-center mt-2 mb-2 garment-header">Lemon Yellow with blue and pink broad border</p>
                       
                  </div>   
              </div>
              <div className="col-md-3">
                  <div className="garment-item-div mb-3">
                      <div className="garment-type-inner">
                        <img src="https://9yob.com/wp-content/uploads/2023/02/Tussar-silk.png"  className="w-100 abs-img"/>
                        </div>
                        <p className="text-center mt-2 mb-2 garment-header">Lemon Yellow with blue and pink broad border</p>
                     
                  </div>
              </div>
              <div className="col-md-3">
                  <div className="garment-item-div mb-3">
                      <div className="garment-type-inner">
                        <img src="https://9yob.com/wp-content/uploads/2023/02/Tussar-silk.png"  className="w-100 abs-img"/>
                        </div>
                        <p className="text-center mt-2 mb-2 garment-header">Lemon Yellow with blue and pink broad border</p>
                     
                  </div>
              </div>
              <div className="col-md-3">
                  <div className="garment-item-div mb-3">
                      <div className="garment-type-inner">
                        <img src="https://9yob.com/wp-content/uploads/2023/02/Tussar-silk.png"  className="w-100 abs-img"/>
                        </div>
                        <p className="text-center mt-2 mb-2 garment-header">Lemon Yellow with blue and pink broad border</p>
                        <div className="addtocart-div p-abcenterbutton text-center">
                        <button className="secondary-round-button min-same-width buy-now-addcart">Buy Now</button>
                        <button className="primary-button min-same-width buy-now-addcart">Add to cart</button>
                        </div>
                  </div>
              </div>
          </Row>
        </Container>
     </Row>  
        
        <div className="row footer-wrap-top">
          <Container>
            <div className="row">
              <Col className="col-md-3 col-12">
                <div className="wdhet-div">
                  <div className="wdicon">
                    <i class="fa fa-truck" aria-hidden="true"></i>
                  </div>
                  <div className="wd-para">
                    <h3>Fast &amp; Free Delivery</h3>
                    
                  </div>
                </div>
              </Col>
              <Col className="col-md-3 col-12">
                <div className="wdhet-div">
                  <div className="wdicon">
                    <i class="fa fa-star-o" aria-hidden="true"></i>
                  </div>
                  <div className="wd-para">
                    <h3>High Quality Product</h3>
                    <p>
                    Maintain proper measurement and quality of product</p>
                  </div>
                </div>
              </Col>
              <Col className="col-md-3 col-12">
                <div className="wdhet-div">
                  <div className="wdicon">
                  <i class="fa fa-headphones" aria-hidden="true"></i>
                  </div>
                  <div className="wd-para">
                    <h3>24/7 Customer Support</h3>
                    <p>
                    Friendly 24/7 customer support</p>
                  </div>
                </div>
              </Col>
              {/*<Col className="col-md-3 col-12">
                <div className="wdhet-div">
                  <div className="wdicon">
                  <i class="fa fa-credit-card" aria-hidden="true"></i>
                  </div>
                  <div className="wd-para">
                    <h3>Secure online payment</h3>
                    <p>We possess SSL/ Secure certificate</p>
                  </div>
                </div>
              </Col>*/}
            </div>
          </Container>
        </div>
        <div className="footer-page">
          <Footer />
        </div>
      </div>




      <MsgModal
        show={show}
        title={head}
        message={content}
        closeAction={closeAction}
      />
    </React.Fragment> :
    <React.Fragment>
      {fromMenuSectionDynamic()}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  productsList: state.ProductsData.productsList,
  product: state.ProductsData.selectedProduct,
  variantList: state.ProductsData.variantList,
  companyid: state.HomeData.companyid,
  cartList: state.CartData.cartList,
  mobileState: state.CategoryData.isMobileMenu,
  authenticated: state.AuthData.authenticated,
  loginData: state.AuthData.loginData,
  wishlist: state.ProductsData.wishlist,
  getratingdata: state.ProductsData.getratingdata,
  companyDetailsCurrency: state.HomeData.companyDetailsCurrency,
  petTemplateDetails: state.petProductData.userP_AllProductInfo.data,
  petTemplateDetailsForLazyLoad: state.petProductData.userP_AllProductInfoForLazyLoad
});

export default connect(mapStateToProps, {
  getItemsByVariant,
  selectProduct,
  previewCart,
  saveWishList,
  setLoginModal,
  getWishList,
  removeWishList,
  getRating,
  set_login_head,
  P_ProductInfo,
  P_ProductInfoForLazyLoad
})(P_AllProduct);
