import {apiAction} from '../middleware/api/apiActions';
import API from './APIConst';

import {host} from '../utils/Env';

const CONFIG_INFO = "CONFIG_INFO";
const COMPANY_ID = "COMPANY_ID";
const GET_TEMPLATE="GET_TEMPLATE";
const SET_TEMPLATE="SET_TEMPLATE";
const SET_IN_VIEW="SET_IN_VIEW";
/**
 * Action getCompanyDetails defined.
 * @constructor
 * @param {string} param - Pass param to update the payload.
 */ 


/**
 * Action  defined.
 * @constructor
 * @param {string} param - Pass param to update the payload.
 */ 
export const getCompanyId = (ob) => {
    return apiAction({
        url: `${API.GET_COMPANY_INFO}${ob}`, 
		method: "post",
        onSuccess: setCompanyId,
        onFailure: () => console.log("Error occured in getCompanyId"),
        callback: 'getcompanydetails',
        label: COMPANY_ID
      });
}


export const getTemplate = (ob) => {
    return apiAction({
        url: API.GET_TEMPLATE, 
		method: "post",
		data:ob,
        onSuccess: setTemplate,
        onFailure: () => console.log("Error occured in getTemplate"),
        label: GET_TEMPLATE
      });
}

export const setTemplate = param => {
    return ({
    type: SET_TEMPLATE,
    payload: param
})}

export const setInView = param => {
    return ({
    type: SET_IN_VIEW,
    payload: param
})}

export const getConfigInfo = () => {
    return apiAction({
        url: window.location.origin.toString().concat(API.GET_CONFIG), 
        onSuccess: setConfigInfo,
        onFailure: () => console.log("Error occured in getConfigInfo"),
		callback: 'getConfigInfo',
        label: CONFIG_INFO
      });
}

export const setConfigInfo = param => ({
    type: CONFIG_INFO,
    payload: param.data.company
  });
  
  export const setCompanyId = param => ({
    type: COMPANY_ID,
    payload: param.data
  });


/**
 * set up with initial state.
 */
const initialState = {
    companyid: '',
	template:{},
	templateStatus:false,
	templateMessage:'',
	companyDetailsCurrency:{},
    inView:false
}

/**
 * reducer .
 * @constructor
 * @param {string} state - state of the data shape.
 * @param {string} action - action payload passed for data updates.
 */
let reducer = (state = initialState, action) => {
    switch (action.type) {
        //case COMPANY_ID: return {...state, companyid: action.payload.companyid, logo:action.payload.logo }
        case CONFIG_INFO: return {...state, ...action.payload }
		// case COMPANY_ID: return {...state, companyid:action.payload.companyid,companyDetailsCurrency:action.payload }
        case COMPANY_ID: return {...state, companyid:action.payload.companyid,companyDetailsCurrency:action.payload,...action.payload }
		case SET_TEMPLATE: return {...state, templateStatus:action.payload.status, templateMessage:action.payload.message, template:action.payload.data }
        case SET_IN_VIEW: return {...state,inView:action.payload}
        default: return state;
    }
}

/**
 * Export Object (Action + Reducer)
 */
const HomeAction = {
    reducer: reducer,
    actions: {
        getConfigInfo,
		getCompanyId,
		getTemplate
    }
}

export default HomeAction