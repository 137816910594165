import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Navbar } from "react-bootstrap";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Global } from '../utils/Env';
import Address from "../components/checkout/Address";
import FinalOrderList from "../components/checkout/FinalOrderList";
import PhoneValidate from "../components/checkout/PhoneValidate";
import Delivery from "../components/checkout/Delivery";
import GenericModal from "../components/checkout/OrderConfirmationModal";
import Payment from "../components/checkout/Payment";
import Footer from "../components/Footer";
import Logo from "../components/header/Logo";
import { setValidateOtp } from "../actions/AuthAction";
import { Button } from 'react-bootstrap';
import Progress from '../components/checkout/Progress';
import { to2Decimal, formatINR, formatSymbolFromCurrency, hasProps } from '../utils/CommonUtil';
import { selectPlaceOrderJSON, placeOrder, razorPay1func, razorpaysuccess_func,getDeliveryCharge, razorpayfailure_func, setRazorPayStatus, setModal, placeOrderCash, set_paymentmode, savePayment, setSelectedvoucher } from '../actions/CheckoutAction';

const CheckOut = (props) => {
  const [phnCard, setPhnCard] = useState(true);
  const [addressCard, setAddressCard] = useState(false);
  const [deliveryCard, setDeliveryCard] = useState(false);
  const [paymentCard, setPymentCard] = useState(false);

  //const [checkoutState, setCheckOutState] = useState([0,0,0,0]);
  //const showSection = (typeof props.showSection=="undefined")?true:props.showSection;
  const [showPhoneSection, setshowPhoneSection] = useState(false);
  const [showAddressSection, setshowAddressSection] = useState(true);
  const [showDeliverySection, setshowDeliverySection] = useState(false);
  const [showPaymentSection, setshowPaymentSection] = useState(false);
  const [showFinalOrderListSection, setshowFinalOrderListSection] = useState(false);
  const [currentSection, setcurrentSection] = useState("Address");
  const [DeliveryAddressSet, setDeliveryAddressSet] = useState(false);
  const [showNextButton, setshowNextButton] = useState(true);
  const [showPreviousButton, setshowPreviousButton] = useState(true);
  const [paymentType, setPaymentType] = useState('razorpay');
	const [cash, setCash] = useState(true);
	const [razorpay, setRazorpay] = useState(false);
	const [phonePay, setphonePay] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const isPhoneValidated = () => {
    return props.valid_otp;
  };
	useEffect(() => {
		props.setSelectedvoucher({});
	}, [props.paymentCard]);
  const info_content = <><p>can claim to return within 7 days of receipt of the order </p>&nbsp;<p>refund will be initiated only on receipt of the order and subject to the terms and conditions</p>&nbsp;<p>The product must be returned in its original condition along with the invoice, all labels, tags should be present in the garments </p>&nbsp;<p>Any garment sought to be returned or exchanged, should be unworn or unwashed. Garments that are found to be worn or damaged or soiled will not be accepted for return or exchange</p>&nbsp;<p>Please note an order can only cancelled within 24 hours of placing the order. Once the order is processed after 24 hours, no cancellation request will be entertained.</p>&nbsp;<p>As part of usual business practice, We reserves the right to cancel the order and refund the entire amount back to you, if order has not been approved / processed by Indipet.</p></>
  Global.callback.placeordercon_onComplete = () => {
		//console.log( props.loginData.data);
		if (typeof props.loginData.data == "undefined" || props.loginData.data == null) {
			//console.log(props.order.loginInfo);
			let obj = {
				"status": true,
				"message": "",
				"data": JSON.parse(JSON.stringify(props.order.loginInfo))
			}
			obj.data["customerId"] = props.order.customerId;
			props.setLogin(obj);
			props.set_authenticated(true);
			props.set_phoneNumber('');
			props.set_password('');
			props.set_phone(props.order.loginInfo.phone);
			props.setValidateOtp({ "status": 1, "message": "Success", "data":{"isOTPVerified": true }});
		}
		props.setModal(false);
		let order_data = JSON.parse(JSON.stringify(props.placeOrderData));
		order_data["OrderId"] = props.order.orderId;
		order_data["OrderNo"] = props.order.orderNo;
		order_data.customer.fname = (props.authenticated) ? props.loginData.data.firstName : ((props.order.loginInfo != null) ? props.order.loginInfo.firstName : order_data.customer.fname);
		order_data.customer.mname = (props.authenticated) ? props.loginData.data.middleName : ((props.order.loginInfo != null) ? props.order.loginInfo.middleName : order_data.customer.mname);
		order_data.customer.lname = (props.authenticated) ? props.loginData.data.lastName : ((props.order.loginInfo != null) ? props.order.loginInfo.lastName : order_data.customer.lname);
		props.razorPay1func(order_data);
	}
 
  const handleShowHideDivPreviousClick = () => {
    //console.log('currentSectionPrevious',currentSection)
    if (currentSection == "Phone") {
      setshowPhoneSection(false);
      setshowDeliverySection(false);
      setshowPaymentSection(false);
      setshowFinalOrderListSection(false);
      setshowAddressSection(false);
      setcurrentSection("Phone");
      setshowPreviousButton(false);
      setshowNextButton(true);
    }
     else if(currentSection=="Address")
      {
        navigate("/Cart");
      }
    else if (currentSection == "Delivery") {
      setshowPhoneSection(false);
      setshowDeliverySection(false);
      setshowPaymentSection(false);
      setshowFinalOrderListSection(false);
      setshowAddressSection(true);
      setcurrentSection("Address");
      setshowPreviousButton(true);
      setshowNextButton(true);
      
    }
    else if (currentSection == "FinalOrderList") {
      setshowPhoneSection(false);
      setshowDeliverySection(true);
      setshowPaymentSection(false);
      setshowFinalOrderListSection(false);
      setshowAddressSection(false);
      setcurrentSection("Delivery");
      setshowPreviousButton(true);
      setshowNextButton(true);
    }
    else if (currentSection == "Payment") {
      setshowPhoneSection(false);
      setshowDeliverySection(false);
      setshowPaymentSection(false);
      setshowFinalOrderListSection(true);
      setshowAddressSection(false);
      setcurrentSection("FinalOrderList");
      setshowPreviousButton(true);
      setshowNextButton(true);
    }
    // else if(currentSection=="")
    // {
    //   setshowPhoneSection(true);
    //   setshowDeliverySection(true);
    //   setshowPaymentSection(true);
    //   setshowFinalOrderListSection(true);
    //   setshowAddressSection(true);
    //   setcurrentSection("Phone");
    // }
  }
  const handleShowHideDivNextClick = () => {
    //console.log('currentSectionNext',currentSection)
    if (currentSection == "Phone") {
      setshowPhoneSection(false);
      setshowDeliverySection(false);
      setshowPaymentSection(false);
      setshowFinalOrderListSection(false);
      setshowAddressSection(true);
      setcurrentSection("Address");
      setshowPreviousButton(false);
      setshowNextButton(true);
    }
    else if (currentSection == "Address") {
      setshowPhoneSection(false);
      setshowDeliverySection(true);
      setshowPaymentSection(false);
      setshowFinalOrderListSection(false);
      setshowAddressSection(false);
      setcurrentSection("Delivery");
      setshowPreviousButton(true);
      setshowNextButton(true);
      props.getDeliveryCharge({
        "totalAmount": props.checkoutDetails.totalamt,
        "taxAmount": props.checkoutDetails.totaltaxamt,
        "netAmount": props.checkoutDetails.grandtotalamt,
        "zipCode": props.pin,
        "companyId": props.companyid
    })
    }
    else if (currentSection == "Delivery") {
      setshowPhoneSection(false);
      setshowDeliverySection(false);
      setshowPaymentSection(false);
      setshowFinalOrderListSection(true);
      setshowAddressSection(false);
      setcurrentSection("FinalOrderList");
      setshowPreviousButton(true);
      setshowNextButton(true);
    }
    else if (currentSection == "FinalOrderList") {
      setshowPhoneSection(false);
      setshowDeliverySection(false);
      setshowPaymentSection(true);
      setshowFinalOrderListSection(false);
      setshowAddressSection(false);
      setcurrentSection("Payment");
      setshowPreviousButton(true);
      setshowNextButton(false);
    }
    else if (currentSection == "") {
      setshowPhoneSection(false);
      setshowDeliverySection(true);
      setshowPaymentSection(true);
      setshowFinalOrderListSection(true);
      setshowAddressSection(true);
      setcurrentSection("Phone");
    }
  }
  
  useEffect(() => {

    navigate(
      location.state && location.state.process ? "/checkout" : "/",
      { state: { process: false }, replace: true }
    );
    // console.log("Deep_test", props.companyDetailsCurrency.currency);
    if (!props.companyDetailsCurrency.currency) navigate("/");
    //to be only used for 101 hooks
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disableAllState = () => {
    setPhnCard(true);
    setAddressCard(false);
    setDeliveryCard(false);
    setPymentCard(false);
  };
  const editPhoneNumber = () => {
    props.setValidateOtp({ "status": 1, "message": "Success", "data":{"isOTPVerified": true }});
    disableAllState();
  };

  const displayHeader = () => {
    return (
      <React.Fragment>
        <header>
          <div className=" p-0-mob">

            <Row className="topsection-header chkout-top">
              <Col
                md={2}
                xs={2}
                className={
                  props.mobileState
                    ? "text-center pr-0 "
                    : "text-center pr-0"
                }
              >
                <Logo />
              </Col>
            </Row>

          </div>
        </header>
      </React.Fragment>
    );
  };
  const onOrderClicked = () => {
		if (razorpay)
			props.placeOrder(props.placeOrderData);
		else if (cash)
			props.placeOrderCash(props.placeOrderData);
		else if (phonePay)
			props.placeOrderCash(props.placeOrderData);
	}

  /* useEffect(() => {
        console.log('card changed ', phnCard, addressCard, deliveryCard, paymentCard);
    }, [phnCard, addressCard, deliveryCard, paymentCard]); */
  // console.log('set_DeliveryAddressSet', DeliveryAddressSet);
  // console.log('checkoutDetails', props.checkoutDetails);
  return (
    <React.Fragment>
      {props.mobileState ? (
        <Navbar
          bg="white"
          sticky="top"
          expand="lg border-bottom"
          className="navstylecontrol checkout_mobileheader"
        >
          {" "}
          {displayHeader()}
        </Navbar>
      ) : (
        displayHeader()
      )}

      <div className="themebggradientcustom">
        <div className="action-block action-blockpopup pos-fixedclass bot-autofd-div">
          <Container
            className={
              props.mobileState
                ? "p-0-mob p-0-tab checkout_mobileback"
                : "p-0-mob p-0-tab"
            }
          >
            <div className="">
              {/* <Link to="/" className="product-links-anchor pl-3 pr-3">
                Back to Shopping
              </Link> */}
              <div style={{ display: showPhoneSection ? 'block' : 'none' }}>
                {isPhoneValidated() && (
                  <span
                    title="Edit Checkout Process"
                    className={
                      props.mobileState ? "checkout_edit_mobile" : "checkout_edit"
                    }
                    onClick={editPhoneNumber}
                  >
                    <b>
                      Edit &nbsp;
                      <i style={{ margin: "2px" }} className="fa fa-pencil"></i>
                    </b>
                  </span>
                )}
              </div>
            </div>
          </Container>
        </div>
        <Container
          className={
            props.mobileState
              ? "p-0-mob p-0-tab checkout_container checkout_mobilecon"
              : "p-0-mob p-0-tab checkout_container"
          }
        >
          <Row>

            <Col md="12 mb-2">
              <div><Progress currentSection={currentSection} 
               /></div>
            </Col>
            <div className="product-details-maindiv top-50-martop col-md-12 p-0">
              <Col md="mb-2">
                {showPhoneSection?
                <div style={{ display: showPhoneSection ? 'flex' : 'none' }} className="row">
                  <div className="col-md-8 pl-10-pr-10-mob">
                    <PhoneValidate
                      setAddressCard={setAddressCard}
                      phnCard={phnCard}
                      setPhnCard={setPhnCard}
                      disableAllState={disableAllState}
                    />
                  </div>
                  <div className="col-md-4">
                    ph
                  </div>

                </div>:''}
                <div style={{ display: showAddressSection ? 'flex' : 'none' }} className="row">
                  <div className="col-md-8 pl-10-pr-10-mob">
                    <Address
                      setDeliveryCard={setDeliveryCard}
                      setAddressCard={setAddressCard}
                      addressCard={addressCard}
                      setDeliveryAddressSet={setDeliveryAddressSet}
                    />
                  </div>
                  <div className="col-md-4">
                    <div className="gtotal-section">
                      {/* <h3 className="p-derails-header">Price Details</h3> */}
                      <div>
                        <div><p className="addressbrands-secondallpages">Item Total<span className="float-right text-dark"><b>{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode), to2Decimal(props.checkoutDetails.totalamt))}</b></span></p></div>
                        <div><p className="addressbrands-secondallpages">Total Tax<span className="float-right text-dark"><b>{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode), to2Decimal(props.checkoutDetails.totaltaxamt))}</b></span></p></div>
                        {!showAddressSection?<div><p className="addressbrands-secondallpages">Delivery Charges<span className="float-right text-dark"><b>{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode), to2Decimal(props.checkoutDetails.deliverychargeamt))}</b></span></p></div>:''}
                        <div class="Subtotal border-top pt-2 mt-3"><span>Total Amount</span><span class="float-right font-bold">{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode), to2Decimal(props.checkoutDetails.grandtotalamt))}</span></div>

                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="shield-did d-inline-block"><i class="fa fa-shield" aria-hidden="true"></i> </div><div className="d-inline-block shild-txt">Safe and Secure Payments.Easy returns.100% Authentic products.</div>
                    </div>
                  </div>

                </div>
                <div style={{ display: showDeliverySection ? 'flex' : 'none' }} className="row">

                  <div className="col-md-8 pl-10-pr-10-mob">
                    <Delivery
                      setPymentCard={setPymentCard}
                      setDeliveryCard={setDeliveryCard}
                      deliveryCard={deliveryCard}
                    />
                  </div>
                  <div className="col-md-4">
                    <div className="gtotal-section">
                      {/* <h3 className="p-derails-header">Price Details</h3> */}
                      <div>
                        <div><p className="addressbrands-secondallpages">Item Total<span className="float-right text-dark"><b>{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode), to2Decimal(props.checkoutDetails.totalamt))}</b></span></p></div>
                        <div><p className="addressbrands-secondallpages">Total Tax<span className="float-right text-dark"><b>{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode), to2Decimal(props.checkoutDetails.totaltaxamt))}</b></span></p></div>
                        <div><p className="addressbrands-secondallpages">Delivery Charges<span className="float-right text-dark"><b>{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode), to2Decimal(props.checkoutDetails.deliverychargeamt))}</b></span></p></div>
                        <div class="Subtotal border-top pt-2 mt-3"><span>Total Amount</span><span class="float-right font-bold">{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode), to2Decimal(props.checkoutDetails.grandtotalamt))}</span></div>

                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="shield-did d-inline-block"><i class="fa fa-shield" aria-hidden="true"></i> </div><div className="d-inline-block shild-txt">Safe and Secure Payments.Easy returns.100% Authentic products.</div>
                    </div>
                  </div>

                </div>
                <div style={{ display: showPaymentSection ? 'flex' : 'none' }} className="row">
                  <div className="col-md-8 pl-10-pr-10-mob">
                    <Payment paymentCard={paymentCard} />
                  </div>
                  <div className="col-md-4">
                    {/*<div className="gtotal-section">
                      <h3 className="p-derails-header">Price Details</h3>
                      <div>
                      <div class="w-100 mt-0"><button type="button" class="btn btn-secondary bgpinbutton position-relative min-same-widthxs w-100 text-center" disabled={!props.acceptTerms} onClick={onOrderClicked}>Place Order</button></div>
                      </div>
                    </div>*/}
                   
                  </div>

                </div>
                <div style={{ display: showFinalOrderListSection ? 'flex' : 'none' }} className="row">
                  <div className="col-md-8 pl-10-pr-10-mob">
                    <FinalOrderList />
                  </div>
                  <div className="col-md-4">
                    <div className="gtotal-section">
                      {/* <h3 className="p-derails-header">Price Details</h3> */}
                      <div>
                        <div><p className="addressbrands-secondallpages">Item Total<span className="float-right text-dark"><b>{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode), to2Decimal(props.checkoutDetails.totalamt))}</b></span></p></div>
                        <div><p className="addressbrands-secondallpages">Total Tax<span className="float-right text-dark"><b>{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode), to2Decimal(props.checkoutDetails.totaltaxamt))}</b></span></p></div>
                        <div><p className="addressbrands-secondallpages">Delivery Charges<span className="float-right text-dark"><b>{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode), to2Decimal(props.checkoutDetails.deliverychargeamt))}</b></span></p></div>
                        <div class="Subtotal border-top pt-2 mt-3"><span>Total Amount</span><span class="float-right font-bold">{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode), to2Decimal(props.checkoutDetails.grandtotalamt))}</span></div>

                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="shield-did d-inline-block"><i class="fa fa-shield" aria-hidden="true"></i> </div><div className="d-inline-block shild-txt">Safe and Secure Payments.Easy returns.100% Authentic products.</div>
                    </div>
                  </div>
                </div>
              </Col>

              <div className="col-md-8 text-right to-continue-button">
              <button id="Previous" className="back-primary-button min-same-widthxs pt-2 pb-2 mb-2 " style={{ display: showPreviousButton ? 'inline-block' : 'none' }} onClick={handleShowHideDivPreviousClick}>Back</button>
              <button id="Next" className="btn btn-secondary bgpinbutton position-relative min-same-widthxs pt-2 pb-2 mb-2 ml-3" style={{ display: showNextButton ? 'inline-block' : 'none' }} disabled={!DeliveryAddressSet} onClick={handleShowHideDivNextClick}>Continue</button>
            </div>
            </div>
           
            <Col className="col-md-4 mb-2 text-right mt-2">
            </Col>
          </Row>

        </Container>
        <Footer />
        <GenericModal />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  mobileState: state.CategoryData.isMobileMenu,
  valid_otp: state.AuthData.valid_otp,
  companyDetailsCurrency: state.HomeData.companyDetailsCurrency,
  checkoutDetails: state.CheckoutData.checkoutDetails,
  pin:state.UserData.pin,
  companyid: state.HomeData.companyid,
	pincode: state.LocationData.pin,
	totalAmount: state.CartData.totalAmount,
	cartList: state.CartData.cartList,
	checkoutDetails: state.CheckoutData.checkoutDetails,
	discountDetails: state.CheckoutData.discountDetails,
	selected_voucher: state.CheckoutData.selected_voucher,
	placeOrderData: selectPlaceOrderJSON(state),
	razorpay1: state.CheckoutData.razorpay1,
	razorpaysuccess: state.CheckoutData.razorpaysuccess,
	razorpayfailure: state.CheckoutData.razorpayfailure,
	order: state.CheckoutData.order,
	loginData: state.AuthData.loginData,
	termsandcondition: state.HomeData.termsncondnord,
	authenticated: state.AuthData.authenticated,
	companyDetailsCurrency: state.HomeData.companyDetailsCurrency,
  acceptTerms:state.CheckoutData.acceptTerms
});

export default connect(mapStateToProps, { setValidateOtp ,placeOrder,getDeliveryCharge, razorPay1func, razorpaysuccess_func, razorpayfailure_func, setRazorPayStatus, setModal, placeOrderCash, set_paymentmode, savePayment, setSelectedvoucher})(CheckOut);
