import React from "react";

import ImageUtil from '../utils/ImageUtil';

const ComingSoon = props => {
  return (
    <div className="empty-cart">
      {/* <img
        src={ImageUtil.getImage('empty.png')}
        alt="empty"
      /> */}
      {props.selectedProduct!=null && props.selectedProduct.length>0 ? null:<h2></h2>}
    </div>
  );
};

export default ComingSoon;