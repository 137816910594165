import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
/**
 * Creates a new MsgModal.
 * @class
 */

const DeleteModal = (props) => {  
    return (
        <React.Fragment>
            <Modal show={props.show} backdrop="static" onHide={props.closeAction} centered>
                <Modal.Header closeButton className='custom-modal-header pt-2 pb-2'>
                    <Modal.Title className='thanks-modtitle'>Delete Address</Modal.Title>
                </Modal.Header>
                <Modal.Body>
				{typeof props.message === 'object' 
                        ? <div>{props.message}</div>
                        : <div dangerouslySetInnerHTML={{__html:props.message}}/>
                    }
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

DeleteModal.propTypes = {
    show: PropTypes.bool.isRequired,
	closeAction: PropTypes.func
};

DeleteModal.defaultProps = {
    show: false
}

export default DeleteModal
