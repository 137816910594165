import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Container, Row, Col, Form, Navbar } from "react-bootstrap";
import {
  login,
  SigninByOthers,
  signup,
  set_phoneNumber,
  set_password,
  set_authenticated,
  setMsgModal,
  forgotPassword,
  sendForgotPasswordOtp,
  validateForgotPasswordOtp,
  set_login_head,
  WalletInfo,
  setUpdatedPhoneNumber,
} from "../../actions/AuthAction";
import { saveWishList, getWishListLogin, SaveUpdateWishCategory } from '../../actions/ProductsAction';
import { getCart, saveCart,setWishListNonLog } from "../../actions/CartAction";
import { clearLocation } from "../../actions/LocationAction";
import { getfeaturedItems } from "../../actions/ProductsAction";
import { set_phone } from "../../actions/UserAction";
import { sendOtpForLogin,setValidateOtp,validateOtp } from "../../actions/AuthAction";
import { Global } from "../../utils/Env";
import MsgModal from "../../container/MsgModal";
import ResendOtp from '../checkout/ResendOtp';
import Footer from "../../components/Footer";
import ImageUtil from "../../utils/ImageUtil";
import SignUpDetails from "../../container/SignUpDetails";
import PhoneValidate from "../../components/checkout/PhoneValidate";
import FacebookLogin from "react-facebook-login";
import { GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";

const Login = (props) => {
  const [phnCard, setPhnCard] = useState(true);
  const [redirectToLogin, setredirectToLogin] = useState(true);
  const [signInDis,setSignInDis]=useState(false);
  const [addressCard,setAddressCard]=useState(false);
  const [validated, setValidated] = useState(false);
  const [state, setState] = useState("OTPLogin");
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const [otp, setOtp] = useState(["", "", "", ""]);
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();
  var date = yyyy + '-' + mm + '-' + dd;
  let primaryColor=props.petTemplateDetails && props.petTemplateDetails.primaryColor?props.petTemplateDetails.primaryColor:'';
  const [otpError, setOtpError] = useState(false);
  const navigate = useNavigate();
  Global.callback.savecart_onComplete = () => {
    setSignInDis(false);
    props.getCart({
      companyid: props.companyid,
      customerid: parseInt(props.loginData.data.customerId),
    });
  };
  Global.callback.login_onComplete = () => {
    setSignInDis(false);
    if (props.loginData.data != null) {
      props.set_authenticated(true);
      props.set_password("");
      props.set_phone(props.loginData.data.phone);
      props.setValidateOtp({ "status": 1, "message": "Success", "data":{"isOTPVerified": true }});
      if (
        props.loginData.data.customerId != "" &&
        props.loginData.data.customerId != null &&
        props.loginData.data.userType == "b2b"
      ) {
        props.WalletInfo({
          CustomerId: parseInt(props.loginData.data.customerId),
        });
      }
      if (props.cartList.length > 0) {
        let items = [];
        props.cartList.map((item, i) => {
          items.push({
            itemId: item.id,
            itemVariantId: item.variantid ? item.variantid : 0,
            itemQty: item.quantity,
          });
        });
        props.saveCart({
          companyid: props.companyid,
          customerid: parseInt(props.loginData.data.customerId),
          userid: props.loginData.data.id,
          carttItemVariant: items,
        });
      } else
        props.getCart({
          companyid: props.companyid,
          customerid: parseInt(props.loginData.data.customerId),
        });
      props.getWishListLogin({ "companyid": props.companyid, "customerid": parseInt(props.loginData.data.customerId) });
      props.clearLocation();
    } else
      props.setMsgModal({
        title: "Error in login",
        message: "Error",
        show: true,
      });
  };
  Global.callback.login_onError = () => {
    setSignInDis(false);
    props.setMsgModal({
      title: "Error in login",
      message: "Some Error Occurred",
      show: true,
    });
  };
  Global.callback.getwishlistlogin_onComplete = () => {
    console.log("props.wishlist",props.wishlist);
    if(props.wishlist && props.wishlist.length>0 && props.wishlist.filter((item)=>item.wishListCategoryName=='Non Logged In').length>0)
    {
      let wishCat=props.wishlist.filter((item)=>item.wishListCategoryName=='Non Logged In')[0];
      props.wishListNonLog.map((wish)=>{
        let obj = {
          "companyid": props.companyid,
          "customerId": parseInt(props.loginData.data.customerId),
          "itemId": wish.itemId,
          "itemVariantId": wish.itemVariantId,
          "notifyOnArrival": wish.notifyOnArrival,
          "wishListCategoryId": wishCat.wishListCategoryId,
          "wishListCategoryName": wishCat.wishListCategoryName,
          "note": "",
          "date": date
      }
     
      props.saveWishList(obj);
      })
      props.setWishListNonLog([]);
      props.closeAction();
    }
    else
    {
      let obj = {
        "WishListCategoryId": 0,
        "WishListCategoryName": 'Non Logged In',
        "IsDefault": props.wishlist ? false : true,
        "Date": date,
        "customerId": parseInt(props.loginData.data.customerId),
        "companyId": props.loginData.data.companyId
      }
      props.SaveUpdateWishCategory(obj); 
    }
    
}
Global.callback.getWishList_onComplete = () => {
  var id = parseInt(props.wishlist[props.wishlist.length - 1].wishListCategoryId);
  var text = props.wishlist[props.wishlist.length - 1].wishListCategoryName;
  props.wishListNonLog.map((wish)=>{
    let obj = {
      "companyid": props.companyid,
      "customerId": parseInt(props.loginData.data.customerId),
      "itemId": wish.itemId,
      "itemVariantId": wish.itemVariantId,
      "notifyOnArrival": wish.notifyOnArrival,
      "wishListCategoryId": id,
      "wishListCategoryName": text,
      "note": "",
      "date": date
  }
 
  props.saveWishList(obj);
  })
  props.setWishListNonLog([]);
  props.closeAction();
  
}

  const onErrorLoginClose = () => {
    props.setMsgModal({
      title: "",
      message: "",
      show: false,
    });
    props.closeAction();
  };
  /** @function
   * @name isValidNumber */
  /** @function
   * @name handlePasswordChange */
  const handlePasswordChange = (e) => {
    props.set_password(e.target.value);
  };
  /** @function
   * @name handleClick */
  const handleClick = () => {
    if (validated && props.phone != "" && props.phone != "")
    {
      props.login({
        CompanyId: props.companyid,
        UserName: props.phone,
        Password: props.password,
      });
      setSignInDis(true);
    }
    else
      props.setMsgModal({
        title: "Error in login",
        message:
          "There is a validation error in the form. Please check the form Details",
        show: true,
      });
  };

  const validOtp = () => {
    if (otp.length === 4) {
      props.validateOtp({
        CompanyId: props.companyid,
        PhoneNo: props.phone,
        OTP: otp.join(""),
        PhoneCode:props.phoneCode,
        ipAddress:props.ipAddress
      });
      setOtp(["", "", "", ""]); // clear otp fields
      setSignInDis(true);
    }
  };

  
  useEffect(() => {
    //props.setValidateOtp({ status: 1, message: "Failed", valid: false });
    if (props.authenticated) {
      navigate("/", { replace: true });
    }
  }, []);

  const handleOtpChange = (e, i) => {
    let copyOtp = [...otp];
    if (e.target.value.length <= 1) {
      copyOtp[i] = e.target.value;
      setOtp(copyOtp);
    }
    if (otp[i] === "") {
      switch (i + 1) {
        case 1: {
          ref2.current.focus();
          break;
        }
        case 2: {
          ref3.current.focus();
          break;
        }
        case 3: {
          ref4.current.focus();
          break;
        }
        case 4: {
          ref5.current.focus();
          break;
        }
        default:
          return null;
      }
    }
  };
  
  const renderOtpField = () => {
    return (
      <Row className="only-otp-div">
        <p className="w-100 fs-14 mb-1">Enter Your OTP</p>
        <Col md="3 pl-0" xs="3">
          <input
            className="form-control p-0-5-tab br-radious-0"
            ref={ref1}
            value={otp[0]}
            onChange={(e) => handleOtpChange(e, 0)}
          />
        </Col>
        <Col md="3 pl-0" xs="3">
          <input
            className="form-control p-0-5-tab br-radious-0"
            ref={ref2}
            value={otp[1]}
            onChange={(e) => handleOtpChange(e, 1)}
          />
        </Col>
        <Col md="3 pl-0" xs="3">
          <input
            className="form-control p-0-5-tab br-radious-0"
            ref={ref3}
            value={otp[2]}
            onChange={(e) => handleOtpChange(e, 2)}
          />
        </Col>
        <Col md="3 pl-0" xs="3">
          <input
            className="form-control p-0-5-tab br-radious-0"
            ref={ref4}
            value={otp[3]}
            onChange={(e) => handleOtpChange(e, 3)}
          />
        </Col>
        {otpError && (
          <div className="text-danger font-12 mt-2">
            Error in otp... try again
          </div>
        )}
        <ResendOtp phonenumber={props.phone} phoneCodeValue={props.phoneCode} loginMode={true} />
      </Row>
    );
  };

  const loginViaOtp=()=>{
    props.sendOtpForLogin({CompanyId:props.companyid,PhoneNo:props.phone,PhoneCode:props.phoneCode,ipAddress:props.ipAddress})
  }
  Global.callback.sendOtp_onComplete = () => {
    setSignInDis(false);
    if(props.otpSendStatus.status && props.otpSendStatus.data && props.otpSendStatus.data.isOTPSent)
    setState('otp');
    else
    {
    setOtpError(true)
    props.setMsgModal({
        "title": "Error in Phone",
        "message": props.otpSendStatus.message,
        "show": true
    });
    }
}
  

  const onKeyDownHandler = (e) => {
    setValidated(true);
    if (e.keyCode === 13 && state == "login") {
      handleClick();
      e.preventDefault();
    }
  };

  const checkOtpButtonValidity = () => {
    if (otp[0] !== "" && otp[1] !== "" && otp[2] !== "" && otp[3] !== "") {
      return false;
    } else return true;
  };

  return (
    <div className="page-bodesection">
      {state == "login" ? (
        <div className="d-flex h-100 justify-content-center align-self-center flex-column">
          <Form validated={validated} onKeyDown={onKeyDownHandler}>
            <fieldset className="scheduler-border rounded">
              {/* <legend className="scheduler-border signin-label">Sign In</legend> */}

              <Form.Group controlId="formBasicEmail">
                <Form.Label className="fsize14 mb-1 google-colorstyle">
                  User Name
                </Form.Label>
                <Form.Control className="PhoneInputInput"
                  type="text"
                 
                  value={props.phone}
                  disabled={true}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label className="fsize14 mb-1 google-colorstyle">
                  Password
                </Form.Label>
                <Form.Control className="PhoneInputInput"
                  type="password"
                  
                  value={props.password}
                  onChange={handlePasswordChange}
                  required
                />
              </Form.Group>

              <div>
                <p className="font-weight-bold text-muted text-center">
                  <a
                    href="#"
                    className="font-weight-bold text-danger text-decoration-underline"
                  >
                    {/*<span onClick={signInDis?console.log("disabled"):()=>linkToOTPSignUp()}>
                      Get an OTP on your phone
                    </span>*/}
                  </a>
                </p>
              </div>
              <div>
                <Button
                  id="submit_test"
                  className="rounded-0 primary-button-nocolor w-100"
                  onClick={handleClick}
                  disabled={signInDis}
                  style={{backgroundColor:primaryColor}}
                >
                  SIGN IN
                </Button>{" "}
              </div>
              <p className="fsize12 font-weight-bold text-muted mb-0 mt-3">
                  Can't Login ?{""}
                  <a
                    href="#"
                    className="fsize13 font-weight-bold text-danger text-decoration-underline"
                  >
                    <span className="d-block-mob" onClick={signInDis?console.log("disabled"):()=>loginViaOtp()}>Login Via OTP</span>
                  </a>
                </p>
            </fieldset>
          </Form>
          <div className="">
              <div className="col-md-6 col-6 text-left">
                
              </div>
            </div>
        </div>
      ) : null}
      {state == "signup" ? <SignUpDetails /> : null}
      {state == "otp" ? (
        <>
          {renderOtpField()}
          <div className="text-right pl-4 pr-4 pb-3">
            <Button
              ref={ref5}
              onClick={validOtp}
              disabled={checkOtpButtonValidity() || signInDis}
              className="rounded-0 primary-button-nocolor w-100"
              style={{backgroundColor:primaryColor}}
            >
              CONTINUE
            </Button>
          </div>
        </>
      ) : null}
      {state == "OTPLogin" ? (
        <div className="ph-val" id="popupID">
          <PhoneValidate
            setAddressCard={setAddressCard}
            phnCard={phnCard}
            setPhnCard={setPhnCard}
            redirectToLogin={redirectToLogin}
            closeAction={onErrorLoginClose}
            setParentState={(e)=>setState(e)}
          />
          
        </div>
      ) : null}

      {!props.authenticated && props.modal && props.modal.show && (
        <MsgModal
          show={props.modal.show}
          title={props.modal.title}
          message={props.modal.message}
          closeAction={onErrorLoginClose}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  phoneNumber: state.AuthData.phoneNumber,
  password: state.AuthData.password,
  authenticated: state.AuthData.authenticated,
  loginData: state.AuthData.loginData,
  modal: state.AuthData.modal,
  cartList: state.CartData.cartList,
  companyid: state.HomeData.companyid,
  forgotpassworddata: state.AuthData.forgotpassworddata,
  sendforgotpasswordotpdata: state.AuthData.sendforgotpasswordotpdata,
  validateforgotpasswordotpdata: state.AuthData.validateforgotpasswordotpdata,
  authData: state.AuthData,
  companyDetailsCurrency: state.HomeData.companyDetailsCurrency,
  wishListNonLog:state.CartData.wishListNonLog,
  wishlist: state.ProductsData.wishlist,
  phone:state.UserData.phone,
  phoneCountryCode: state.UserData.phoneCountryCode,
  phoneCode:state.UserData.phoneCode,
  otpSendStatus:state.AuthData.otpSendStatus,
  petTemplateDetails: state.petProductData.userP_AllProductInfo.data,
  ipAddress:state.AuthData.ipAddress
});

export default connect(mapStateToProps, {
  setMsgModal,
  getfeaturedItems,
  login,
  SigninByOthers,
  signup,
  set_phoneNumber,
  set_password,
  set_authenticated,
  set_phone,
  setValidateOtp,
  clearLocation,
  getCart,
  saveCart,
  forgotPassword,
  sendForgotPasswordOtp,
  validateForgotPasswordOtp,
  set_login_head,
  WalletInfo,
  setUpdatedPhoneNumber,
  saveWishList, 
  getWishListLogin, 
  SaveUpdateWishCategory,
  setWishListNonLog,
  validateOtp,
  sendOtpForLogin
})(Login);
