import React, { } from 'react';
import { connect } from 'react-redux';
import {Form,Button} from 'react-bootstrap';

/**
 * Creates a new OrderedItemTable.
 * @class
 */
const CancelReason = props => {

    let primaryColor=props.petTemplateDetails && props.petTemplateDetails.primaryColor?props.petTemplateDetails.primaryColor:'';
	
		return(
			<div>
				{props.cancelReasonData && props.cancelReasonData.length>0?
				<Form.Group>
				<Form.Label className="form-group-labels">Cancel Reason</Form.Label>
				<Form.Control id="addtype" value={props.cancelReason} onChange={(e)=>props.setCancelReason(e.target.value)}
					as="select" > 
					<option key={"key"} value={null}>Cancel Reason</option>
					 {props.cancelReasonData.map(item => {
						return (
							<option key={item.cancellationReasonId} value={item.cancellationReasonId}>{item.reasonName}</option>
						)
					})}
				</Form.Control>
				{props.cancelReasonData.filter((c)=>c.explanationRequired).length>0?
				<div>
				<Form.Label className="form-group-labels">Further Reason for Cancellation</Form.Label>
                <Form.Control placeholder="Reasons" value={props.cancelExplanation}
                name="reason" htmlSize={6} onChange={(e)=>props.setCancelExplanation(e.target.value)} />
				</div>:''}
				<Button style={{backgroundColor:primaryColor}} className='primary-button-nocolor min-same-widthxs' onClick={()=>props.cancelItem()}>
                        Cancel Item
                    </Button>
			</Form.Group>:''
				}	
			</div>
		);
}
 
const mapStateToProps = state => ({
   cancelReasonData: state.UserData.cancelReasonData,
   petTemplateDetails: state.petProductData.userP_AllProductInfo.data,
})

export default connect(mapStateToProps,{})(CancelReason);
