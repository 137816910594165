import React, {useState} from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Row, Col, Card, Form, Container } from 'react-bootstrap';
import {setPromoApplied, setPromModal,cleanDiscount,selectCheckOutJSON,setPromoCode,checkOutOrder,getDeliveryCharge } from '../../actions/CheckoutAction';
import { hasProps } from '../../utils/CommonUtil';
import { Global } from '../../utils/Env';

const PromoModal = (props) => {

    const handleClose = () => {
        props.setPromModal(false)
    }
    const handleOpen = () => {
        props.setPromModal(true)
    }
    
    const onPromoClicked = param => e => {
        props.setPromoCode(param.code);
    }

    const handleApply = (event) => {
        event.preventDefault()
        props.setPromoApplied(true);
        props.checkOutOrder(props.checkoutData);
    }
	Global.callback.checkOutOrder_onComplete = () => {
		props.getDeliveryCharge({
            "totalAmount": props.checkoutDetails.totalamt,
            "taxAmount": props.checkoutDetails.totaltaxamt,
            "netAmount": props.checkoutDetails.grandtotalamt,
            "zipCode": props.pin,
            "companyId": props.companyid
        })
	}
	const handleClear = () =>{
		props.cleanDiscount();
	}

    let primaryColor=props.petTemplateDetails && props.petTemplateDetails.primaryColor?props.petTemplateDetails.primaryColor:'';
    let secondaryColor=props.petTemplateDetails && props.petTemplateDetails.secondaryColor?props.petTemplateDetails.secondaryColor:'';
    let tertiaryColor=props.petTemplateDetails && props.petTemplateDetails.tertiaryColor?props.petTemplateDetails.tertiaryColor:'';


    return (
        <React.Fragment>
            <button className="w-100 back-primary-button min-same-widthxs pt-2 pb-2 mb-2 " onClick={handleOpen}>Promo Codes</button>
            <Modal show={props.promoModal} onHide={handleClose}  centered>
                <Modal.Header closeButton className="pt-1 pb-1 text-white cl-btn-white custom-modal-header">
                    <h5>Apply Promo</h5>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                    <Form.Row>
                        <Form.Group as={Col}  md={9} xs={8}>
                            <Form.Control disabled={props.promoApplied} placeholder="Enter Promo Code" value={props.promocode}
                            onChange={(e) => props.setPromoCode(e.target.value)}/>
                            {props.promoApplied && props.checkoutDetails && props.checkoutDetails.vouchers?<div style={{fontStyle:'italic',fontSize:'14px',marginTop:'8px'}}>{props.checkoutDetails.vouchers.msg}</div>:null}
                        </Form.Group>
                        <Form.Group as={Col} md={3} xs={4} className="text-right">
                            <Button disabled={props.promocode.length && !props.promoApplied ? false : true} type="submit" style={{backgroundColor:primaryColor}}  className='primary-button-nocolor min-same-widthxs pt-2 pb-2 mb-2 ' onClick={(e)=>handleApply(e)}>Apply</Button>
								{props.promoApplied? <a className="clear_discount" onClick={()=>handleClear()}>Clear Discount</a>:null}
                        </Form.Group>
                    </Form.Row>
                    </Form>
                    {hasProps(props, 'vouchers') ? <div>
                        {<Row>
                            <p className="mb-1 fs-14"><b>Click a Promo Code</b></p>
                        </Row>}
                        <Row>
                            {props.checkoutDetails.vouchers.map(item => (
                                <Col md={12} key={item.id} className="p-0 pb-1">
                                    
                                    <Card className="p-1 mb-1 bg-dark text-white" style={{cursor:"pointer"}}  >
                                        <button style={{backgroundColor:primaryColor}} onClick={onPromoClicked(item)} >{item.code} - {item.description}<br/>
                                        <p className="font-12 text-dark">{item.minimumamount}</p></button>
                                    </Card>
                                </Col>)
                            )}
                           
                        </Row>
                    </div> : null}
                </Modal.Body>
                {/* <Modal.Footer>
                </Modal.Footer> */}
            </Modal>
        </React.Fragment>
    )
}
const mapStateToProps = state => ({
    checkoutDetails: state.CheckoutData.checkoutDetails,
    companyid:state.HomeData.companyid,
    promoModal: state.CheckoutData.promoModal,
    checkoutData: selectCheckOutJSON(state),
    petTemplateDetails: state.petProductData.userP_AllProductInfo.data,
    promocode:state.CheckoutData.promoCode,
    promoApplied:state.CheckoutData.promoApplied,
    pin:state.UserData.pin
})
export default connect(mapStateToProps, {setPromModal,cleanDiscount,setPromoCode,checkOutOrder,setPromoApplied,getDeliveryCharge})(PromoModal);