import React, {  } from 'react';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';

const CancelPolicy = props => {
	
	

    return (
	<React.Fragment>
	<h1 className="aboutus-head">
        <b>Cancellation / Refund Policy</b></h1>
        {props.footerData && props.footerData.cancellation_RefundPolicyDtl && props.footerData.cancellation_RefundPolicyDtl.showCancellation_RefundPolicy?props.footerData.cancellation_RefundPolicyDtl.isHtml?
    <div className="product_details_cont" dangerouslySetInnerHTML={{ __html: props.footerData.cancellation_RefundPolicyDtl.cancellation_RefundPolicy }} />:'':''}
           
		</React.Fragment>
    );
}

const mapStateToProps = state => ({
	footerData:state.HomeData.footer
})

export default connect(mapStateToProps, {})(CancelPolicy);
