import React, { useState,useEffect } from "react";
import ImageUtil from '../../utils/ImageUtil';
import Nav from 'react-bootstrap/Nav';
import { connect } from "react-redux";
import {NavDropdown} from 'react-bootstrap';
import { Global } from '../../utils/Env';
import Loading from '../Loading'

const Category = props => {
	const [loading, setLoading] = useState(false);
	
	const in_category=(obj) =>
	{
		let test=false;
		if( obj !== null && typeof obj == "object" ) {
			Object.entries(obj).forEach(([key, value]) => {
				if((value instanceof Array) && value.length>0)
					test=value;
			});
		}
		return (test)?test:false;
	}
	
	const in_category_name=(obj) =>
	{
		let test=false;
		if( obj !== null && typeof obj == "object" ) {
			Object.entries(obj).forEach(([key, value]) => {
				if((value instanceof Array)  && value.length>0)
					test=key;
			});
		}
		return (test)?test:false;
	} 

  const getIcon = icon => {
	  const css={width:'15px',height:'15px'}
    return (
      <label className="mb-0 d-none">
        <img className={loading? "icon-image hidden-xs hidden-sm":"hide"} alt={icon} src={ImageUtil.getImage(icon)} onLoad={() =>setLoading(true)} />
			{(loading)?<span>&nbsp;&nbsp;</span>:null}
			{(loading)?null:<Loading css={css} />}
      </label>
    ) 
  }
  
  

 const traverse_sub =(obj,parent1) =>
  {
	  let content=[];
	  let obj1=in_category(obj);
	  let name=in_category_name(obj);
	  for(var i=0;i<obj1.length;i++)
			{
			let category=obj1[i];
			if(in_category(category))
				content=[content,(traverse_categories(category,parent1.concat(' '),name))];
			else
				content=[content,(<NavDropdown.Item className="navitem" key={category.name} eventKey={(category.name.toLowerCase()=='all')?(parent1.split(' ')[0]):(parent1.concat(' ').concat(name).concat(':').concat(category.id))} > {category.name} </NavDropdown.Item>)];
			}
	return (
		<React.Fragment>
        { content }
		</React.Fragment>
    );	
  }
	 

   const traverse_categories = (obj,parent1,name) => 
  {
	  let content=[];
	  let name1=(name==' ')?("category"):(name);
	  let title=<React.Fragment>{obj.icon && getIcon(obj.icon)}{obj.name}</React.Fragment>;
	  if(in_category(obj))
	  		content=[content,(<NavDropdown key={obj.name}  className={"subcategory_dropdown ".concat("level ").concat(in_category_name(obj))} drop={(name==' ' || props.isMobileMenu)?"down":"right"}   title={title} id="nav-dropdown"> {traverse_sub(obj,parent1.concat(name1).concat(":").concat(obj.id))}</NavDropdown>)];
		else
			content=[content,(<Nav.Link key={parent1.concat(name1).concat(":").concat(obj.id)} className="category_root" eventKey={parent1.concat(name1).concat(":").concat(obj.id)}  >{obj.icon && getIcon(obj.icon)}{obj.name} </Nav.Link>)];
	return (
        <div key={"traverse ".concat(props.category.name)} >
        { content }
      </div>
    );
  }

  return (
	  <React.Fragment>
    <Nav.Item key={props.keyValue} >
		{traverse_categories(props.category,'',' ')}
    </Nav.Item>
	</React.Fragment>
  );
};
const mapStateToProps = state => ({
  selectedCategory: state.CategoryData.selectedCategory,
  selectedsubsubCategory: state.CategoryData.selectedsubsubCategory,
  isMobileMenu: state.CategoryData.isMobileMenu,
  menuState: state.CategoryData.menuState
})
export default connect(mapStateToProps)(Category);