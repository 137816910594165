import React from "react";

import ImageUtil from '../utils/ImageUtil';

const EmptyCart = props => {
  return (
    <div className="empty-cart">
      {/* <img
        src={ImageUtil.getImage('empty-cart.png')}
        alt="empty-cart"
      /> */}
      <h2 className="pt-5 pb-5 text-danger bg-white">You cart is empty!</h2>
    </div>
  );
};

export default EmptyCart;
