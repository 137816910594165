
import {apiAction} from '../middleware/api/apiActions';
import API from './APIConst';


// Description on const CATEGORYLIST
const TOGGLE_MENU_STATE = 'TOGGLE_MENU_STATE';
const SET_MENU_STATE = 'SET_MENU_STATE';
const SET_MOBILE_MENU = 'SET_MOBILE_MENU';
const CATEGORY_LIST = "CATEGORY_LIST";
const CATEGORY_SELECTED = "SELECTED_CATEGORY";
const CATEGORY_CLEARED = "CATEGORY_CLEARED";
const SUB_CATEGORY_SELECTED = "SUB_SELECTED_CATEGORY";

const SUB_CATEGORY_CLEARED="SUB_CATEGORY_CLEARED";

const SUB_SUB_CATEGORY_SELECTED = "SUB_SUB_SELECTED_CATEGORY";

const SUB_SUB_CATEGORY_CLEARED="SUB_SUB_CATEGORY_CLEARED";
//.....................................
/**
 * Action getCategoryList defined.
 * @constructor
 * @param {string} param - Pass param to update the payload.
 */ 
export const getCategoryList = (cid) => {
    return apiAction({
        url: `${API.GET_CATEGORY}${cid}`,
		method:"post",
        onSuccess: setCategoryList,
        onFailure: () => console.log("Error occured in getCategoryList"),
        label: CATEGORY_LIST
      });
}

export const setCategoryList = param => ({
    type: CATEGORY_LIST,
    payload: param.data
  });

//...................................................................
/**
 * Action selectCategory defined.
 * @constructor
 * @param {string} param - Pass param to update the payload.
 */  
export const selectCategory = param => ({
    type: CATEGORY_SELECTED,
    payload: param
  });
  
   export const selectsubCategory = param => ({
    type: SUB_CATEGORY_SELECTED,
    payload: param
  });
  
  export const selectsubsubCategory = param => ({
    type: SUB_SUB_CATEGORY_SELECTED,
    payload: param
  });

//...................................................................
/**
 * Action clearCategory defined.
 * @constructor
 */  
export const clearCategory = () => ({
  type: CATEGORY_CLEARED
});

//...................................................................
/**
 * Action toggleMenuState defined.
 * @constructor
 *
 */  
export const toggleMenuState = () => ({
  type: TOGGLE_MENU_STATE,
});

export const clearsubCategory = () => ({
  type: SUB_CATEGORY_CLEARED
});

export const clearsubsubCategory = () => ({
  type: SUB_SUB_CATEGORY_CLEARED
});


//...................................................................
/**
 * Action setMenuState defined.
 * @constructor
 * @param
 */  
export const setMenuState = (param) => ({
  type: SET_MENU_STATE,
  payload:param
});

//...................................................................
/**
 * Action setMobileMenu defined.
 * @constructor
 * @param
 */  
export const setMobileState = (param) => ({
  type: SET_MOBILE_MENU,
  payload:param
});

//...................................................................
/**
 * set up with initial state.
 */
const initialState = {
    categoryList: [],
    selectedCategory: -1,
    menuState:true,
    isMobileMenu: false,
    selectedsubsubCategory: '',
    categoryType:3
}
    
/**
 * reducer .
 * @constructor
 * @param {string} state - state of the data shape.
 * @param {string} action - action payload passed for data updates.
 */ 
let reducer = (state = initialState, action) => {
	switch(action.type) {
        case CATEGORY_LIST: return {...state, categoryList: action.payload.categories,categoryType:action.payload.menuOption }
        case CATEGORY_SELECTED: return {...state, selectedCategory: action.payload }
        case CATEGORY_CLEARED: return {...state, selectedCategory: -1}
        case TOGGLE_MENU_STATE: return {...state, menuState: !state.menuState }
        case SET_MENU_STATE : return { ...state, menuState: action.payload }
        case SET_MOBILE_MENU :  return { ...state, isMobileMenu: action.payload }
		case SUB_CATEGORY_SELECTED: return {...state, selectedsubCategory: action.payload}
		case SUB_CATEGORY_CLEARED: return {...state, selectedsubCategory: -1}
		case SUB_SUB_CATEGORY_SELECTED: return {...state, selectedsubsubCategory: action.payload}
		case SUB_SUB_CATEGORY_CLEARED: return {...state, selectedsubsubCategory: -1}
        default: return state;
	}
}

//...................................................................
//.. selectors :: TODO


//...................................................................
/**
 * Export Object (Action + Reducer)
 */
const CategoryAction = {
    reducer:reducer,
    actions:{
        getCategoryList, 
        selectCategory,
        toggleMenuState,
        setMenuState,
        setMobileState,
        clearCategory
    }
}

export default CategoryAction
 