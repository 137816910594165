import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.css';

import { Row, Col, Container, Navbar, Nav } from 'react-bootstrap';

const BestSellersComponent = props => {


    return (
        <React.Fragment>
		  <div className="each-section bestselling-discount">
                    <Container>
                        <div className="row">
                            <div className="col-md-6">
                                <h2 className="section-header text-white">{props.primaryText}</h2>
                            </div>
                            <div className="col-md-6 text-right text-white">
                                <a href="#" className="viewall-link text-white">Viev All <span className="double-arrow">&#187;</span></a>
                            </div>
                        </div>
                        <Row>
						{props.imageData?props.imageData.map((item)=>{
							return(
                            <Col>
                                <div>
                                    <div class="card">
                                        <div className="bestselling-images">
                                            <img class="card-img-top" src={item.imagePath} alt="Card image" alt="Card image" />
                                        </div>
                                        <div class="card-body text-center">
                                            <p class="product-category-name">{props.secondaryText}</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
							)
						}):''
						}
                        </Row>
                    </Container>
                </div>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    
})

export default connect(mapStateToProps, { })(BestSellersComponent);
