import React, {  } from 'react';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';


const TermsCondition = props => {
	
	

    return (
	<React.Fragment>
        <Container className='mt-5 mb-5'>
	<h1 className="aboutus-head"><b>Terms & Conditions</b></h1>
	<div className="entry clr aboutus-body" itemProp="text">
    {props.footerData && props.footerData.termsAndConditionsDtl && props.footerData.termsAndConditionsDtl.showTermsAndConditions?props.footerData.termsAndConditionsDtl.isHtml?
    <div className="product_details_cont" dangerouslySetInnerHTML={{ __html: props.footerData.termsAndConditionsDtl.termsAndConditions }} />:'':''}
      </div>
      </Container>
	 	</React.Fragment>
    );
}

const mapStateToProps = state => ({
    footerData:state.HomeData.footer	
})

export default connect(mapStateToProps, {})(TermsCondition);
