import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ImageUtil from "../utils/ImageUtil";
import { addQuantity, subtractQuantity, addToCart, setCartBounce,saveCart,getCart,saveCartWithoutloging } from "../actions/CartAction";
import { connect } from "react-redux";
import {Global} from '../utils/Env';
import {setLoginModal,set_phoneNumber, set_password} from '../actions/AuthAction';
import PhoneNumberAdd from "./PhoneNumberAdd";
import { toast } from "react-toastify";

const Counter = props => {
  const [state, setState] = useState("");
  const [interv, setInterv] = useState(null);
  const [minusClick, setMinus]= useState(false);
  const { product } = props;
  const cart = props.cartList
let index = (product.variantid)?cart.findIndex(x => x.id===product.id && x.variantid === product.variantid):cart.findIndex(x => x.id===product.id);
  
  Global.callback.savecart_onComplete = () => {
		props.getCart({"companyid":props.companyid,"customerid":parseInt(props.loginData.data.customerId)});
	}

  const findProductQnty = () => {
    let quantity = 0;
    if (index > -1) {
      quantity = cart[index].quantity
    }
    return quantity;
  }
  
  const createobj=(prod,str)=>{
	  let obj={
		  "companyid":props.companyid,
		  "customerid":parseInt(props.loginData.data.customerId),
		  "userid":props.loginData.data.id,
		  "carttItemVariant": [{
			  "itemId": prod.id,
			  "itemVariantId": (prod.variantid)?prod.variantid:0,
			  "itemQty":(str=="add"?1:(str=="plus"?++prod.quantity:(str=="minus"?--prod.quantity:0)))
		}]
	}
	return obj;
  }

  const getTotalAmount = (x) => {
    let totalAmount = 0
    for (let i = 0; i < x.length; i++) {
      let price = (x[i].promo_price ? x[i].promo_price : x[i].price_regular)
      totalAmount += price * parseInt(x[i].quantity);
    }
    return totalAmount
  }
  const addToCart = () => {
    if(minusClick==false)
    {
      //console.log("inside cart");
    if(props.authenticated)
    {
    if((props.loginData && props.loginData.data && props.loginData.data.phone !="") || props.updatedUserPhoneNumber !="")
    {
    let cart = [...props.cartList];
    cart.push({ ...product, quantity: 1 });
    let totalAmount = getTotalAmount(cart)
    props.addToCart({ cart: cart, totalAmount: totalAmount });
    props.saveCart(createobj(product,"add"));
    props.setLoginModal(false);
    toast.info("Item added to cart");
    }
    else
    {
      //console.log("deep_in_else_part");
    setState("PhoneReInsert");
    }
    }
    else{
      //console.log(props.cartList)
      let cart = [...props.cartList];
      cart.push({ ...product, quantity: 1 });
      let totalAmount = getTotalAmount(cart)
      props.addToCart({ cart: cart, totalAmount: totalAmount });
      //obj.push(...props.product,{"quantity":1});
      //console.log("inside Cart1",obj);
     // props.saveCartWithoutloging(obj);
    }
    }
    setMinus(false);
	// if(props.authenticated)
	// 	props.saveCart(createobj(product,"add"));
    setTimeout(() => props.setCartBounce(false), 1000)
  }
  const add = (ob) => {
    props.addQuantity(ob);
  }


  const clear = () => {
    clearInterval(interv);
  }

  const minus = (ob) => {
    props.subtractQuantity(ob);
  }

  const handlePlus = () => {
    if(props.authenticated)
    {
    let cart1 = [...props.cartList];
	let save=JSON.parse(JSON.stringify(cart1[index]));
    let item = { ...cart1[index], quantity: cart1[index].quantity + 1}
    cart1[index] = item;
    let totalAmount = getTotalAmount(cart1);
    add({ cart: cart1, totalAmount: totalAmount });
	//if(props.authenticated)
		props.saveCart(createobj(save,"plus"));
    props.setLoginModal(false);
    }
    else
    {
    let cart1 = [...props.cartList];
	  let save=JSON.parse(JSON.stringify(cart1[index]));
    let item = { ...cart1[index], quantity: cart1[index].quantity + 1}
    cart1[index] = item;
    let totalAmount = getTotalAmount(cart1);
    //console.log("cart1",cart1);
    props.addToCart({ cart: cart1, totalAmount: totalAmount });
    }
  }

  const handleMinus = () => {
    setMinus(true);
    //console.log("inside minus");
    if(props.authenticated)
    {
    let cart1 = [...props.cartList];
	let save=JSON.parse(JSON.stringify(cart1[index]));
    if (cart1[index].quantity > 1) {
      let item = { ...cart1[index], quantity: cart1[index].quantity - 1 }
      cart1[index] = item;
    }
    else
      cart1.splice(index, 1);
    let totalAmount = getTotalAmount(cart1);
    minus({ cart: cart1, totalAmount: totalAmount });
	//if(props.authenticated)
		props.saveCart(createobj(save,"minus"));
    props.setLoginModal(false);
  }
  else
  {
    let cart1 = [...props.cartList];
	let save=JSON.parse(JSON.stringify(cart1[index]));
    if (cart1[index].quantity > 1) {
      let item = { ...cart1[index], quantity: cart1[index].quantity - 1 }
      cart1[index] = item;
    }
    else
      cart1.splice(index, 1);
    let totalAmount = getTotalAmount(cart1);
    minus({ cart: cart1, totalAmount: totalAmount });
    props.addToCart({ cart: cart1, totalAmount: totalAmount });
  }
  }
  
  const closeAction = () => {
    props.setLoginModal(false);
    props.set_phoneNumber('');
    props.set_password('');
}
const closeActionPhone = () => {
  setState("");
}
  let quantity = findProductQnty()
  if (quantity > 0) {
    return (
      <div className={"stepperc-input checkoutstepperc-input"} style={{ display: quantity > 0 ? "flex" : "none" }}>
        {/* eslint-disable-next-line */}
        <a className="decrement" onClick={() => handleMinus()} >
          {quantity >= 1 ? <i className="fa fa-minus font-8" aria-hidden="true"></i> : <img alt="" src={ImageUtil.getImage("delete.svg")} />}
        </a>
        <input
          type="number"
          className="quantity"
          readOnly={true}
          value={quantity}
        />
        {/* eslint-disable-next-line */}
        <a className="increment" onClick={() => handlePlus()} >
          +
        </a>
      </div>
    );
  }
  else {
    clear();
     return (
      <React.Fragment>
     <button className="primary-button min-same-width" onClick={()=>addToCart()}>Add to cart</button>
     {(state == "PhoneReInsert") ? (
      <PhoneNumberAdd showValue={true} closeAction={closeActionPhone}/>
         ) : null}
        </React.Fragment>
     )
  }
}

Counter.propTypes = {
  value: PropTypes.number
};
const mapStateToProps = state => ({
  companyid:state.HomeData.companyid,
  cartList: state.CartData.cartList,
  loginData: state.AuthData.loginData,
  authenticated: state.AuthData.authenticated,
  loginhead:state.AuthData.loginhead,
  updatedUserPhoneNumber:state.AuthData.updatedUserPhoneNumber
});
export default connect(mapStateToProps, { addQuantity, subtractQuantity, addToCart, setCartBounce,saveCart,saveCartWithoutloging,getCart,setLoginModal,set_phoneNumber, set_password })(Counter);
