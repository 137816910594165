  
  import React, { useEffect } from 'react';
  import { Container, Row } from 'react-bootstrap';
  import { connect } from 'react-redux';
  import { useNavigate,Link } from 'react-router-dom';
  import {setProfoption } from '../../actions/UserAction';
  
  const Announcement = props => {
    //console.log("hello",props)
    const navigate = useNavigate();
    return (
      <>
      {props.company?.announcementText?
              <div className='announcement d-none' style={{textAlign: 'center',backgroundColor:props.company.announcementBackgroundColor}} >
              <p style={{color:props.company.announcementTextColor}}><div dangerouslySetInnerHTML={{ __html:props.company.announcementText}}/></p>
            </div>:null}
        </>
    )
  }
  
  const mapStateToProps = state => ({
    company: state.HomeData.companyDetailsCurrency,
    footerData: state.HomeData.footer
  })
  
  export default connect(mapStateToProps,{setProfoption})(Announcement);