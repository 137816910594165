import {apiAction} from '../middleware/api/apiActions';
import API from './APIConst';

/**
 * Constants defination 
 */
const USER_FIRST_NAME = "USER_FIRST_NAME";
const USER_LAST_NAME = "USER_LAST_NAME";
const USER_MIDDLE_NAME="USER_MIDDLE_NAME";
const USER_PHONE = "USER_PHONE";
const USER_EMAIL = "USER_EMAIL";
const USER_ADDRESS_1 = "USER_ADDRESS_1";
const USER_ADDRESS_2 = "USER_ADDRESS_2";
const USER_PINCODE = "USER_PINCODE";
const USER_CITY = "USER_CITY";
const USER_DISTRICT = "USER_DISTRICT";
const USER_STATE = "USER_STATE";
const USER_COUNTRY = "USER_COUNTRY";
const ADDRESS_TYPE = "ADDRESS_TYPE";
const ADDRESS_ID = "ADDRESS_ID";
const CUSTOMER_DETAILS = "CUSTOMER_DETAILS";
const CLEAR_DETAILS = "CLEAR_DETAILS";
const NOTIFICATION = "NOTIFICATION";
const GENERIC_MODAL = 'GENERIC_MODAL';
const CUSTOMER_PROFILE='CUSTOMER_PROFILE';
const SAVE_PROFILE='SAVE_PROFILE';
const ORDER_HISTORY='ORDER_HISTORY';
const PROFILE_OPTION='PROFILE_OPTION';
const USER_PHONECOUNTRYCODE='USER_PHONECOUNTRYCODE';
const USER_PHONECODE='USER_PHONECODE';
const CREATE_CANCEL_REQUEST = 'CREATE_CANCEL_REQUEST';
const GET_CANCEL_REASON = 'GET_CANCEL_REASON';

/**
 * Actions defination
 * @constructor
 * @param {*} param 
 */
export const set_fname = (param) => ({ type: USER_FIRST_NAME, payload: param })
export const set_lname = (param) => ({ type: USER_LAST_NAME, payload: param })
export const set_mname = (param) => ({ type: USER_MIDDLE_NAME, payload: param })
export const set_phone = (param) => ({ type: USER_PHONE, payload: param })
export const set_email = (param) => ({ type: USER_EMAIL, payload: param })
export const set_address1 = (param) => ({ type: USER_ADDRESS_1, payload: param })
export const set_address2 = (param) => ({ type: USER_ADDRESS_2, payload: param })
export const set_pin = (param) => ({ type: USER_PINCODE, payload: param })
export const set_city = (param) => ({ type: USER_CITY, payload: param })
export const set_dist = (param) => ({ type: USER_DISTRICT, payload: param })
export const set_state = (param) => ({ type: USER_STATE, payload: param })
export const set_country = (param) => ({ type: USER_COUNTRY, payload: param })
export const set_addressType = (param) => ({ type: ADDRESS_TYPE, payload: param })
export const set_addressId = (param) => ({ type: ADDRESS_ID, payload: param})
export const clear_userDetails = () => ({ type: CLEAR_DETAILS })
export const setNotify = (param) => ({ type: NOTIFICATION, payload: param})
export const setProfoption = (param) => ({ type: PROFILE_OPTION, payload: param})
export const set_phoneCountryCode = (param) => ({ type: USER_PHONECOUNTRYCODE, payload: param })
export const set_phoneCode = (param) => ({ type: USER_PHONECODE, payload: param })
export const setCancelData = (param) => ({type:CREATE_CANCEL_REQUEST, payload: param})
export const setCancelReasonData = (param) => ({type:GET_CANCEL_REASON, payload: param.data})

export const setMsgModal = (param) => ({ 
    type: GENERIC_MODAL,
    payload: param
});
/**
 * Action getCustomerDetails defined.
 * @constructor
 * @param {string} param - Pass param to update the payload.
 */ 
export const getCustomerDetails = (obj) => {
    return apiAction({
        url: API.GET_CUSTOMER_DETAILS+`CompanyId=${obj.companyid}&customerid=${obj.phone}`, 
		method:"post",
        onSuccess: setCustomerDetails,
        onFailure: () => console.log("Error occured in getCustomerDetails"),
        callback: 'getCustomerDetails',
        label: CUSTOMER_DETAILS
      });
}

export const getCustomerProfile = (obj) => {
    return apiAction({
        url: API.GET_USER_ALL_DETAILS,
        method: "post",
        data: obj,
        onSuccess: setCustomerProfile,
        onFailure: () => console.log("Error occured in get customer profile"),
        callback: 'getprofile',
        label: CUSTOMER_PROFILE
    });
}

export const cancelOrderItem = (obj) => {
    return apiAction({
        url: API.CREATE_CANCEL_REQUEST,
        method: "post",
        data: obj,
        onSuccess: setCancelData,
        onFailure: () => console.log("Error occured in get cancelOrderItem"),
        label: CREATE_CANCEL_REQUEST,
        callback:'cancelorderdata'
    });
}

export const getCancelReason = (obj) => {
    return apiAction({
        url: API.GET_CANCEL_REASON,
        method: "post",
        data: obj,
        onSuccess: setCancelReasonData,
        onFailure: () => console.log("Error occured in get getCancelReason"),
        label: GET_CANCEL_REASON
    });
}

export const saveCustomerProfile = (obj) => {
    return apiAction({
        url: API.SAVE_PROFILE,
        method: "post",
        data: obj,
        onSuccess: saveProfile,
        onFailure: () => console.log("Error occured in save profile"),
        callback: 'saveprofile',
        label: SAVE_PROFILE
    });
}

export const getOrderHistory = (obj) => {
    return apiAction({
        url: API.ORDER_HISTORY,
        method: "post",
        data: obj,
        onSuccess: saveOrderHistory,
        onFailure: () => console.log("Error occured in order history"),
        label: ORDER_HISTORY
    });
}

export const saveOrderHistory = param => {
    return {
        type: ORDER_HISTORY,
        payload: param
    }
  };



export const saveProfile = param => {
    return {
        type: SAVE_PROFILE,
        payload: param
    }
  };

export const setCustomerProfile = param => {
    return {
        type: CUSTOMER_PROFILE,
        payload: param
    }
  };


export const setCustomerDetails = param => {
    return {
        type: CUSTOMER_DETAILS,
        payload: param
    }
  };

/**
 * set up with initial state.
 */
const initialState = {
	notification: false,
    addrid: '',
    fname: '',
    lname: '',
	mname: '',
    phone: '',
    email: '',
    address1: '',
    address2: '',
    pin: '',
    city: '',
    dist: '',
    state: '',
    country: '',
    type: -1,
    addressTypes: [],
    customerDetails: [],
	profile:{},
	saveprofile:{},
	orderhistory:{},
	profile_option:'',
	customerid:'',
    cancelOrderData:{},
    cancelReasonData:{}
	
}

/**
 * reducer .
 * @constructor
 * @param {string} state - state of the data shape.
 * @param {string} action - action payload passed for data updates.
 */
let reducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_FIRST_NAME: return {...state, fname: action.payload }
        case USER_LAST_NAME: return {...state, lname: action.payload }
		case USER_MIDDLE_NAME: return {...state, mname: action.payload }
        case USER_PHONE: return {...state, phone: action.payload}
        case USER_EMAIL: return {...state, email: action.payload }
        case USER_ADDRESS_1: return {...state, address1: action.payload }
        case USER_ADDRESS_2: return {...state, address2: action.payload }
        case USER_PINCODE: return {...state, pin: action.payload }
        case USER_CITY: return {...state, city: action.payload }
        case USER_DISTRICT: return {...state, dist: action.payload }
        case USER_STATE: return {...state, state: action.payload }
        case ADDRESS_ID: return {...state, addrid: action.payload }
        case USER_COUNTRY: return {...state, country: action.payload }
        case ADDRESS_TYPE : return {...state, type: action.payload }
        case CUSTOMER_DETAILS: return {...state, customerDetails: ((action.payload.data!=null && typeof action.payload.data.data != "undefined")?action.payload.data.data:null), addressTypes: ((action.payload.data!=null && typeof action.payload.data.type != "undefined")?action.payload.data.type:null),customerid: ((action.payload.data!=null && typeof action.payload.data.customerId != "undefined")?action.payload.data.customerId:null)}
        case CLEAR_DETAILS: return initialState
		case NOTIFICATION: return {...state, notification: action.payload}
		case GENERIC_MODAL: return { ...state, modal: action.payload }
		case CUSTOMER_PROFILE: return { ...state, profile: action.payload }
		case SAVE_PROFILE: return { ...state, saveprofile: action.payload }
		case ORDER_HISTORY: return { ...state, orderhistory: action.payload }
		case PROFILE_OPTION: return { ...state, profile_option: action.payload }
        case USER_PHONECOUNTRYCODE: return {...state, phoneCountryCode: action.payload }
        case USER_PHONECODE: return {...state, phoneCode: action.payload }
        case CREATE_CANCEL_REQUEST: return {...state, cancelOrderData: action.payload}
        case GET_CANCEL_REASON: return {...state, cancelReasonData: action.payload}
        
        default: return state;
    }
}

/**
 * Export Object (Action + Reducer)
 */
const UserAction = {
    reducer: reducer,
    actions: {
        getCustomerDetails,
        clear_userDetails,
		getCustomerProfile,
		saveCustomerProfile,
		getOrderHistory,
        cancelOrderItem,
        getCancelReason
    }
}

export default UserAction
