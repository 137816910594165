import React, { useState } from 'react';
import ImageUtil from '../../utils/ImageUtil';
import { useNavigate } from 'react-router-dom';
import { clearCategory } from '../../actions/CategoryAction';
import { connect } from 'react-redux';
import {setSearch} from '../../actions/AuthAction';

const Search = (props) => {

    const [input, setInput] = useState('');
    const navigate = useNavigate();
    
    function handleClick(e){
        
        e.preventDefault();
        props.clearCategory();
		props.setSearch(true);
        navigate(`/categorySection/${5}/${0}/${input}`);

        setInput(''); //.. clear input field
    } 

    return (
        <div className="search">
            {/*eslint-disable-next-line*/}
            <svg className="svg-icon search-icon"  onClick={handleClick} aria-labelledby="title desc" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.9 19.7"><title id="title">Search Icon</title><desc id="desc">A magnifying glass icon.</desc><g class="search-path" fill="none" stroke="#848F91"><path stroke-linecap="square" d="M18.5 18.3l-5.4-5.4"/><circle cx="8" cy="8" r="7"/></g></svg>
            <a className="mobile-search"
                
                onClick={handleClick}>
                <img
                    src={ImageUtil.getImage('search-green.png')}
                    alt="search"
                />
            </a>

            <form
                action=""
                method="get"
                className={ "search-form active"}
            >
                 {/* <button
                    className="search-buttontext-light"
                    type="submit"
                    onClick={handleClick}><i className="fa fa-search"></i>
                </button> */}
                <input
                    type="search"
                    placeholder="Search for Item"
                    className="search-keyword"
                    value={input}
                    onChange={e => setInput(e.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            navigate(`/categorySection/${5}/${0}/${input}`);
                        }
                      }}
                />
               
                
            </form>
            {/* <p className="tagline">GET DAILY NECESSITY AT YOUR HOME WITHIN 24 HOURS OF PLACING YOUR ORDER</p> */}
        </div>
    )
}

export default connect(null, {clearCategory,setSearch}) (Search);