import React, {useEffect,useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Container, Card, Table,Tabs,Tab,} from 'react-bootstrap';
import { getItemsByVariant} from '../actions/ProductsAction';
import { Global } from '../utils/Env';

/**
 * Creates a new OrderedItemTable.
 * @class
 */
const WishlistItems = props => {
	
	const product = (props.productsList.filter(product => {
    return Number(product.id) === Number(props.proid)
  }))[0];
  
  useEffect(() => {
		if(typeof product != "undefined" && typeof product.variant != "undefined")
			if(product.variant[0]!=null)
				props.getItemsByVariant({ companyid: props.companyid, variant: product.variant,itemId:Number(props.proid)});
    },[product]);
	
	Global.callback.itemsbyvariant_onComplete = () => {
	}
	
	
	
	const items=()=>{
		
		const wishlisteditems=props.wishlist.filter((item)=>{return item.itemId==props.proid});
		
		return (
		<React.Fragment>
		
		{wishlisteditems.length>0?wishlisteditems.map((item,i)=>{
				return(
					<tbody key={item.itemId.toString().concat(item.variantId.toString())}>
						<tr className="tr" >
						{(props.variantList.filter((variant)=>{return variant.id==item.itemId && variant.variantid==item.variantId}).length>0)?
						<td className="tdata text-center pt-1 pb-1 fsize13" >{props.variantList.filter((variant)=>{return variant.id==item.itemId && variant.variantid==item.variantId})[0].color}</td>:null}
						 </tr>
						 </tbody>
			
                  );
		}):null}
		</React.Fragment>
		);
				
						 
						
	}
	
	
	
						

    return (
        <React.Fragment>
			<div>
            <Row >
                <Col sm={12}>
                <Table responsive striped bordered  hover size="sm">
				<thead>
                <tr className="thead-table-ord">
							<th className="td pb-1 pt-1 text-center fsize13" style={{width:'10%'}}><p className="m-0 table-blank-p">COLOR</p></th>
                        </tr>
                        </thead>
						{(typeof product.variant != "undefined" && typeof product != "undefined" && props.variantList.length>0)?((product.variant[0]!=null)?items():(
						<tbody>
						<tr className="tr" >
						 <td className="tdata text-center pt-1 pb-1 fsize13" >Single Variant</td>
						 </tr>
						 </tbody>
						)):null}
						</Table>
                    
            </Col>
            </Row>
        </div>
    
        </React.Fragment>
    );
}
 
const mapStateToProps = state => ({
  wishlist: state.ProductsData.wishlist,
  productsList: state.ProductsData.productsList,
  companyid: state.HomeData.companyid,
  variantList: state.ProductsData.variantList
})

export default connect(mapStateToProps,{getItemsByVariant})(WishlistItems);
