import React,{useState,useEffect} from 'react';
import {OverlayTrigger,Popover} from 'react-bootstrap';
import { connect } from 'react-redux';

const Info = props => {
	return(
	<OverlayTrigger
      trigger="click"
      key="test"
      placement="bottom"
      overlay={
        <Popover id="test">
          <Popover.Title as="h3">{props.title}</Popover.Title>
          <Popover.Content>
		  {props.content}
          </Popover.Content>
        </Popover>
      }
    >
      <a><i className="fa fa-info-circle"></i></a>
    </OverlayTrigger>
	);
	
	
	
}

const mapStateToProps = state => ({
    
  })

export default connect(mapStateToProps, {  }) (Info);