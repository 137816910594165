import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import ImageUtil from '../../utils/ImageUtil';
import { to2Decimal, formatINR, formatSymbolFromCurrency } from '../../utils/CommonUtil';
import Counter from "../Counter";
import { removeItem, saveCart, getCart } from '../../actions/CartAction';
import { saveWishList, removeWishList, getWishList } from '../../actions/ProductsAction';
import { setLoginModal, set_login_head } from '../../actions/AuthAction';
import { Col, Row } from 'react-bootstrap';
import { Global } from '../../utils/Env';
import MsgModal from '../../container/MsgModal';


const CartItem = (props) => {
    const navigate = useNavigate();
    Global.callback.savecart_onComplete = () => {
        props.getCart({ "companyid": props.companyid, "customerid": parseInt(props.loginData.data.customerId) });
    }
    const [show, setShow] = useState(false);
    const [content, setContent] = useState(null);
    const [head, setHead] = useState("Wish List");
    const getTotalAmount = (x) => {
        let totalAmount = 0
        for (let i = 0; i < x.length; i++) {
            let price = (x[i].promo_price ? x[i].promo_price : x[i].price_regular)
            totalAmount += price * parseInt(x[i].quantity);
        }
        return totalAmount
    }
    const closeAction = () => {
        setShow(false);
    }
    const handleRemove = () => {
        let cart1 = [...props.cartList];
        let index = props.product.variantid ? cart1.findIndex(x => x.id === props.product.id && x.variantid === props.product.variantid) : cart1.findIndex(x => x.id === props.product.id);
        cart1.splice(index, 1);
        let totalAmount = getTotalAmount(cart1);
        props.removeItem({ cart: cart1, totalAmount: totalAmount });
        if (props.authenticated) {
            let obj = {
                "companyid": props.companyid,
                "customerid": parseInt(props.loginData.data.customerId),
                "userid": props.loginData.data.id,
                "carttItemVariant": [{
                    "itemId": props.product.id,
                    "itemVariantId": props.product.variantid ? props.product.variantid : 0,
                    "itemQty": 0
                }]
            }
            props.saveCart(obj);
        }

    }

    Global.callback.savewishlist_onComplete = () => {
        props.getWishList({ "companyid": props.companyid, "customerid": parseInt(props.loginData.data.customerId) });
    }


    const wishlist = () => {

        if (!props.authenticated) {
            props.setLoginModal(true);
            props.set_login_head("Login");
        }
        else {
            let obj = {
                "companyid": props.companyid,
                "customerid": parseInt(props.loginData.data.customerId),
                "userid": props.loginData.data.id,
                "carttItemVariant": [{
                    "itemId": props.product.id,
                    "itemVariantId": props.product.variantid ? props.product.variantid : 0
                }]
            }
            props.saveWishList(obj);
            setHead("Wish List");
            setShow(true);
            setContent("The item has been removed from your cart and moved to your wishlist");
            handleRemove();
        }

    }

    const removewishlist = () => {

        if (!props.authenticated)
            props.setLoginModal(true);
        else {
            let obj = {
                "companyid": props.companyid,
                "customerid": parseInt(props.loginData.data.customerId),
                "userid": props.loginData.data.id,
                "itemId": props.product.id,
                "itemVariantId": props.product.variantid ? props.product.variantid : 0
            }
            props.removeWishList(obj);
            setHead("Wish List");
            setShow(true);
            setContent("The item has been removed from your wishlist");
        }

    }

console.log('props.product',props.product)
let price=props.product.offer?props.product.offer.offerSalePrice:props.product.price_regular
    return (
        <React.Fragment>
            <Row className="cart-item cart-item-newpage border-bottom mt-0 mb-0" >

                <Col md="2" xs="3" className="p-0-5-tab pl-1 pr-2">
                    <div className="chkout-main-imgpanel">

                        {props.product.brandicon ? <img alt={props.product.brandicon} className="cart-brand-img" src={props.product.brandicon}></img> : null}
                        <img
                            className="product-image product-image-cart product-image-cart-newpage cursor" onClick={() => navigate(`/categorySection/${2}/${props.product.id}/${props.product.variantid}`)}
                            src={props.product.images[0] ? ImageUtil.getImage(props.product.images[0].name.substring(0, props.product.images[0].name.lastIndexOf(".")).concat("_Thumbnail").concat(props.product.images[0].name.substring(props.product.images[0].name.lastIndexOf("."), props.product.images[0].name.length))) : ImageUtil.getErrorImage()}

                            alt={props.product.name}
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = ImageUtil.getErrorImage();
                            }}
                        />
                         {/* <div>

{(props.product.variantid) ?
    ((props.wishlist.filter((item) => { return item.itemId == props.product.id && item.variantId == props.product.variantid }).length != 0) ? <span title="Remove from Wishlist" className="badge position-absolute z-index1 mt-1 label-wish"><i className="fa fa-heart fa-2x wishlisted" onClick={() => removewishlist()} /></span> : <span title="Move to Wishlist" className="badge position-absolute z-index1 mt-1 label-wish"><i className="fa fa-heart fa-2x not-wishlisted" onClick={() => wishlist()} /></span>) :
    ((props.wishlist.filter((item) => { return item.itemId == props.product.id }).length != 0) ? <span title="Remove from Wishlist" className="badge position-absolute z-index1 mt-1 label-wish"><i className="fa fa-heart fa-2x wishlisted" onClick={() => removewishlist()} /></span> : <span title="Move to Wishlist" className="badge position-absolute z-index1 label-wish"><i className="fa fa-heart fa-2x not-wishlisted" onClick={() => wishlist()} /></span>)}
</div> */}
                    </div>
                </Col>
                <Col md="10" xs="9" className='pl-0'>
                    <span className="badge badge-pill badge-success">{props.product.promo_percentage}</span>
                    <Row>
                    <Col md="9" className="text-left pl-0 pl-0-mob">
                        {/* <p className="product-name product-name-cart mb-0">{props.product.item_code}</p> */}
                        <a href={(`/categorySection/${2}/${props.product.id}/${props.product.variantid}`)} className="product-name product-name-cart product-name-cart-newpage mb-0 cursor" onClick={() => navigate(`/categorySection/${2}/${props.product.id}/${props.product.variantid}`)}>{`${props.product.name} `}</a>
                       
                        {/* <p className="text-muted text-left font-12"><b>({props.product.size})</b></p> */}
                        {/* <label className="product-name product-name-cart ">Color:<b>{props.product.color}</b></label> */}
                        {props.product.color && props.product.color!=''?<p className='prod-variant-text'>Color: <span className='a-text-regular col8f8f8f fsize13'>{props.product.color}</span></p>:''}
                        {props.product.size && props.product.size!=''?<p className='prod-variant-text'>Size: <span className='a-text-regular col8f8f8f fsize13'>{props.product.size}</span></p>:''}
                        {props.product.weight && props.product.weight!=''?<p className='prod-variant-text'>Weight: <span className='a-text-regular col8f8f8f fsize13'>{props.product.weight}</span></p>:''}
                        {props.product.material && props.product.material!=''?<p className='prod-variant-text'>Material: <span className='a-text-regular col8f8f8f fsize13'>{props.product.material}</span></p>:''}
                    </Col>
                    <Col md="3" xs="5" className={(props.wishlist.filter((item) => { return item == props.product.id }).length != 0) ? "wishlist-cart p-0 pr-2 p-0-mob ps-static" : "wishlist-move p-0 pr-2 p-0-mob ps-static"}>
                                

                                <div className=""><p className="amount product-price product-price-newpage mt-2 mb-0"><b>
                                    {formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode), to2Decimal(props.product.quantity * price))}</b></p>
                                </div>
                            </Col>
                    </Row>
                    
                    <div className="product-info">
                        <Row>
                            <Col md="7" xs="8" className="text-left checkout-count p-0 p-0-mob mt-2">
                                {/* <p className="text-left lh-mob-20"><b>{props.product.promo_price ? props.product.promo_price : props.product.price_regular}</b></p> */}
                                <div>
                                    {props.product.stockQty!=null && props.product.stockQty<=0?<p className='stock-out-cart'>Out of Stock</p>:
                                    <>
                                <Counter product={props.product} />&nbsp;&nbsp;
                                    {/* <span aria-hidden="true">@</span>&nbsp;&nbsp; */}
                                    <span className="single-amt">
                                        {/* {props.product.promo_price 
                                        ? formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode),to2Decimal(props.product.promo_price))
                                        : formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode),to2Decimal(props.product.price_regular))} */}
                                    </span> &nbsp; &nbsp;</>}
                                    {/* <s className="text-muted f-size">₹ 125</s> */}<i className="fa fa-trash-o del-color" aria-hidden="true" title="Remove Item from Cart"
                                        href="#"
                                        onClick={handleRemove}
                                    >

                                    </i></div>

                            </Col>
                           
                            {/* <Col md="5" xs="5" className="text-right"><p className="amount product-price text-right">
                                {props.product.promo_price 
                                    ? formatINR(to2Decimal(props.product.quantity * props.product.promo_price)) 
                                    : formatINR(to2Decimal(props.product.quantity * props.product.price_regular))}</p>
                            </Col> */}

                        </Row>
                        {/* <Row>
                            <Col>
                            <p className="product-name product-name-cart mb-0">{`${props.product.name} `} <b>({props.product.size})</b></p>
                            </Col>
                        </Row> */}
                    </div>
                    {/*eslint-disable-next-line*/}

                </Col>
            </Row>
            <MsgModal show={show} title={head} message={content} closeAction={closeAction} />
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    companyid:state.HomeData.companyid,
    cartList: state.CartData.cartList,
    loginData: state.AuthData.loginData,
    authenticated: state.AuthData.authenticated,
    wishlist: state.ProductsData.wishlist,
    companyDetailsCurrency: state.HomeData.companyDetailsCurrency
})
export default connect(mapStateToProps, { removeItem, saveCart, getCart, saveWishList, removeWishList, setLoginModal, getWishList, set_login_head })(CartItem);

