import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { Button, Container, Row, Col, Form, Navbar } from 'react-bootstrap';
import { Global, host } from '../utils/Env';
import MsgModal from './MsgModal';
import Footer from '../components/Footer';
import PhoneInput,{ getCountryCallingCode  } from 'react-phone-number-input'
import {login, signup, setMsgModal,set_fname,set_phone,set_email,set_address1,set_user_name,set_lname,set_mname,set_address2,set_city,set_state,set_country,set_zip,set_password,setLoginModal,set_login_head } from '../actions/AuthAction';

/**
 * Creates a new SignUp.
 * @class
 */
const SignUpDetails = props => {

    const [validated, setValidated] = useState(false);
    const [signUpDis, setSignUpDis] = useState(false);
    let primaryColor=props.petTemplateDetails && props.petTemplateDetails.primaryColor?props.petTemplateDetails.primaryColor:'';

    const navigate = useNavigate();

    /** @function
 * @name signup_onError */
    Global.callback.signup_onError = () => {
        setSignUpDis(false);
        props.setMsgModal({
            "title": "Error in Registration",
            "message": props.message,
            "show": true
        });
    }
        /** @function
 * @name login_onComplete */
         Global.callback.signup_onComplete = () => {
            setSignUpDis(false);
        }


    //console.log(countryCodeData);
    //console.log(phoneCodeValue);
    //console.log(phone);

    useEffect(() => {
props.set_fname('');
props.set_email('');
props.set_address1('');
props.set_user_name('');
props.set_lname('');
props.set_mname('');
props.set_address2('');
props.set_city('');
props.set_state('');
props.set_country('');
props.set_zip('');
props.set_password('');
    }, []);
      /** @function
 * @name onErrorSignUpClose */
    const onErrorSignUpClose = () => {
        props.setMsgModal({
            "title": "",
            "message": "",
            "show": false
        });
    }
          /** @function
 * @name signup_onComplete */
    Global.callback.signup_onComplete = () => {
        setSignUpDis(false);
        if (props.status == false) {
            
        } else {
           props.set_fname('');
props.set_email('');
props.set_address1('');
props.set_user_name('');
props.set_lname('');
props.set_mname('');
props.set_address2('');
props.set_city('');
props.set_state('');
props.set_country('');
props.set_zip('');
props.set_password('');
            // alert('Registration successful');
            props.login({"CompanyId":props.companyid,"UserName":props.phone, "Password":props.password});    
        }
        toast.info("Registration Successful and Logged In")
    }
    const handleSignUp = (event) => {
        setValidated(true);
        //const form = event.currentTarget;
        //console.log("dropdown value ",dropdowntest);  // 5-10-2020
        if (handleValidation() === true) {
			//console.log("Validation successful");
        props.signup({
			"companyId":props.companyid,
			"username": props.email?props.email:'',
            "Email":props.email?props.email:'',
            "Password": props.password,
            "Phone": props.phone,
			"FirstName": props.fname,
			"MiddleName": '',
			"LastName": props.lname,
            "Country":'',
            "CountryCode":'',
			"UserPhoneCountryCode":props.phoneCountryCode,
            "PhoneCode":props.phoneCode
        });
        setSignUpDis(true);
        setValidated(false);
    }else{
		//console.log("failed");
        //setValidated(true);
        event.stopPropagation();
       
    }

    }

    const handleValidation = () => {

        let formIsValid = true;

        //Fname
        
		 if(!props.fname){
			props.setMsgModal({
            "title": "Form Validation Error",
            "message": "Error in First Name Please check",
            "show": true
        });
           formIsValid = false;
		   //console.log("name");
        }
  
        if(typeof props.fname !== "undefined" && props.fname==''){
           if(!props.fname.match(/^[a-zA-Z ]+$/)){
              formIsValid = false;
			  //console.log("name2");
			  props.setMsgModal({
            "title": "Form Validation Error",
            "message": "Error in First Name Please check",
            "show": true
        });
           }        
        }
		//lname
		if(!props.lname){
			props.setMsgModal({
            "title": "Form Validation Error",
            "message": "Error in Last Name Please check",
            "show": true
        });
           formIsValid = false;
		   //console.log("lname");
        }
  
        if(typeof props.lname !== "undefined" && props.lname==''){
           if(!props.lname.match(/^[a-zA-Z ]+$/)){
              formIsValid = false;
			  //console.log("name2");
			  props.setMsgModal({
            "title": "Form Validation Error",
            "message": "Error in Last Name Please check",
            "show": true
        });
           }        
        }
   
        //Email
        
  
        if(typeof props.email !== "undefined" && props.email!=''){
           let lastAtPos = props.email.lastIndexOf('@');
           let lastDotPos = props.email.lastIndexOf('.');

           if (!(lastAtPos < lastDotPos && lastAtPos > 0 && props.email.indexOf('@@') == -1 && lastDotPos > 2 && (props.email.length - lastDotPos) > 2)) {
              formIsValid = false;
			  props.setMsgModal({
            "title": "Form Validation Error",
            "message": "Error in Email Please check",
            "show": true
        });
			  //console.log("email2");
            }
       }
       if(props.password=='') 
       {
        formIsValid = false;
			   //console.log("password");
			   props.setMsgModal({
            "title": "Form Validation Error",
            "message": "Error in Password Please check",
            "show": true
        });
       } 

       return formIsValid;
   }


    /** @function
 * @name linkToSignIn */  
    const linkToSignIn = () => {
		 props.set_fname('');
props.set_phone('');
props.set_email('');
props.set_address1('');
props.set_user_name('');
props.set_lname('');
props.set_mname('');
props.set_address2('');
props.set_city('');
props.set_state('');
props.set_country('');
props.set_zip('');
props.set_password('');
        navigate("/");
		props.setLoginModal(true);
		props.set_login_head("Login");
    }
     /** @function
 * @name saveSignUp */ 
    // const saveSignUp = (event) => {
    //      setValidated(true);

    //     const form = event.currentTarget;

    //     if (form.checkValidity() === false) {
    //         setEnablePlaceOrder(false);
    //         event.stopPropagation();
    //     }
    //     event.preventDefault();

    //     if (form.checkValidity() === true) {
    //         setEnablePlaceOrder(true);
    //     }

    //     //.. callback for evaluation
    // }
    /** @function
 * @name handlenameChange */ 
    const handlenameChange = (e) => {
        props.set_user_name(e.target.value);
    }
    /** @function
    /** @function
 * @name handleemailChange */
    const handleemailChange = (e) => {
        let lastAtPos = e.target.value.lastIndexOf('@');
        let lastDotPos = e.target.value.lastIndexOf('.');
        if (!(lastAtPos < lastDotPos && lastAtPos > 0 && e.target.value.indexOf('@@') == -1 && lastDotPos > 2 && (e.target.value.length - lastDotPos) > 2))
            e.target.value = e.target.value;
            props.set_email(e.target.value);
    }
    /** @function
 * @name handleaddress1Change */
    const handleaddress1Change = (e) => {
        props.set_address1(e.target.value);
    } 
    /** @function
 * @name handleaddress2Change */
    const handleaddress2Change = (e) => {
        props.set_address2(e.target.value);
    }
    /** @function
 * @name handlecityChange */
    const handlecityChange = (e) => {
        if (e.target.value.charAt(e.target.value.length - 1) >= '0' && e.target.value.charAt(e.target.value.length - 1) <= '9')
            e.target.value = e.target.value.substring(0, e.target.value.length - 1);
        props.set_city(e.target.value);
    }
    /** @function
 * @name handlestateChange */
    const handlestateChange = (e) => {
        if (e.target.value.charAt(e.target.value.length - 1) >= '0' && e.target.value.charAt(e.target.value.length - 1) <= '9')
            e.target.value = e.target.value.substring(0, e.target.value.length - 1);
        props.set_state(e.target.value);
    }
     /** @function
 * @name handledistChange */
   
    /** @function
 * @name handlepinChange */
    const handlezipChange = (e) => {
        if ((e.target.value.length > 6 || !(e.target.value.charAt(e.target.value.length - 1) >= '0' && e.target.value.charAt(e.target.value.length - 1) <= '9')))
            e.target.value = e.target.value.substring(0, e.target.value.length - 1);
        props.set_zip(e.target.value);
    }
	const handlefnameChange = (e) => {
        if (e.target.value.charAt(e.target.value.length - 1) >= '0' && e.target.value.charAt(e.target.value.length - 1) <= '9')
            e.target.value = e.target.value.substring(0, e.target.value.length - 1);
        props.set_fname(e.target.value);
    }
	const handlelnameChange = (e) => {
        if (e.target.value.charAt(e.target.value.length - 1) >= '0' && e.target.value.charAt(e.target.value.length - 1) <= '9')
            e.target.value = e.target.value.substring(0, e.target.value.length - 1);
        props.set_lname(e.target.value);
    }
	const handlemnameChange = (e) => {
        if (e.target.value.charAt(e.target.value.length - 1) >= '0' && e.target.value.charAt(e.target.value.length - 1) <= '9')
            e.target.value = e.target.value.substring(0, e.target.value.length - 1);
        props.set_mname(e.target.value);
    }
	const handlepasswordChange = (e) => {
        props.set_password(e.target.value);
    }
	const handlecountryChange = (e) => {
        if (e.target.value.charAt(e.target.value.length - 1) >= '0' && e.target.value.charAt(e.target.value.length - 1) <= '9')
            e.target.value = e.target.value.substring(0, e.target.value.length - 1);
        props.set_country(e.target.value);
    }
    

    return (
        <div className="page-bodesection">
            {/* <Navbar bg="light" sticky="top" expand="lg border-bottom" className="navstylecontrol ">
                <Navbar.Brand>
                    <img src={require("../images/logo.png")} alt="logo" />
                </Navbar.Brand>
            </Navbar> */}
            <div>
                <Row>
                <Col className='p-0'>
                    <Row className="">
                        {/* <Col sm={8} className="hidden-xs">
                            <div className="position-relative z-index9">
                                <img src={require("../images/new-user-bg.png")} alt="Login Banner" className="w-75control" />
                            </div>
                            <div className="position-absolute log-sign-leftdiv signin-sign-leftdiv bge9e1d4 text-center">
                                <div>
                                    <h1 className="text-center log-welcome-text mb-0"><b>WELCOME</b></h1>
                                    <h3><i>To</i></h3>
                                    <label className="log-banner-logo mb-1 bg-white"><img src={require("../images/log-logo.png")} alt="Login Banner" /></label>
                                    <p className="mb-2"><b>Online Fashion Store</b></p>
                                    <h4 className="b2btagline mb-4">ECOM ORDERING PLATFORM</h4>
                                    <Button  className="rounded-0 primarybutton pt-2 pb-2 position-relative z-index9" onClick={linkToSignIn} >SIGN IN</Button>
                                </div>
                            </div>
                        </Col> */}
                        <Col sm={12} className="p-0">
                            <div>
                            <Form noValidate validated={validated}>
                                <fieldset className="scheduler-border rounded">
                                    {/* <legend className="scheduler-border signin-label">New User? Register Here</legend> */}
                                   
                                        {/* <Form.Group controlId="formBasicEmail">
                                            <Form.Label className="fsize14 mb-1"><b>User Name <i className="text-danger">*</i></b></Form.Label>
                                            <Form.Control type="text" placeholder="Enter User Name" value={props.name} required  onChange={handlenameChange} />
                                        </Form.Group>
										 <Form.Group controlId="formBasicEmail">
                                            <Form.Label className="fsize14 mb-1"><b>Password <i className="text-danger">*</i></b></Form.Label>
                                            <Form.Control type="password" placeholder="Enter Password" value={props.password} required  onChange={handlepasswordChange} />
                                        </Form.Group> */}
										<Form.Group controlId="formBasicEmail">
                                            <Form.Label className="fsize14 mb-1 google-colorstyle phno-lebel">First Name <i className="text-danger">*</i></Form.Label>
                                            <Form.Control type="text" className='br-radious-0' value={props.fname} required  onChange={handlefnameChange} />
                                        </Form.Group>
										{/* <Form.Group controlId="formBasicEmail">
                                            <Form.Label className="fsize14 mb-1"><b>Middle Name</b></Form.Label>
                                            <Form.Control type="text" placeholder="Enter Middle Name" value={props.mname}  onChange={handlemnameChange} />
                                        </Form.Group> */}
										<Form.Group controlId="formBasicEmail">
                                            <Form.Label className="fsize14 mb-1 google-colorstyle phno-lebel">Last Name <i className="text-danger">*</i></Form.Label>
                                            <Form.Control type="text" className='br-radious-0' value={props.lname} required  onChange={handlelnameChange} />
                                        </Form.Group>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label className="fsize14 mb-1 google-colorstyle phno-lebel">Mobile No <i className="text-danger">*</i></Form.Label>
                                            {/* <Form.Control placeholder="Enter Mobile No." required pattern='[0-9]{10}' value={props.phone} onChange={handlephoneChange} /> */}
                                                <PhoneInput 
                                                countrySelectProps={{ unicodeFlags: false }}
                                                countryCallingCodeEditable={false}
                                                international
                                                defaultCountry={props.countryCode}
                                                value={props.phoneCode+props.phone}
                                                disabled={true}
                                                name="phone" />
                                        </Form.Group>
                                        <Form.Group controlId="formBasicPassword">
                                            <Form.Label className="fsize14 mb-1 google-colorstyle phno-lebel">Email Address <i className="text-danger"></i></Form.Label>
                                            <Form.Control className='br-radious-0' value={props.email}  onChange={handleemailChange} />
                                        </Form.Group>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label className="fsize14 mb-1 google-colorstyle phno-lebel"><b>Password <i className="text-danger">*</i></b></Form.Label>
                                            <Form.Control type="password" className='br-radious-0' value={props.password} required  onChange={handlepasswordChange} />
                                        </Form.Group>
                                        {/* <Form.Group controlId="formBasicEmail">
                                            <Form.Label className="fsize14 mb-1"><b>Address 1 <i className="text-danger">*</i></b></Form.Label>
                                            <Form.Control type="text" placeholder="Enter Address 1" value={props.address1} required onChange={handleaddress1Change} />

                                        </Form.Group>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label className="fsize14 mb-1"><b>Address 2</b></Form.Label>
                                            <Form.Control type="text" placeholder="Enter Address 2" value={props.address2} onChange={handleaddress2Change} />

                                        </Form.Group>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label className="fsize14 mb-1"><b>City<i className="text-danger">*</i></b></Form.Label>
                                            <Form.Control type="text" placeholder="Enter City" value={props.city} required onChange={handlecityChange} required />

                                        </Form.Group>
                                        
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label className="fsize14 mb-1"><b>State<i className="text-danger">*</i></b></Form.Label>
                                            <Form.Control type="text" placeholder="Enter State" value={props.state} onChange={handlestateChange} required />

                                        </Form.Group>
										 <Form.Group controlId="formBasicEmail">
                                            <Form.Label className="fsize14 mb-1"><b>Country<i className="text-danger">*</i></b></Form.Label>
                                            <Form.Control type="text" placeholder="Enter Country" value={props.country} onChange={handlecountryChange} required />

                                        </Form.Group>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label className="fsize14 mb-1"><b>Zip Code<i className="text-danger">*</i></b></Form.Label>
                                            <Form.Control type="text" placeholder="Enter Zip Code" value={props.zip} onChange={handlezipChange} pattern='[0-9]{6}' required />

                                        </Form.Group> */}
                                        
                                      
                                        <div className="mt-4"><Button  style={{backgroundColor:primaryColor}} className="rounded-0 primary-button-nocolor w-100" onClick={signUpDis?console.log('false'):(e)=>handleSignUp(e)}>REGISTER HERE</Button>{' '}</div>
                                        
                                    
                                </fieldset>
                                {/* <div className="">
                                        <p className="fsize13 font-weight-bold text-muted mb-0">Existence User? <a href="" className="fsize13 font-weight-bold text-danger text-decoration-underline"><span onClick={signUpDis?console.log('false'):()=>linkToSignIn()}>Sign In</span></a></p>
                                        </div> */}
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Col>
                </Row>
                
            </div>
            {/* <Footer /> */}
            {props.modal && props.modal.show && <MsgModal show={props.modal.show} title={props.modal.title} message={props.modal.message} closeAction={onErrorSignUpClose} />}
        </div>
    );
}

const mapStateToProps = state => ({
    message: state.AuthData.message,
    status: state.AuthData.status,
    email: state.AuthData.email,
    address1: state.AuthData.address1,
    address2: state.AuthData.address2,
    city: state.AuthData.city,
    state: state.AuthData.state,
	fname:state.AuthData.fname,
	lname:state.AuthData.lname,
	mname:state.AuthData.mname,
	country:state.AuthData.country,
    countryCodeDefault:state.HomeData.servicePhoneCountryCode,
    phoneCodeDefault:state.HomeData.servicePhoneCode,
	password:state.AuthData.password,
    phone:state.UserData.phone,
    phoneCountryCode: state.UserData.phoneCountryCode,
    phoneCode:state.UserData.phoneCode,
	zip:state.AuthData.zip,
    modal: state.AuthData.modal,
    name: state.AuthData.name,
	companyurl: state.HomeData,
    companyid:state.HomeData.companyid,
  petTemplateDetails: state.petProductData.userP_AllProductInfo.data

})

export default connect(mapStateToProps, {login,signup, setMsgModal,set_fname,set_phone,set_email,set_address1,set_user_name,set_lname,set_mname,set_address2,set_city,set_state,set_country,set_zip,set_password,setLoginModal,set_login_head
})(SignUpDetails);
