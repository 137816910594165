import React, { useState, useEffect } from 'react';
//import {Alert, Row, Col } from 'react-bootstrap';
//import Actions, { DispatchAction } from '../actions';
import { connect } from 'react-redux';
import { Navbar, Nav, ListGroup } from 'react-bootstrap';
import MsgModal from '../container/MsgModal';
import { setMsgModal, setProfoption } from '../actions/UserAction';
import { setLoginModal } from '../actions/AuthAction';
import { Modal, Button, Row, Col, Card, Form, Container } from 'react-bootstrap';
import { versionInfo } from '../utils/CommonUtil';
import ImageUtil from '../utils/ImageUtil';
import { useNavigate,Link } from 'react-router-dom';
import footerlogo from '../assets/img/footer-logo.png';
import footercard from '../assets/img/cards-1.jpg';
//function Footer(props) {
/**
* Creates a new Footer.
* @class
*/
const Footer = (props) => {
  /** @function
 * @name showModal */
  const showModal = (msgTitle, msgBody) => {
    props.setMsgModal({
      "title": msgTitle,
      "message": msgBody,
      "show": true
    });
  }
  /** @function
 * @name hideModal */
  const hideModal = () => {
    props.setMsgModal({
      "title": "",
      "message": "",
      "show": false
    });
  }
  /** @function
 * @name checkFooterData */
  const checkFooterData = (array, index) => {
    return (Array.isArray(array) && typeof array[index] === 'object' && ("title" in array[index]) && ("htmlData" in array[index])) ? true : false
  }
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    hideModal()
  }, [])
  const profile = () => {
    if(props.authenticated)
    {
    props.setProfoption("first");
    navigate("/profile");
    }
    else
    props.setLoginModal(true);
  }
  const order = () => {
    if(props.authenticated)
    {
    props.setProfoption("fourth");
    navigate("/profile");
    }
    else
    props.setLoginModal(true);
  }
  //console.log("propstrad",props)
  return (
    <footer>
      { //       
        // <div className="footer-wrapper">

        //   <Row className="ml-0 mr-0">
        //   <Col className="text-center-mob" xs={{ span: 12, order: 2 }} md={{ span: 5, order: 1 }}>
        //   <small className="w-100-mob d-block-mob text-center-mob">
        //     &copy; 2020 Copyright &nbsp;<strong><a href="http://wizardcomm.net">WizardComm</a> - <a href="http://uvanij.com">uVanij.com</a></strong><span>{versionInfo()}</span>
        //   </small>

        //   </Col>
        //   <Col xs={{ span: 12, order: 1 }} md={{ span: 7, order: 2 }}>
        //   <nav className="p-0 w-100-mob d-block-mob text-right footer-right-section text-center-mob">
        //   {checkFooterData(props.footerdata,1) && <a href="#" onClick={() => showModal(props.footerdata[1].title, props.footerdata[1].htmlData)}>Contact Us</a>}
        //   {checkFooterData(props.footerdata,0) && <a href="#" onClick={() => showModal(props.footerdata[0].title, props.footerdata[0].htmlData)}>About Us</a> }
        //   {checkFooterData(props.footerdata,2) && <a href="#" onClick={() => showModal(props.footerdata[2].title, props.footerdata[2].htmlData) }>Terms & Condition</a>} 
        //   </nav>
        //   </Col>
        //   </Row>
        //   {props.modal && props.modal.show && <MsgModal show={props.modal.show}
        //     title={props.modal.title} message={props.modal.message} closeAction={hideModal} />}
        // </div>
        <div>
          <div className="footer-wrapper">

            <Row className="ml-0 mr-0">
              <Container>
                <Row>

                  <Col md={4} className="p-0-mob">
                    <div className='first-left-footer'>
                      <img className='footer-dynamiclogo' src={props.companyDetails?props.companyDetails.companyFooterLogo:''} />
                      
                      <ul className='f-social-logo'>
                      {props.companyDetails && props.companyDetails.showFacebookOnline?<li><a href={props.companyDetails?.facebook}  target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>:null}
                      {props.companyDetails && props.companyDetails.showInstagramOnline?<li><a href={props.companyDetails?.instagram}  target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>:null}
                      {props.companyDetails && props.companyDetails.showTwitterOnline?<li><a href='#'><i class="fa fa-twitter" aria-hidden="true"></i></a></li>:null}
                      </ul>
                      {/* <img src={footercard} /> */}
                      <p>
                      {props.footerData && props.footerData.tradeLicenseDtl && props.footerData.tradeLicenseDtl.showTradeLicenseNo?
                      <div>
                      <strong>Trade License Number:</strong>{props.footerData.tradeLicenseDtl.tradeLicenseNo}
                      </div>:''}
                      <br />
                      {props.footerData && props.footerData.tinNoDtl && props.footerData.tinNoDtl.showTINNo?
                      <div>
                      <strong>TIN No:</strong>{props.footerData.tinNoDtl.tinNo}
                      </div>:''}
                      </p>
                    </div>
                  </Col>
                  <Col md={5} className="p-0-mob mt-5-mobile">
                    <div className='row'>
                      <div className='col-md-6 p-0-mob col-6'>
                        <div>
                          <h4 class="widget-title-footer">FOR CUSTOMER</h4>
                          <div className='menu-footer-menu-2-container'>
                            <ul className="menu">
                              <li className="menu-item menu-item-type-custom"><a href="#" onClick={()=>profile()}>Account details</a></li>
                              <li className="menu-item menu-item-type-custom"><a href="#" onClick={()=>order()}>Orders</a></li>
                              
                              {/*<li className="menu-item menu-item-type-custom"><a href="#">Addresses</a></li>
                              <li className="menu-item menu-item-type-custom"><a href="#">Lost password</a></li>*/}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-6 col-6'>
                        <div>
                          <h4 class="widget-title-footer">QUICK LINK</h4>
                          <div className='menu-footer-menu-2-container'>
                            <ul className="menu">
                              {props.footerData && props.footerData.aboutUsDtl && props.footerData.aboutUsDtl.showAboutUs?props.footerData.aboutUsDtl.isHtml?
                              <li className="menu-item menu-item-type-custom"><Link to={'/about-us'}>About Us</Link></li>:
                              <li className="menu-item menu-item-type-custom"><a href={props.footerData.aboutUsDtl.aboutUs} target="_blank">About Us</a></li>:''}
                              {props.footerData && props.footerData.contactUsDtl && props.footerData.contactUsDtl.showContactUs?props.footerData.contactUsDtl.isHtml?
                              <li className="menu-item menu-item-type-custom"><Link to={'/contact-us'}>Contact Us</Link></li>:
                              <li className="menu-item menu-item-type-custom"><a href={props.footerData.contactUsDtl.contactUs} target="_blank">Contact Us</a></li>:
                              ''}
                              {props.footerData && props.footerData.privacyPolicyDtl && props.footerData.privacyPolicyDtl.showPrivacyPolicy?props.footerData.privacyPolicyDtl.isHtml?
                              <li className="menu-item menu-item-type-custom"><Link to={'/privacy-policy'}>Privacy Policy</Link></li>:
                              <li className="menu-item menu-item-type-custom"><a href={props.footerData.privacyPolicyDtl.privacyPolicy} target="_blank">Privacy Policy</a></li>:
                              ''}
                              {props.footerData && props.footerData.termsAndConditionsDtl && props.footerData.termsAndConditionsDtl.showTermsAndConditions?props.footerData.termsAndConditionsDtl.isHtml?
                              <li className="menu-item menu-item-type-custom"><Link to={'/terms-conditions'}>Terms & Conditions</Link></li>:
                              <li className="menu-item menu-item-type-custom"><a href={props.footerData.termsAndConditionsDtl.termsAndConditions} target="_blank">Terms & Conditions</a></li>:''}
                              {/* <li className="menu-item menu-item-type-custom"><a href="https://mygirlco.com/blog/" target="_blank">Blog</a></li> */}
                              {/*<li className="menu-item menu-item-type-custom"><a href="#">Shop For Dogs</a></li>
                              <li className="menu-item menu-item-type-custom"><a href="#">Shop For Cats</a></li>
                              <li className="menu-item menu-item-type-custom"><a href="#">Pet Parent</a></li>*/}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={3} className="p-0-mob">
                    <div>
                      <h4 class="widget-title-footer">CONTACT INFO
                      </h4>

                      {/* <ul class="contact-info-widget default"><li class="address"><i class="fa fa-map-marker" aria-hidden="true"></i><div class="oceanwp-info-wrap"><span class="oceanwp-contact-title">Address:</span><span class="oceanwp-contact-text oceanwp-contact-text-full-width">{props.companyDetails.address.address1},
                        {props.companyDetails.address.address2},
                        {props.companyDetails.address.country},
                        {props.companyDetails.address.pin},
                        {props.companyDetails.address.state}</span></div></li><li class="mobile"><i class="fa fa-mobile" aria-hidden="true"></i><div class="oceanwp-info-wrap"><span class="oceanwp-contact-title">Mobile:</span><span class="oceanwp-contact-text">+91 0123654789</span></div></li><li class="email"><i class="fa fa-envelope" aria-hidden="true"></i><div class="oceanwp-info-wrap"><span class="oceanwp-contact-title">Email:</span><span class="oceanwp-contact-text"><a href="mailto:contactemail@mail.com"> contactemail@mail.com</a></span></div></li></ul> */}
                        
                        {(props.companyDetails && (typeof props.companyDetails.address == "undefined" && props.companyDetails.address == null)) ? 
                          <ul class="contact-info-widget default"><li class="address"><i class="fa fa-map-marker" aria-hidden="true"></i><div class="oceanwp-info-wrap"><span class="oceanwp-contact-title">Address:</span><span class="oceanwp-contact-text oceanwp-contact-text-full-width">
                          "20, PARK LANE,Police Station Park Street,BD,700016,West Bengal"
                          </span></div></li><li class="mobile"><i class="fa fa-mobile" aria-hidden="true"></i><div class="oceanwp-info-wrap"><span class="oceanwp-contact-title">Mobile:</span><a href={'callto:'+props.companyDetails?.contactPhone} class="oceanwp-contact-text">{props.companyDetails?.contactPhone}</a></div></li><li class="email"><i class="fa fa-envelope" aria-hidden="true"></i><div class="oceanwp-info-wrap"><span class="oceanwp-contact-title">Email:</span><span class="oceanwp-contact-text"><a href={'callto:'+props.companyDetails?.contactEmail}> {props.companyDetails?.contactEmail}</a></span></div></li></ul>
                        :
						props.companyDetails?
						<React.Fragment>
                         <ul class="contact-info-widget default"><li class="address"><i class="fa fa-map-marker" aria-hidden="true"></i><div class="oceanwp-info-wrap"><span class="oceanwp-contact-title">Address:</span><span class="oceanwp-contact-text oceanwp-contact-text-full-width">{props.companyDetails.address.address1},
                         {props.companyDetails.address.address2},
                         {props.companyDetails.address.state}-{props.companyDetails.address.pin}{/*props.companyDetails.address.country*/}</span></div></li><li class="mobile"><i class="fa fa-mobile" aria-hidden="true"></i><div class="oceanwp-info-wrap"><span class="oceanwp-contact-title">Mobile:</span><span class="oceanwp-contact-text">{props.companyDetails?.contactPhone}</span></div></li><li class="email"><i class="fa fa-envelope" aria-hidden="true"></i><div class="oceanwp-info-wrap"><span class="oceanwp-contact-title">Email:</span><span class="oceanwp-contact-text"><a href={props.companyDetails?.contactEmail}>{props.companyDetails?.contactEmail}</a></span></div></li></ul>
						 </React.Fragment>:''
                      }
                   
                   
                   
                    </div>
                    {/* <p className='footer-p-p text-left'>

                      {((typeof props.companyDetails.address == "undefined" && props.companyDetails.address == null)) ? "" :
                        <div>
                          {(props.companyDetails.address.address1)},
                          {(props.companyDetails.address.address2)},
                          {(props.companyDetails.address.country)},
                          {(props.companyDetails.address.pin)},
                          {(props.companyDetails.address.state)}
                        </div>
                      }
                    </p> */}
                  </Col>
                </Row>
                <Container className='p-0-mob'>
                {/*<div className='col-md-12 d-block d-sm-none'>
                  <div className='mb-3 d-block d-sm-none'><img src='https://mygirlco.com/wp-content/uploads/2021/07/SSLCOMMERZ-Pay-With-logo-All-Size_Aug-21-04.png' className='w-100'/>
                 </div>
                  </div>*/}
                <Row className='footer-last-bottom'>
                  {/*<div className='col-md-12 d-none d-sm-block'>
                  <div className='mb-3'><img src='https://mygirlco.com/wp-content/uploads/2021/07/SSLCOMMERZ-Pay-With-logo-All-Size_Aug-21-02.png' className='w-100'/>
                 </div>
                  </div>*/}
                
                 <div className='col-md-4 p-0 order-sm-last text-left text-cen-mob'>
                   <div>
                   <div className='copyright-small'>
                     <small>Copyright © {new Date().getFullYear()}  | All rights reserved.</small>
                   </div>
                   </div>
                 </div>
                 <div className='col-md-3 p-0 order-sm-last'>
                   <div className='copyright-small mt-mob-0'>
                     <small>Powered by <a href="https://wizardcomm.net/" target='_blank' className='our-com-name'>Wizard Communications Pvt Ltd.</a></small>
                   </div>
                 </div>
                 <div className='col-md-5 p-0 order-first order-md-last'>
                   <div>
                       <ul className='footer-small-menu'>
                       {props.footerData && props.footerData.privacyPolicyDtl && props.footerData.privacyPolicyDtl.showPrivacyPolicy?props.footerData.privacyPolicyDtl.isHtml?
                              <li><Link to={'/privacy-policy'}>Privacy Policy</Link></li>:
                              <li><a href={props.footerData.privacyPolicyDtl.privacyPolicy} target="_blank">Privacy Policy</a></li>:
                              ''}
                         {props.footerData && props.footerData.cancellation_RefundPolicyDtl && props.footerData.cancellation_RefundPolicyDtl.showCancellation_RefundPolicy?props.footerData.cancellation_RefundPolicyDtl.isHtml?
                              <li className="menu-item menu-item-type-custom"><Link to={'/cancel-policy'}>Cancellation / Refund Policy</Link></li>:
                              <li className="menu-item menu-item-type-custom"><a href={props.footerData.cancellation_RefundPolicyDtl.cancellation_RefundPolicy} target="_blank">Cancellation / Refund Policy</a></li>:''}
                         {props.footerData && props.footerData.termsAndConditionsDtl && props.footerData.termsAndConditionsDtl.showTermsAndConditions?props.footerData.termsAndConditionsDtl.isHtml?
                              <li><Link to={'/terms-conditions'}>Terms & Conditions</Link></li>:
                              <li><a href={props.footerData.termsAndConditionsDtl.termsAndConditions} target="_blank">Terms & Conditions</a></li>:''}
                         {props.footerData && props.footerData.contactUsDtl && props.footerData.contactUsDtl.showContactUs?props.footerData.contactUsDtl.isHtml?
                              <li><Link to={'/contact-us'}>Contact Us</Link></li>:
                              <li><a href={props.footerData.contactUsDtl.contactUs} target="_blank">Contact Us</a></li>:
                              ''}
                       </ul>
                   </div>
                 </div>
                 
                 
               </Row>
                </Container>
               
              </Container>
            </Row>

            {props.modal && props.modal.show && <MsgModal show={props.modal.show}
              title={props.modal.title} message={props.modal.message} closeAction={hideModal} />}
          </div>
        </div>
      }
    </footer>
  );

}

const mapStateToProps = state => ({
  modal: state.UserData.modal,
  termsandcondition: state.HomeData.termsncondnord,
  footerData: state.HomeData.footer,
  name: state.UserData.name,
  phone: state.UserData.phone,
  authenticated: state.AuthData.authenticated,
  email: state.UserData.email,
  query: '',
  logo: state.HomeData.logo,
  companyDetails: state.HomeData.companyDetailsCurrency
});

export default connect(mapStateToProps, { setMsgModal, setProfoption,setLoginModal })(Footer);

