import React, {  } from 'react';
import { Container ,Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import comonbanner from "../../src/assets/img/pet-shop-banner.jpg";
import smcomonbanner from "../../src/assets/img/sm-comon-banner.jpg";
const Contact = props => {
	
	

    return (
	<React.Fragment>
	<div >
           <div >
                          <h4 className="aboutus-head">
                          CONTACT INFORMATION
                          </h4>
						 
                          {props.footerData && props.footerData.contactUsDtl && props.footerData.contactUsDtl.showContactUs?props.footerData.contactUsDtl.isHtml?
    <div className="product_details_cont" dangerouslySetInnerHTML={{ __html: props.footerData.contactUsDtl.contactUs }} />:'':''}
                        </div>


    </div>
           
       
		</React.Fragment>
    );
}

const mapStateToProps = state => ({
	footerData:state.HomeData.footer
})

export default connect(mapStateToProps, {})(Contact);
