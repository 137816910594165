import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.css';

import { Row, Col, Container, Navbar, Nav } from 'react-bootstrap';

const AdComponent = props => {


    return (
        <React.Fragment>
		  <div className="each-section fourcoloum-product-section">
                    <Container>
                        <Row>
                            <Col>
                                <div>
                                    <div class="card p-3">
                                        <h2 className="section-header text-dark">Value Pack Under ₹599</h2>
                                        <div className="fourcolumn-per-images-withheader">
                                            <img class="card-img-top" src="https://rukminim1.flixcart.com/image/495/594/ktvucnk0/dress/i/5/u/l-new-western-knee-length-dress-combo-for-women-7800-group-original-imag74kezx9sux8b.jpeg?q=50" alt="Card image" alt="Card image" />
                                        </div>
                                        <div class="card-body text-center p-0">
                                            <a href="#" class="viewall-link">Viev All <span class="double-arrow">»</span></a>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div>
                                    <div class="card p-3">
                                        <h2 className="section-header text-dark">Inspired by Your Browsing History</h2>
                                        <div className="fourcolumn-per-images-withheader">
                                            <Row className="mb-2">
                                                <Col>
                                                    <div>
                                                        <img src="https://images-eu.ssl-images-amazon.com/images/G/31/img21/Fashion/Event/Gateway/WRS-Jun/PC_QC_186/Comp-186/5-min._SY116_CB666463598_.jpg" className="w-100 h-object-fit" />
                                                        <p className="item-four-smallname">Kurta</p>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div>
                                                        <img src="https://images-eu.ssl-images-amazon.com/images/G/31/img21/Fashion/Event/Gateway/WRS-Jun/PC_QC_186/Comp-186/5-min._SY116_CB666463598_.jpg" className="w-100 h-object-fit" />
                                                        <p className="item-four-smallname">Kurta</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div>
                                                        <img src="https://images-eu.ssl-images-amazon.com/images/G/31/img21/Fashion/Event/Gateway/WRS-Jun/PC_QC_186/Comp-186/5-min._SY116_CB666463598_.jpg" className="w-100 h-object-fit" />
                                                        <p className="item-four-smallname">Kurta</p>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div>
                                                        <img src="https://images-eu.ssl-images-amazon.com/images/G/31/img21/Fashion/Event/Gateway/WRS-Jun/PC_QC_186/Comp-186/5-min._SY116_CB666463598_.jpg" className="w-100 h-object-fit" />
                                                        <p className="item-four-smallname">Kurta</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div class="card-body text-center p-0">
                                            <a href="#" class="viewall-link">Viev All <span class="double-arrow">»</span></a>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div>
                                    <div class="card p-3">
                                        <h2 className="section-header text-dark">Top Offers On<br/>&nbsp;</h2>
                                        <div className="fourcolumn-per-images-withheader">
                                            <img class="card-img-top" src="https://rukminim1.flixcart.com/image/495/594/ktvucnk0/dress/i/5/u/l-new-western-knee-length-dress-combo-for-women-7800-group-original-imag74kezx9sux8b.jpeg?q=50" alt="Card image" alt="Card image" />
                                        </div>
                                        <div class="card-body text-center p-0">
                                            <a href="#" class="viewall-link">Viev All <span class="double-arrow">»</span></a>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div>
                                    <div class="card p-3">
                                        <h2 className="section-header text-dark">Top Rated, Premium Quality</h2>
                                        <div className="fourcolumn-per-images-withheader">
                                            <img class="card-img-top" src="https://rukminim1.flixcart.com/image/495/594/ktvucnk0/dress/i/5/u/l-new-western-knee-length-dress-combo-for-women-7800-group-original-imag74kezx9sux8b.jpeg?q=50" alt="Card image" alt="Card image" />
                                        </div>
                                        <div class="card-body text-center p-0">
                                            <a href="#" class="viewall-link">Viev All <span class="double-arrow">»</span></a>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    
})

export default connect(mapStateToProps, { })(AdComponent);
