import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { getCategoryList, selectCategory, selectsubCategory, selectsubsubCategory, setMenuState } from '../actions/CategoryAction';
import { GetSearchItem, filterByCategory, getProductsDetailsByItem, getFAQDetails, getItemDetailBySectionFor, getItemsByVariant, selectProduct, saveWishList, getWishList, removeWishList, getRating, getRelatedItemDetails, SaveUpdateWishCategory, deleteWishItem } from '../actions/ProductsAction';
import { Global } from '../utils/Env';
import { Container, Row, Col, Button, Form, Modal } from 'react-bootstrap';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Carousel } from 'react-responsive-carousel';
import ImageUtil from "../utils/ImageUtil";
import SizeChart from './SizeChart';
import InnerImageZoom from 'react-inner-image-zoom'
import Info from './Info';
import { formatINR, formatSymbolFromCurrency } from '../utils/CommonUtil';
import Counter from "./Counter";
import MsgModal from '../container/MsgModal';
import ImageModel from '../container/ImageModel';
import { ScrollInView } from '../components/Scroll';
import P_AllProduct from '../components/P_AllProduct';
import P_ProductLoading from "./P_ProductLoading";
import { setLoginModal, set_login_head, setSearch } from '../actions/AuthAction';
import { previewCart, saveCart, saveCartbuynow, getCart, getCartbuynow,setWishListNonLog } from '../actions/CartAction';
import { checkOutOrder, selectCheckOutJSON } from '../actions/CheckoutAction';
import ComingSoon from "./ComingSoon";
import Moment from 'react-moment';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PhoneNumberAdd from "./PhoneNumberAdd";
import 'react-accessible-accordion/dist/fancy-example.css';
import { toast } from "react-toastify";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import { isEqual } from "lodash";
function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "transparent" }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "transparent" }}
            onClick={onClick}
        />
    );
}


const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        }
    ]

};
let imageList;
let imageFinalList;
let imageLastList;
const CategorySection = (props) => {
    const [state, setState] = useState("");
    const params = useParams();
    const location = useLocation();
    const [sectionFor, setSectionFor] = useState(params.catid);
    const [itemId, setItemId] = useState(params.subcatid);
    const [variantId, setVariantId] = useState(params.subsubcatid);
    const [searchText, setSearchText] = useState(params.subsubcatid);
    const [showDefaultSection, setshowDefaultSection] = useState(true);

    const navigate = useNavigate();
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [show, setShow] = useState(false);
    const [showImage, setShowImage] = useState(false);
    const [content, setContent] = useState(<SizeChart />);
    const [head, setHead] = useState("Size Chart");
    const [final_color, setColor] = useState(null);
    const [final_size, setSize] = useState(null);
    const [colorList, setColorList] = useState([]);
    const [sizeList, setSizeList] = useState([]);
    const [rate, setRate] = useState(0);
    const [variantTypes, setVariantTypes] = useState([]);
    const [optionTypeSelected, setOptionTypeSelected] = useState({});
    const [optionTypes, setOptionTypes] = useState({});
    let wishlisting = false;
    const [product, setproduct] = useState([]);
    let addinfo;
    const [loadPage, seloadPage] = useState(true);
    let faqinfo;
    let relatediteminfo;


    const info_content = <><p>Any prepaid order pending due to unavability of stock from our end will be refunded of the full value paid</p></>
    const info_content1 = <><p>We have made every effort to display the colours of our products that appear on Petindia as accurately as possible. However, as computer monitors, tablets and mobile devices vary, we cannot guarantee that your monitor's display of a colour will be completely accurate</p></>
    let primaryColor = props.petTemplateDetails && props.petTemplateDetails.primaryColor ? props.petTemplateDetails.primaryColor : '';

    if (sectionFor == 5 && props.search) {
        props.setSearch(false);
        window.location.reload();
    }

    useEffect(() => {
        setSectionFor(params.catid);
        setItemId(params.subcatid);
        setVariantId(params.subsubcatid);
        setSearchText(params.subsubcatid);
    }, [params])
    const buyNow = async () => {
        if (props.authenticated) {
            if ((props.loginData && props.loginData.data && props.loginData.data.phone != "") || props.updatedUserPhoneNumber != "") {
                await props.saveCartbuynow(createobj(product, "add"));
            }
            else
                setState("PhoneReInsert");
        }
        else {
            props.setLoginModal(true);
            props.set_login_head("Login");
        }
    }
    Global.callback.savecartbuynow_onComplete = async () => {
        await props.getCartbuynow({ "companyid": props.companyid, "customerid": parseInt(props.loginData.data.customerId) });
    }
    Global.callback.getcartbuynow_onComplete = async () => {
        await props.checkOutOrder(props.checkoutData);
    }
    Global.callback.checkOutOrder_onComplete = () => {
        navigate("/checkout", { state: { process: true } });
    }
    const createobj = (prod, str) => {
        let obj = {
            "companyid": props.companyid,
            "customerid": parseInt(props.loginData.data.customerId),
            "userid": props.loginData.data.id,
            "carttItemVariant": [{
                "itemId": selectedProduct[0].id,
                "itemVariantId": (variantId) ? Number(variantId) : 0,
                "itemQty": (str == "add") ? 1 : 0,
            }]
        }
        return obj;
    }

    const closeActionPhone = () => {
        setState("");
    }

    useMemo(() => {
        if (sectionFor == 5 && props.companyid) {
            props.GetSearchItem({ "companyid": props.companyid, "SearchCriteria": searchText })
            props.setSearch(false);
        }
        if(selectedProduct.length && selectedProduct[0].id==itemId && selectedProduct.filter((item)=>item.itemId!=itemId).length==0)
        {}
        else if (sectionFor == 4 && props.companyid)
            props.getItemDetailBySectionFor(props.loginData.data == null ? { "companyid": props.companyid, "sectionFor": Number(sectionFor), "itemId": Number(itemId), "variant": Number(variantId), "customerid": 0 } : { "companyid": props.companyid, "sectionFor": Number(sectionFor), "itemId": Number(itemId), "variant": Number(variantId), "customerid": Number(props.loginData.data.customerId) })
        else if (sectionFor == 3 && props.companyid)
            props.getItemDetailBySectionFor(props.loginData.data == null ? { "companyid": props.companyid, "sectionFor": Number(sectionFor), "itemId": Number(itemId), "variant": Number(variantId), "customerid": 0 } : { "companyid": props.companyid, "sectionFor": Number(sectionFor), "itemId": Number(itemId), "variant": Number(variantId), "customerid": Number(props.loginData.data.customerId) })
        else if (sectionFor == 2 && props.companyid)
            props.getItemDetailBySectionFor(props.loginData.data == null ? { "companyid": props.companyid, "sectionFor": Number(sectionFor), "itemId": Number(itemId), "variant": Number(variantId), "customerid": 0 } : { "companyid": props.companyid, "sectionFor": Number(sectionFor), "itemId": Number(itemId), "variant": Number(variantId), "customerid": Number(props.loginData.data.customerId) })
        else if (sectionFor == 1 && props.companyid)
            props.getItemDetailBySectionFor(props.loginData.data == null ? { "companyid": props.companyid, "sectionFor": Number(sectionFor), "itemId": Number(itemId), "variant": Number(variantId), "customerid": 0 } : { "companyid": props.companyid, "sectionFor": Number(sectionFor), "itemId": Number(itemId), "variant": Number(variantId), "customerid": Number(props.loginData.data.customerId) })
        else if (sectionFor == 0 && props.companyid)
            props.getItemDetailBySectionFor(props.loginData.data == null ? { "companyid": props.companyid, "sectionFor": Number(5), "itemId": Number(itemId), "variant": Number(variantId), "customerid": 0 } : { "companyid": props.companyid, "sectionFor": Number(5), "itemId": Number(itemId), "variant": Number(variantId), "customerid": Number(props.loginData.data.customerId) })


    }, [sectionFor, itemId, variantId, searchText])
    useMemo(() => {
        if(selectedProduct.length && selectedProduct[0].id==itemId && selectedProduct.filter((item)=>item.itemId!=itemId).length==0)
        {}
        else if (sectionFor == 2 && props.companyid) {
            const lst = [1, 2];
            props.getProductsDetailsByItem({ "companyid": props.companyid, "variant": lst, "itemId": Number(itemId) })
        }
    }, [sectionFor, itemId, variantId, searchText])

    useMemo(() => {
        if(selectedProduct.length && selectedProduct[0].id==itemId && selectedProduct.filter((item)=>item.itemId!=itemId).length==0)
        {}
        else if (sectionFor == 2 && props.companyid) {
            props.getFAQDetails({ "companyid": props.companyid, "ItemId": Number(itemId) })
        }
    }, [sectionFor, itemId, variantId, searchText])
    useMemo(() => {
        if(selectedProduct.length && selectedProduct[0].id==itemId && selectedProduct.filter((item)=>item.itemId!=itemId).length==0)
        {}
        else if (sectionFor == 2 && props.companyid) {
            props.getRelatedItemDetails({ "companyid": props.companyid, "ItemIds": [Number(itemId)] })
        }
    }, [sectionFor, itemId, variantId, searchText])
    Global.callback.sectionFor_onComplete = () => {
        //console.log(props.sectionForList)
        //console.log(variantId)
        if(variantId==0){
            setVariantId(props.sectionForList[0].variantid);
        }
        //console.log(variantId)
        setproduct(props.sectionForList)
        if(variantId)
        setSelectedProduct(props.sectionForList);
        let final_data = props.sectionForList;
        let colors = [];
        if (props.sectionForList != null && props.sectionForList.length > 0) {
            props.sectionForList.map((item, i) => {
                if (colors.filter((color) => { return (color == item.color) }).length == 0)
                    colors.push(item.color);
            });
        }
        setColorList(colors);
        if (final_data != null && final_data.length > 0) {
            setColor(final_data[0].color);
        }

        let sizes = [];
        if (final_data != null && props.sectionForList.length > 0) {
            props.sectionForList.map((item, i) => {
                if (item.color == final_data[0].color)
                    sizes.push(item.size);
            });
        }
        if (final_data != null && final_data.length > 0) {
            setSize(final_data[0].size);
        }
        setSizeList(sizes);

        let variantTypes=[];
        let optionTypeSelected={
            variantid:variantId,
            variants:{}
        };
        let optionTypes={};

        props.sectionForList.map((item,i)=>{
            let obj={
                variantid:item.variantid,
                variants:{}
            };
            item.variants.map((v)=>obj.variants[v.option]=v.value)
            variantTypes.push(obj);
            
            if(i==0)
            {
                item.variants.map((v)=>optionTypeSelected.variants[v.option]=v.value)
                item.variantList.map((vari)=>{
                    if(optionTypes[vari.option] && Array.isArray(optionTypes[vari.option]))
                    {
                        
                    }
                    else
                    optionTypes[vari.option]=vari.value;
                });
            }
        });

        setVariantTypes(variantTypes);
        setOptionTypeSelected(optionTypeSelected);
        setOptionTypes(optionTypes);
    }
    Global.callback.savewishlist_onComplete = () => {
        //props.getWishList({ "companyid": props.companyid, "customerid": parseInt(props.loginData.data.customerId) });
    }

    const closeAction = () => {
        setShow(false);
    }
    const closeActionImage = () => {
        setShowImage(false);
    }
    const [wishState, setWishState] = useState(false);
    const [showNewMod, setshowNewMod] = useState(false);
    const [showWishCatMod, setshowWishCatMod] = useState(false);
    const [wishText, setwishText] = useState(null);

    const handleClose = () => setshowNewMod(false);
    const handleShow = () => setshowNewMod(true);

    const handleCloseWishcat = () => setshowWishCatMod(false);
    const handleShowWishcat = () => setshowWishCatMod(true);

    
    const valueChange = (event) => {
        setwishText(event.target.value)
    }

    const wishDdlChange = (event) => {
        setwishText(event.target.value)
    }

    useEffect(() => {
        if (props.authenticated) {
            props.getWishList({ "companyId": props.loginData.data.companyId, "customerId": parseInt(props.loginData.data.customerId) });
        }

    }, []);

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    var date = yyyy + '-' + mm + '-' + dd;

    const [type, setType] = useState(0);
    const GetWishListCategory = async (e) => {
        wishlisting=true;
        if (!props.authenticated) {
            let temp=JSON.parse(JSON.stringify(props.wishListNonLog));
            temp.push({"itemId": selectedProduct[0].id,
            "itemVariantId": (variantId) ? Number(variantId) : 0,
            "notifyOnArrival": selectedProduct.length && selectedProduct[0].stockQty != null && selectedProduct[0].stockQty <= 0 ? true : false});
            props.setWishListNonLog(temp);
        }
        else {
            e.preventDefault();
            wishlisting = true;
            if (props.wishlist != null) {
                setshowWishCatMod(true);
                setshowNewMod(false);
            }
            else {
                setshowWishCatMod(false);
                setshowNewMod(true);
            }
           
        }

    }

    const saveWishlistItem = () => {
       
            if(props.wishlist == null)
            {
                saveNewWishItem(0,wishText);
            }
            else if (props.wishlist != null && wishText != null) {
                CreateList();
            }
            else {

                var ddlWish = document.getElementById("ddlWislistcat");
                var id = parseInt(ddlWish.options[ddlWish.selectedIndex].value);
                var text = ddlWish.options[ddlWish.selectedIndex].text;
                saveNewWishItem(id,text);
            }
        }

    const saveNewWishItem = async(catId,catText) =>
    {
        let obj = {
                    "companyid": props.companyid,
                    "customerId": parseInt(props.loginData.data.customerId),
                    "itemId": selectedProduct[0].id,
                    "itemVariantId": (variantId) ? Number(variantId) : 0,
                    "notifyOnArrival": selectedProduct.length && selectedProduct[0].stockQty != null && selectedProduct[0].stockQty <= 0 ? true : false,
                    "wishListCategoryId": catId,
                    "wishListCategoryName": catText,
                    "note": "",
                    "date": date

                }
               
               props.saveWishList(obj);
    }



    Global.callback.savewishlist_onComplete = () => {
        if (props.savewishlist == "Yes")
        {
            setwishText(null);
            setWishState(true);
            // setHead("Wish List");
            // setShow(true);
            // setContent("The item has been added to your wishlist");
            toast.info("The item has been added to your wishlist");
            setshowWishCatMod(false);
            setshowNewMod(false);
        }
    }
       const CreateList = async () => {
        let obj = {
            "WishListCategoryId": 0,
            "WishListCategoryName": wishText,
            "IsDefault": props.wishlist ? false : true,
            "Date": "2023-01-23",
            "customerId": parseInt(props.loginData.data.customerId),
            "companyId": props.loginData.data.companyId
        }
        props.SaveUpdateWishCategory(obj);
        
        
    }

    Global.callback.getWishList_onComplete = () => {
        var id = parseInt(props.wishlist[props.wishlist.length - 1].wishListCategoryId);
        var text = props.wishlist[props.wishlist.length - 1].wishListCategoryName;
        saveNewWishItem(id,text);
        
    }
    const showNewModal = () => {
        setshowWishCatMod(false);
        setshowNewMod(true);
    }

    const removewishlist = (wishItemId) => {
        wishlisting = true;
        if (!props.authenticated) {
            let temp=props.wishListNonLog.filter((item)=>!(item.itemId==selectedProduct[0].id && item.itemVariantId==variantId));
            props.setWishListNonLog(temp);
        }
        else {
            let obj = {
                "CustomerWishItemId": wishItemId,
                "CustomerId": parseInt(props.loginData.data.customerId),
                "CompanyId": props.companyid,
                "Date": date
            }
            props.deleteWishItem(obj);
        }
    }
    Global.callback.getWishListItemById_onComplete = () => {
        if (props.deletewishlist == "Yes") {
            // setHead("Wish List");
            // setContent("The item has been removed from your wishlist");
            // setShow(true);
            toast.info("The item has been removed from your wishlist");
            setWishState(false);
        }
        else {
            toast.info("Failed To Delete");
        }
    }
    const onMoreShopping = () => navigate("/");
    const onCartClicked = () => {
        //props.previewCart(true);
        navigate("/Cart");
    }
    const handleVariantChange = (size, color) => {

        if (selectedProduct != null && selectedProduct.length > 0) {
            selectedProduct.map((item, i) => {

                if (item.color == color && item.size == size)
                {}

            });
        }
    }
    const color_selected = (color) => {

        setColor(color);
        let sizes = []
        if (selectedProduct != null && selectedProduct.length > 0) {
            selectedProduct.map((item, i) => {
                if (item.color == color)
                    sizes.push(item.size);
            });
        }
        if (sizes.filter((item) => { return item == final_size }).length == 0) {
            setSize(sizes[0]);
            handleVariantChange(sizes[0], color);
        }
        else
            handleVariantChange(final_size, color);
        setSizeList(sizes);
    }
    const handleImageZoom = (i, e) => {
        if (wishlisting == false) {
            setHead("Image Zoom");
            setShowImage(true);

            if (selectedProduct != null && selectedProduct.length > 0) {
                selectedProduct.map((itemdata, iddata) => {
                    if (itemdata.id == itemId && itemdata.variantid == variantId && itemdata.images) {
                        setContent(
                            itemdata.images.map((item, id) => {
                                if (id == i) {
                                    return (
                                        <TransformWrapper
                                            initialScale={1}
                                            initialPositionX={0}
                                            initialPositionY={0}
                                        >
                                            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                <React.Fragment>
                                                    {/* <div className="tools">
                                                    <button  style={{backgroundColor:primaryColor}} className="zoom_button zoom_in" onClick={() => zoomIn()}>Zoom In +</button>
                                                    <button  style={{backgroundColor:primaryColor}} className="zoom_button zoom_out"onClick={() => zoomOut()}>Zoom Out -</button>
                                                    <button  style={{backgroundColor:primaryColor}} className="zoom_button reset_zoom"onClick={() => resetTransform()}>Reset</button>
                                                    </div> */}
                                                    <TransformComponent>
                                                        <img key={id} src={ImageUtil.getImage(item.name)} style={{ width: '100%' }} />
                                                    </TransformComponent>
                                                </React.Fragment>
                                            )}
                                        </TransformWrapper>

                                    );
                                }
                            }
                            ));
                    }
                }
                );
            }
        }
        wishlisting = false;
    }
    const renderThumbs = () => {
        let imagedata;
        let imageList;
        if (selectedProduct != null && selectedProduct.length > 0) {
            imagedata = selectedProduct.map((itemdata, iddata) => {
                if (itemdata.id == itemId && itemdata.variantid == variantId && itemdata.images) {
                    imageList = itemdata.images.map((item, id) => {
                        return (
                            <img key={id} src={ImageUtil.getImage(item.name.substring(0, item.name.lastIndexOf(".")).concat("_Thumbnail").concat(item.name.substring(item.name.lastIndexOf("."), item.name.length)))} alt={item.name.alt} />
                        )
                    })
                }
            })
        }
        else {
            imageList = (
                <img className="quick-view-product-image"
                    src={ImageUtil.getErrorImage()}
                    alt=""
                />
            );
        }
        return imageList;
    }
    const getUpdatedVariant = (type,value) => {
        let temp=JSON.parse(JSON.stringify(optionTypeSelected.variants));
        temp[type]=value;
        if(variantTypes.filter((v)=>isEqual(v.variants,temp)).length>0)
        {
            let variantid=variantTypes.filter((v)=>isEqual(v.variants,temp))[0];
            setVariantId(variantid.variantid);
            setOptionTypeSelected(variantid);
            navigate(`/categorySection/${sectionFor}/${selectedProduct[0].id}/${variantid.variantid}`)
        }
        else
        {
            if(variantTypes.filter((v)=>v.variants[type]==value).length>0)
            {
                let variantid=variantTypes.filter((v)=>v.variants[type]==value)[0];
                setVariantId(variantid.variantid);
                setOptionTypeSelected(variantid);
                navigate(`/categorySection/${sectionFor}/${selectedProduct[0].id}/${variantid.variantid}`)
            }
        }
    }
    const renderImages = () => {
        
        let imagedata;
        let imageList;
        if (selectedProduct != null && selectedProduct.length > 0) {
            
            imagedata = selectedProduct.map((item, iddata) => {
                if (item.id == itemId && item.variantid == variantId && item.images) {
                    
                    imageList = item.images.map((itemdata, id) => {
                        return (
                            <div>
                                <div className="quick-view-image" key={id}>
                                    {props.companyDetailsCurrency.isDescriptionPrimary ?
                                        <img key={id} src={ImageUtil.getImage(itemdata.name.substring(0, itemdata.name.lastIndexOf(".")).concat(itemdata.name.substring(itemdata.name.lastIndexOf("."), itemdata.name.length)))} alt={itemdata.alt} />
                                        :
                                        <img key={id} src={ImageUtil.getImage(itemdata.name.substring(0, itemdata.name.lastIndexOf(".")).concat("_Medium").concat(itemdata.name.substring(itemdata.name.lastIndexOf("."), itemdata.name.length)))} alt={itemdata.alt} />
                                    }
                                    {item.brandicon ? <div className="brand-iconmaindiv"><img className="quick-view-brand-img" alt={item.brandicon} src={item.brandicon} /></div> : null}
                                    <div className="brand-iconmaindiv-details">
                                        {item.isWishListAdded || wishState || (props.wishListNonLog.filter((item)=>item.itemId==selectedProduct[0].id && item.itemVariantId==variantId).length>0 && !props.authenticated)? <span className={props.mobileState ? " label-wish" : " label-wish-product"}><i className="fa fa-heart fa-2x wishlisted" onClick={() => removewishlist(item.customerWishItemId)} /></span>:<span className={props.mobileState ? " mt-1 label-wish" : " label-wish-product"}><i className="fa fa-heart fa-2x not-wishlisted" onClick={(e) => GetWishListCategory(e)} /></span>}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            })
        }
        else {
            imageList = (<div className="quick-view-image">
                <img className="quick-view-product-image"
                    src={ImageUtil.getErrorImage()}
                    alt=""
                />
                {selectedProduct.brandicon !== "" ? <div className="brand-iconmaindiv"><img className="quick-view-brand-img" alt={selectedProduct.brandicon} src={selectedProduct.brandicon} /></div> : null}

            </div>
            )
        }
        return imageList;
    }
    const renderItemDetailsSectionone = () => {
        const cart = props.cartList
        let index = (variantId) ? cart.findIndex(x => x.id == itemId && x.variantid == variantId) : cart.findIndex(x => x.id == itemId);
        let quantity = 0;
        if (index > -1) {
            quantity = cart[index].quantity
        }
        let imageList = '';
        if (selectedProduct != null && selectedProduct.length > 0) {
            imageList = selectedProduct.map((item, iddata) => {
                if (item.id == itemId && item.variantid == variantId) {
                    return (
                        <>
                            <div>
                                <Modal show={showNewMod} onHide={handleClose}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Create a new list</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form>
                                            <Form.Group className="mb-3">
                                                <Form.Label>List name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    autoFocus
                                                    name="search" value={wishText} onChange={valueChange}
                                                />
                                            </Form.Group>
                                        </Form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose}>
                                            Close
                                        </Button>
                                        <Button variant="primary" onClick={saveWishlistItem}>
                                            Create
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                                <Modal show={showWishCatMod} onHide={handleCloseWishcat}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Select Wish List</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form>
                                            <Form.Group className="mb-3">
                                                <Form.Label>List name</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    value={type}
                                                    id="ddlWislistcat"
                                                    onChange={e => {
                                                        setType(e.target.value);
                                                    }}
                                                >


                                                    {props.wishlist != null ?
                                                        props.wishlist.map(item => (
                                                            <option
                                                                key={item.wishListCategoryId}
                                                                value={item.wishListCategoryId}
                                                            >
                                                                {item.wishListCategoryName}
                                                            </option>
                                                        )) : null}

                                                </Form.Control>
                                                <Button variant='link' onClick={showNewModal}>Add New Wishlist</Button>
                                            </Form.Group>
                                        </Form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleCloseWishcat}>
                                            Close
                                        </Button>
                                        <Button variant="primary" onClick={saveWishlistItem}>
                                            Save
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </div>
                           
                            <div className="quick-view-description">
                                {/* <h3 className="product-codes">{item.item_code}</h3> */}
                                <h3 className="product-names">{item.name}  
                                {/* <Info title="Disclaimer" class="dis-icon" content={info_content} /> */}
                                </h3>

                                {item.promo_price ? <p className="product-mrp"><b>Product MRP:</b> <s>{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode), item.price_regular)}</s></p> : ""}

                                <p className="prod-selling-price"> <span className="main-price">Price: {item.offer ? formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode), (item.offer.offerSalePrice)) : formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode), (item.price_regular))}</span>
                                    {(item.offer && item.offer.offerValue) ? <><span>&nbsp;&nbsp;<del style={{ opacity: "45%", fontSize: "20px", color: "#040404" }}>{item.offer ? item.price_regular : item.mrp}</del></span><span className="percentage_off"> <b>({item.offer.offerIsPercentage ? item.offer.offerValue : formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode), (item.offer.offerValue))}{item.offer.offerIsPercentage ? '%' : ''} OFF)</b></span></> : null}
                                    {quantity > 0 ? <span className='d-block add-item-cart-label'>
                                        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g id="Line"><path d="M21.19,6A3,3,0,0,0,19,5H16.9A5,5,0,0,0,7.1,5H5A3,3,0,0,0,2,8.19l.63,10.12a5,5,0,0,0,5,4.69h8.74a5,5,0,0,0,5-4.69L22,8.19A3,3,0,0,0,21.19,6ZM12,3a3,3,0,0,1,2.82,2H9.18A3,3,0,0,1,12,3Zm7.36,15.19a3,3,0,0,1-3,2.81H7.63a3,3,0,0,1-3-2.81L4,8.06A1,1,0,0,1,5,7H7V8A1,1,0,0,0,9,8V7h6V8a1,1,0,0,0,2,0V7h2a1,1,0,0,1,1,1.06Z" /><path d="M15.34,11.33l-4.1,4L9.66,13.82a1,1,0,0,0-1.41,1.43l2.28,2.24a1,1,0,0,0,1.41,0l4.81-4.73a1,1,0,1,0-1.41-1.43Z" /></g></svg>
                                        Item has already been added in cart</span> : null}</p>
                                        {selectedProduct.length && selectedProduct[0].stockQty != null && selectedProduct[0].stockQty <= 0 ? <h4 className='outstock-details'>Out of Stock</h4> : ''}
                                <span className="badge badge-danger bg-red font-weight-normal mr-5">{item.promo_percentage ? `${item.promo_percentage} Off` : ""}</span>
                                <span className="badge badge-pill badge-danger bg-red font-weight-normal p-1 pl-2 pr-2">{item.promo_name || ""}</span>
                            </div>
                            {props.sectionForList.length > 1 ?
                                    (item.variantid != null) ? (<> <div className="mt-3">
                                        {/* <p className="mb-2 avl-in"><b>Available in:</b>&nbsp; <Info title="Colors" content={info_content1} /></p> */}
                                        {Object.keys(optionTypes).map((v)=>{
                                         return(   
                                        <React.Fragment>
                                        <p className="mb-2 avl-in"><b>{v}</b></p>
                                        <div className="variant-select-btn-control">
                                            {optionTypes[v].map(k => <button key={k} className={optionTypeSelected.variants[v] == k ? "active-variant-btn" : ""} onClick={() => getUpdatedVariant(v,k)}>{k}</button>)}
                                        </div>
                                        </React.Fragment>
                                         );
                                        })}
                                    </div></>) : null : null}
                        </>
                    )
                }
            })
        }
        else {
            return (
                <div className="quick-view-description">

                </div>
            )
        }
        return imageList;
    }
    const renderItemDetailsSectiontwo = () => {
        // console.log('colorListcolorList',Object.assign({},...colorList).length,'hfhfhfhfhhf',colorList !=undefined)
        let imageList;
        if (selectedProduct != null && selectedProduct.length > 0) {
            imageList = selectedProduct.map((item, iddata) => {
                if (item.id == itemId && item.variantid == variantId) {
                    return (
                        <div>
                            {selectedProduct.length && selectedProduct[0].stockQty != null && selectedProduct[0].stockQty > 0 ?
                                <div className="add-button pt-3 pb-3">
                                    {(props.mobileState && !props.inView) || !props.mobileState ?
                                        <label className='mobile-style-fixed-button'>

                                            <Counter className="assign-count" product={Object.assign({}, ...selectedProduct.filter((item, i) => { return (item.variantid == variantId && item.id == itemId) }))} />
                                            
                                            <button className='secondary-round-button min-same-width ml-3 mr-3' onClick={() => buyNow()}>Buy Now</button></label> : ''}
                                    {item.variantid ? ((props.cartList.filter((item, i) => { return (item.variantid == item.variantid && item.id == item.id) }).length > 0) ? (<button className={props.mobileState ? "secondary-button_mobile min-same-width_mobile mr-3 d-none" : "secondary-button min-same-width mr-3 d-none"} onClick={onCartClicked}>Go To Cart</button>) : null) : ((props.cartList.filter((item, i) => { return item.id == item.id }).length > 0) ? (<button className={props.mobileState ? "secondary-button_mobile min-same-width_mobile mr-3" : "secondary-button min-same-width mr-3"} onClick={onCartClicked}>Go To Cart</button>) : null)}
                                    <button className={props.mobileState ? "secondary-button_mobile min-same-width_mobile " : "secondary-button "} onClick={onMoreShopping}><i className="fa fa-arrow-left"></i>&nbsp;&nbsp;Back to Shopping</button>
                                </div> : ''}
                            <div className="size-button add-button">
                            </div>
                            <div className="quick-view-details">
                              
                                {item.desc && item.desc.replace(/<[^>]*>?/gm, '') != '' ?
                                    <div className="details-description-section">

                                        <div className="mt-4 mb-2"><div className="product_details_cont" dangerouslySetInnerHTML={{ __html: item.desc }} /></div>
                                    </div> : ''}
                                <div className=''>
                                    <Accordion allowZeroExpanded={true} preExpanded={['0']} className="custom-accordian">
                                        {renderItemAdditionalInfoHtml()}
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    )
                }
            })
        }
        return imageList;
    }
    const renderItemAdditionalInfo = () => {
        if (props.AddInfo != null && props.AddInfo.length > 0) {
            return (
                addinfo = props.AddInfo.map((item, id) => {
                    if (item.fieldType != 'Html') {
                        return (
                            <tr>
                                <td className='adi-info-leftpart'>{item.fieldName}</td>
                                <td className='adi-info-rightpart'>{item.fieldValue}</td>
                            </tr>
                        )
                    }
                }
                )
            );
        }
        return addinfo;
    }
    const renderItemAdditionalInfoHtml = () => {
        if (props.AddInfo != null && props.AddInfo.length > 0) {
            return (
                addinfo = props.AddInfo.filter((x) => x.fieldType == 'Html').map((item, id) => {
                    if (item.fieldType == 'Html') {
                        return (
                            <AccordionItem uuid={String(id)}>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        {item.fieldName}
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div dangerouslySetInnerHTML={{ __html: item.fieldValue }} />
                                </AccordionItemPanel>
                            </AccordionItem>
                        )

                    }
                }
                )
            );
        }
        return addinfo;
    }
    const renderFAQInfo = () => {

        if (props.FAQInfo !== null && props.FAQInfo.length > 0) {
            return (
                faqinfo = props.FAQInfo.map((item, id) => (
                    <div>
                        <div className='each-qu-ans-div'>
                            <div className="quesdiv">
                                <span className="a-text-bold">Question:</span>
                                <span className="d-inline-block pl-4 a-text-regular">{item.faq}</span>
                            </div>
                            <div className="ansdiv">
                                <span className="a-text-bold">Answer:</span>
                                <span className="d-inline-block pl-4 a-text-regular-ans">{item.faqAnswer}</span>
                            </div><div className="ansdiv"><span className="a-text-bold"></span><span className="d-inline-block pl-4 a-text-regular-ans">By {item.answerPostedByName} on <Moment format="DD MMM, YYYY">{item.answerPostedOnUTC}</Moment></span></div>
                        </div>
                    </div>
                ))
            );
        }
        return faqinfo;
    }
    const section2 = () => {
        //console.log("reletediteminfo", props.RELATEDITEMInfo);
        if (typeof props.RELATEDITEMInfo != "undefined") {
            imageList = props.RELATEDITEMInfo?.map((item, id) => {
                //console.log("deep_item", item);
                imageFinalList = item.images;
                let saleprice = item.offer && item.offer.offerSalePrice ? item.offer.offerSalePrice : item.price ? item.price : item.salePrice;
                let mrp = item.offer && item.offer.offerSalePrice ? item.price ? item.price : item.salePrice : item.mrp;
                if (imageFinalList != null) {
                    if (imageFinalList != null) {
                        return (
                            <Col className="p-1-mob">
                                <div>
                                    <P_ProductLoading src={imageFinalList[0].fullImagepath} href={`/categorySection/${sectionFor}/${item.id}/${item.variantid}`} alt={item.name} productId={item.id} variantId={item.variantid} salePrice={saleprice} mrp={mrp} sectionFor={sectionFor} offer={item.offer} stockQty={item.stockQty} />
                                </div>
                            </Col>
                        )
                    }
                }
            })
        }

        return imageList;
    }

    // console.log(product[0]);
    // console.log('selectedProduct111',selectedProduct)
    // console.log('selectedProduct222',props.sectionForList)

    // console.log('sectionFor',sectionFor)
    // console.log('itemId',itemId)
    // console.log('variantId',variantId)

    const fromMenuSectionDynamic = () => {
        let imageLastList;
        if (selectedProduct != null && selectedProduct.length > 0) {
            imageLastList = selectedProduct.map((item, id) => {
                //console.log("deep_item", item);
                const productId = item.id;
                let saleprice = item.offer && item.offer.offerSalePrice ? item.offer.offerSalePrice : item.price_regular;
                let mrp = item.offer && item.offer.offerSalePrice ? item.price_regular : item.mrp;
                return (
                    <div className="col-md-3 p-1-mob col-6">
                        <P_ProductLoading src={ImageUtil.getImage(item.images ? item.images[0].name : null)} href={""} alt={item.name.concat(' ').concat(item.variantName)} sectionFor={2} productId={productId} variantId={item.variantid} salePrice={saleprice} mrp={mrp} offer={item.offer} stockQty={item.stockQty} />
                    </div>
                )
            })
        }
        else {
            imageLastList = <><h1 className="coming-soon"><ComingSoon selectedProduct={selectedProduct} /></h1><div>&nbsp;</div></>;
        }
        return imageLastList;
    }
    //console.log("product1234_deep", product);
    //console.log("selectedProduct", selectedProduct);
    if ((sectionFor == 2) && selectedProduct != null && Object.keys(selectedProduct).length > 0) {
        //console.log("selectedProduct[0].variantid",selectedProduct[0].variantid);
        //console.log("selectedProduct[0].id",selectedProduct[0].id);
        return (
            <React.Fragment>
                <Row className="product-details-maindiv">
                    <Container className='pl-10-pr-10-mob'>
                        {props.companyDetailsCurrency.isDescriptionPrimary ?
                            <Row>
                                <Col md="6 p-0-mob">
                                    <Carousel key={(selectedProduct[0] != null) ? (selectedProduct[0].variantid) ? selectedProduct[0].variantid : selectedProduct[0].id : 0} thumbWidth={"50px"} renderThumbs={renderThumbs} autoPlay={true} stopOnHover={true} infiniteLoop={true} onClickItem={handleImageZoom}>
                                        {renderImages()}
                                    </Carousel>
                                </Col>
                                <Col md="6 p-0-mob">
                                    {renderItemDetailsSectionone()}
                                    {renderItemDetailsSectiontwo()}
                                    {rate != 0 ? <p><b>Rating:</b>&nbsp; <i className="fa fa-star" /> {rate}</p> : ''}
                                </Col>
                            </Row>
                            
                            : <Row>
                                <Col md="3 p-0-mob">
                                    <Carousel key={(selectedProduct[0] != null) ? (selectedProduct[0].variantid) ? selectedProduct[0].variantid : selectedProduct[0].id : 0} thumbWidth={"50px"} renderThumbs={renderThumbs} autoPlay={true} stopOnHover={true} infiniteLoop={true} onClickItem={handleImageZoom}>
                                        {renderImages()}
                                    </Carousel>
                                </Col>
                                <Col md="9 p-0-mob">
                                    {renderItemDetailsSectionone()}
                                    {renderItemDetailsSectiontwo()}
                                    {rate != 0 ? <p><b>Rating:</b>&nbsp; <i className="fa fa-star" /> {rate}</p> : ''}
                                </Col>
                            </Row>
                        }
                         <Row className='d-none'>
                            <Col md="7 p-0-mob">
                                <Carousel key={(selectedProduct[0] != null) ? (selectedProduct[0].variantid) ? selectedProduct[0].variantid : selectedProduct[0].id : 0} thumbWidth={"50px"} renderThumbs={renderThumbs} autoPlay={true} stopOnHover={true} infiniteLoop={true} onClickItem={handleImageZoom}>
                                    {renderImages()}
                                </Carousel>
                            </Col>
                            <Col md="5 p-0-mob">
                                {renderItemDetailsSectionone()}
                                {renderItemDetailsSectiontwo()}
                                {rate != 0 ? <p><b>Rating:</b>&nbsp; <i className="fa fa-star" /> {rate}</p> : ''}
                            </Col>
                        </Row>
                <ImageModel show={showImage}  message={content} closeAction={closeActionImage} />

                        {/* <Row>
            <Col md={12} className="p-0">
                <div className='faq-div pt-4 pb-4 border-top'>
                    <h2 className='faq-header-label'>Buy It With</h2>
                    <div className='buy-it-with mt-4'>
                        <ul>
                            <li>
                                <a href='#'><img className="buy-it-img" src="https://library.wizardcomm.in/company/petindiab2c/images/Item/Original/SHAMPOO-2_mobile.jpg" /></a></li>
                            <li> + </li>
                            <li>
                                <a href='#'><img className="buy-it-img" src="https://library.wizardcomm.in/company/petindiab2c/images/Item/Original/toy-2_mobile.jpg" /></a></li>
                            <li> + </li>
                            <li>
                                <a href='#'><img className="buy-it-img" src="https://library.wizardcomm.in/company/petindiab2c/images/Item/Original/toy001_mobile.jpg" /></a></li>
                        </ul>
                        <div className="fs-14px">
                            <div>
                                <input type='checkbox' checked />&nbsp;&nbsp;<b>Meat Up Chicken Flavour Real Chicken Biscuit, 1kg (Buy 1 Get 1 Free)
                                </b><span>... </span><span className='price-color'>
                                    ₹297.00 (₹14.85/100 g)</span></div>
                            <div><input type='checkbox' checked />&nbsp;&nbsp;<a href="#">Chappi Adult Dry Dog Food, Chicken & Rice, 8kg Pack<span>... </span></a> <span className='price-color'>
                                ₹1,092.00 (₹13.42/100 g)</span></div>
                            <div><input type='checkbox' checked />&nbsp;&nbsp;<a href="#">Chappi Puppy Dry Pellet Dog Food, Chicken & Milk, 8 kg<span>... </span></a> <span className='price-color'>
                                ₹1,116.00 (₹13.95/100 g)</span></div>
                        </div>
                    </div>

                </div>
            </Col>
        </Row> */}
                        <Row>
                            <Col md={6} className="p-0">
                                {props.AddInfo != null && props.AddInfo.length > 0 &&  props.AddInfo[0].fieldType !="Html" ?
                                    <div className='faq-div pt-4 pb-4 border-top'>
                                        <h2 className='faq-header-label mb-4'>Additional Information</h2>
                                        <div>
                                            <table className='w-100'>
                                                <tbody>
                                                    {renderItemAdditionalInfo()}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div> : ''}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className="p-0">
                                {props.FAQInfo != null && props.FAQInfo.length > 0 ?
                                    <div className='faq-div pt-4 pb-4 border-top'>
                                        <h2 className='faq-header-label'>Frequently Asked Questions</h2>
                                        <p className='faq-header-p'>Here are few of the most frequently asked questions by our valueable customers</p>
                                        <div className='search'>
                                            {renderFAQInfo()}

                                        </div>


                                    </div> : ''}
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12} className="p-0">
                                {props.RELATEDITEMInfo && props.RELATEDITEMInfo.length > 0 ?
                                    <React.Fragment>
                                        <div className='faq-div pt-4 border-top'>
                                            <h2 className='faq-header-label'>Products related to this item</h2>
                                        </div>
                                        <div className="product-details-maindiv raw-bites related-section pt-3 row">
                                            <div className="container pl-10-pr-10-mob">
                                                <div className="position-relative row">
                                                    <div className="col-md-12 p-0">
                                                        <Slider {...settings}>
                                                            {section2()}
                                                        </Slider>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment> : ''}
                            </Col>
                        </Row>
                    </Container>
                </Row>
                <div className='mobile-style-nofixed-button' style={{ marginTop: '32px' }}>
                                    <Counter product={Object.assign({}, ...selectedProduct.filter((item, i) => { return (item.variantid == variantId && item.id == itemId) }))} />
                                    <button className='secondary-round-button min-same-width ml-3 mr-3' onClick={() => buyNow()}>Buy Now</button>
                                </div>
                <MsgModal show={show} title={head} message={content} closeAction={closeAction} />
                {(state == "PhoneReInsert") ? (
                    <PhoneNumberAdd showValue={true} closeAction={closeActionPhone} />
                ) : null}
            </React.Fragment>
        );
    }
    else {
        return (
            <ScrollInView trigger={props.selectedCategory}>
                <div className="p-0-mob">
                    <div className="products-wrapper">
                        <Container>
                            <div className="mar-0-auto text-center bg-light-control row">
                                <div className="col-md-12 text-center"><h3 className="page-lab-head">Items</h3></div>
                                {fromMenuSectionDynamic()}
                            </div>
                        </Container>
                    </div>
                </div>
            </ScrollInView>
        )
    }
}

const mapStateToProps = state => ({
    companyid: state.HomeData.companyid,
    sectionForList: state.ProductsData.sectionForList,
    authenticated: state.AuthData.authenticated,
    wishlist: state.ProductsData.wishlist,
    mobileState: state.CategoryData.isMobileMenu,
    variantList: state.ProductsData.variantList,
    companyDetailsCurrency: state.HomeData.companyDetailsCurrency,
    cartList: state.CartData.cartList,
    selectedCategory: state.CategoryData.selectedCategory,
    loginData: state.AuthData.loginData,
    AddInfo: state.ProductsData.AddInfo,
    search: state.AuthData.search,
    FAQInfo: state.ProductsData.FAQInfo,
    RELATEDITEMInfo: state.ProductsData.RELATEDITEMInfo,
    checkoutData: selectCheckOutJSON(state),
    updatedUserPhoneNumber: state.AuthData.updatedUserPhoneNumber,
    petTemplateDetails: state.petProductData.userP_AllProductInfo.data,
    inView: state.HomeData.inView,
    deletewishlist: state.ProductsData.deletewishlist,
    savewishlist:state.ProductsData.savewishlist,
    wishListNonLog:state.CartData.wishListNonLog
})

export default connect(mapStateToProps, {setWishListNonLog, saveCartbuynow, getCartbuynow, GetSearchItem, setSearch, getProductsDetailsByItem, getFAQDetails, getItemDetailBySectionFor, getItemsByVariant, getCategoryList, selectCategory, previewCart, setLoginModal, set_login_head, saveWishList, getWishList, selectsubCategory, selectsubsubCategory, filterByCategory, setMenuState, getRelatedItemDetails, saveCart, getCart, checkOutOrder, SaveUpdateWishCategory, deleteWishItem })(CategorySection);

