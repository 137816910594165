import React from 'react';
import { connect } from 'react-redux';


import CartItem from './CartPreviewItem';
import EmptyCart from "../EmptyCart";
import { Container } from 'react-bootstrap';


const PreviewItemList = (props) => {

  let cartItems = props.cartItems.map((product, index) => {
    return <CartItem key={`${product.name}${index}`} product={product} />
  });

  let hasItems = () => {
    return (props.cartItems && (props.cartItems.length > 0)) ? true : false
  }

  return (<Container className="p-0-mob">
        <div className="itemlist">
          <div className="itemlistinner">
            {hasItems() ? cartItems : <EmptyCart />}
          </div>
        </div>
        <div>
        <div className="product-details-maindiv raw-bites row">
									<div className="container pl-10-pr-10-mob">
										<div className="position-relative row">
											<label className="comon-arrow-style lft-pos">❮</label>
											<div className="col-md-3">
												<div className="prod">
													<div>
														<img className="prod-img" src="https://library.wizardcomm.in/company/petindiab2c/images/Item/Original/CHICKEN &amp;VEG GRAVY.jpg" />
													</div>
													<div className="col">
														<div className="product_name text-center"> IndiPet Organics Raw Bites Premium Dog Treats- Rabbit Jerky</div>
													</div>
												</div>
												<div className="row">
													<div className="pl-10 col"><div className="vendor text-center mt-2 mb-2">INDIPET</div></div>
												</div>
												<div className="row">
													<div className="pl-10 col">
														<div className="Price-renge text-center mb-2">
															<div className="price-new mr-1">₹ 690.00</div>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="pl-10 mb-5 col">
														<div className="addtocart-div text-center"><button className="primary-button min-same-width">Add to cart</button></div>
													</div>
												</div>
											</div>
											<div className="col-md-3">
												<div className="prod">
													<div>
														<img className="prod-img" src="https://library.wizardcomm.in/company/petindiab2c/images/Item/Original/CHICKEN &amp;VEG GRAVY.jpg" />
													</div>
													<div className="col">
														<div className="product_name text-center"> IndiPet Organics Raw Bites Premium Dog Treats- Rabbit Jerky</div>
													</div>
												</div>
												<div className="row">
													<div className="pl-10 col"><div className="vendor text-center mt-2 mb-2">INDIPET</div></div>
												</div>
												<div className="row">
													<div className="pl-10 col">
														<div className="Price-renge text-center mb-2">
															<div className="price-new mr-1">₹ 690.00</div>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="pl-10 mb-5 col">
														<div className="addtocart-div text-center"><button className="primary-button min-same-width">Add to cart</button></div>
													</div>
												</div>
											</div>
											<div className="col-md-3">
												<div className="prod">
													<div>
														<img className="prod-img" src="https://library.wizardcomm.in/company/petindiab2c/images/Item/Original/CHICKEN &amp;VEG GRAVY.jpg" />
													</div>
													<div className="col">
														<div className="product_name text-center"> IndiPet Organics Raw Bites Premium Dog Treats- Rabbit Jerky</div>
													</div>
												</div>
												<div className="row">
													<div className="pl-10 col"><div className="vendor text-center mt-2 mb-2">INDIPET</div></div>
												</div>
												<div className="row">
													<div className="pl-10 col">
														<div className="Price-renge text-center mb-2">
															<div className="price-new mr-1">₹ 690.00</div>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="pl-10 mb-5 col">
														<div className="addtocart-div text-center"><button className="primary-button min-same-width">Add To Cart</button></div>
													</div>
												</div>
											</div>
											<div className="col-md-3">
												<div className="prod">
													<div>
														<img className="prod-img" src="https://library.wizardcomm.in/company/petindiab2c/images/Item/Original/CHICKEN &amp;VEG GRAVY.jpg" />
													</div>
													<div className="col">
														<div className="product_name text-center"> IndiPet Organics Raw Bites Premium Dog Treats- Rabbit Jerky</div>
													</div>
												</div>
												<div className="row">
													<div className="pl-10 col"><div className="vendor text-center mt-2 mb-2">INDIPET</div></div>
												</div>
												<div className="row">
													<div className="pl-10 col">
														<div className="Price-renge text-center mb-2">
															<div className="price-new mr-1">₹ 690.00</div>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="pl-10 mb-5 col">
														<div className="addtocart-div text-center"><button className="primary-button min-same-width">Add to cart</button></div>
													</div>
												</div>
											</div>
											<label className="comon-arrow-style rig-pos">❯</label>
										</div>
									</div>
								</div>
        </div>
      </Container>)
}

const mapStateToProps = state => ({
  cartItems: state.CartData.cartList,
  totalAmount: state.CartData.totalAmount
});

export default connect(mapStateToProps)(PreviewItemList);