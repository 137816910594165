import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Carousel, Row, Col } from 'react-bootstrap';
import Banner from '../components/HomeV2/Banner';
import CategoryComponent from '../components/HomeV2/CategoryComponent';
import BestSellersComponent from '../components/HomeV2/BestSellersComponent';
import AdComponent from '../components/HomeV2/AdComponent';
import ItemScroller from '../components/HomeV2/ItemScroller';
import BestDeals from '../components/HomeV2/BestDeals';
import SurpriseDeals from '../components/HomeV2/SurpriseDeals';
import {getTemplate} from '../actions/HomeAction';
const HomeV2 = props => {
	
	const navigate = useNavigate();

 useEffect(() => {
        props.getTemplate({"CompanyId":props.companyid,"Type":"1","Url":""});
    }, []);
	
	


    return (
        <div className="fullpage-wrapper">
		{(props.template && props.template.sections)?props.template.sections.map((item)=>{
			
			let sorted_images=JSON.parse(JSON.stringify(item.images));
					sorted_images.sort((a,b)=>{
					if(a.displayOrder<b.displayOrder) {
					return -1;
					}
					if(a.displayOrder>b.displayOrder) {
					return 1;
					}
				});
		
			if(item.type==1 && item.images.length>0)
				return(
			<div onClick={()=>{navigate(`/sections/${item.id}`)}} style={{cursor:"pointer"}}>
			<Banner imageData={sorted_images}  />
			</div>
			
			)
			
			if(item.type==7 && item.images.length>0)
				return(
			<div onClick={()=>{navigate(`/sections/${item.id}`)}} style={{cursor:"pointer"}}>
			<ItemScroller primaryText={item.primaryText} secondaryText={item.secondaryText} imageData={sorted_images} onClick={()=>{navigate(`/sections/${item.id}`)}} />
			</div>
			)
			if(item.type==3 && item.images.length>0)
				return(
			<div onClick={()=>{navigate(`/sections/${item.id}`)}} style={{cursor:"pointer"}}>
			<BestSellersComponent primaryText={item.primaryText} secondaryText={item.secondaryText} imageData={sorted_images} onClick={()=>{navigate(`/sections/${item.id}`)}} />
			</div>
			)
			
			if(item.type==2 && item.images.length>0)
				return(
			<div onClick={()=>{navigate(`/sections/${item.id}`)}} style={{cursor:"pointer"}}>
			<BestDeals primaryText={item.primaryText} secondaryText={item.secondaryText} imageData={sorted_images} onClick={()=>{navigate(`/sections/${item.id}`)}} />
			</div>
			
			)
			
		}):''
		}
            <CategoryComponent />
            <AdComponent />
			<SurpriseDeals />
           
        </div>

    );
}

const mapStateToProps = state => ({
	companyid:state.HomeData.companyid,
	template:state.HomeData.template,
	templateStatus:state.HomeData.templateStatus,
	templateMessage:state.HomeData.templateMessage

})

export default connect(mapStateToProps, {getTemplate})(HomeV2);
